import classnames from "classnames";
import { type ReactNode } from "react";

import styles from "./thumbtack-modal.module.scss";

type PillType = "licensed" | "low_price" | "popular" | "remote";

type ThumbtackModalPill = {
  className: string;
  label: string;
  icon: ReactNode;
};

const pillTypes: Record<PillType, ThumbtackModalPill> = {
  licensed: {
    className: classnames(styles.pill, styles.purple),
    label: "Licensed pro",
    icon: (
      <svg
        height="14"
        width="14"
        fill="currentColor"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.571 7.011c-.044 1.698-.515 3.345-3.571 4.416-3.056-1.07-3.526-2.717-3.571-4.395V4.62L7 2.698l3.571 1.922V7.01zM7 1L2 3.716v3.337c.055 2.055.649 4.521 4.787 5.878L7 13l.213-.069c4.138-1.357 4.732-3.823 4.787-5.9V3.718L7 1zM5.706 7.041a.52.52 0 00-.743.156.58.58 0 00.148.78l1.81 1.267 2.117-3.332a.583.583 0 00-.149-.78.522.522 0 00-.743.156L6.624 7.683l-.918-.642z"></path>
      </svg>
    ),
  },
  low_price: {
    className: classnames(styles.pill, styles.green),
    label: "Great value",
    icon: (
      <svg
        height="14"
        width="14"
        fill="currentColor"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 6.143H6c-.552 0-1-.385-1-.857 0-.473.448-.857 1-.857h3c.553 0 1-.384 1-.858 0-.473-.447-.857-1-.857H8v-.857C8 1.384 7.553 1 7 1c-.553 0-1 .384-1 .857v.857c-1.654 0-3 1.154-3 2.572 0 1.417 1.346 2.571 3 2.571h2c.552 0 1 .385 1 .857 0 .473-.448.857-1 .857H4.5c-.553 0-1 .384-1 .858s.447.857 1 .857H6v.857c0 .474.447.857 1 .857.553 0 1-.383 1-.857v-.857c1.654 0 3-1.154 3-2.572 0-1.417-1.346-2.571-3-2.571z"></path>
      </svg>
    ),
  },
  popular: {
    className: classnames(styles.pill, styles.blue),
    label: "In high demand",
    icon: (
      <svg
        height="14"
        width="14"
        fill="currentColor"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.875 5.313c0 .756-.505 1.39-1.193 1.605a3.7 3.7 0 00.068-.668V2.503h.938c.103 0 .187.084.187.188v2.622zM9.25 6.25C9.25 7.49 8.24 8.5 7 8.5S4.75 7.49 4.75 6.25V2.5h4.5v3.75zm-7.125-.938V2.684c0-.103.084-.187.188-.187h.937V6.25c0 .229.028.45.067.668a1.686 1.686 0 01-1.192-1.606zM11.688 1H2.312C1.59 1 1 1.589 1 2.313v3A2.812 2.812 0 003.769 8.12 3.743 3.743 0 006.25 9.922V11.5h-1.5a.75.75 0 100 1.5h4.5a.75.75 0 100-1.5h-1.5V9.923a3.742 3.742 0 002.481-1.803A2.812 2.812 0 0013 5.313v-3C13 1.589 12.411 1 11.687 1z"></path>
      </svg>
    ),
  },
  remote: {
    className: classnames(styles.pill, styles.purple),
    label: "Offers remote services",
    icon: (
      <svg
        height="14"
        width="14"
        fill="currentColor"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.665 8.853l-1.554-.778v-2.15l1.554-.777v3.705zm-3.11.486a.773.773 0 01-.772.772H3.104a.773.773 0 01-.771-.772V4.66c0-.426.346-.772.771-.772h4.679c.426 0 .772.346.772.772V9.34zm4.666-6.708l-3.157 1.578a2.33 2.33 0 00-2.281-1.875H3.104A2.33 2.33 0 00.777 4.66V9.34a2.33 2.33 0 002.327 2.328h4.679c1.128 0 2.07-.807 2.281-1.875l3.157 1.578V2.63z"></path>
      </svg>
    ),
  },
};

export const Pills = ({ keys }) => {
  return (
    <ul className={styles.pillBox}>
      {keys.map((k) => {
        const pill = pillTypes[k];
        return (
          pill && (
            <li className={pill.className} key={k}>
              {pill.icon} {pill.label}
            </li>
          )
        );
      })}
    </ul>
  );
};
