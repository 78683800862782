import { useCallback, useEffect, useState } from "react";

import { POOL_DATA, POOL_ENUM_DISPLAY_MAP, PROPERTY_PROFILE } from "consts";
import { scrollToTop, trackEvent } from "utils";

export const usePropertyProfilePage = (userPropertyProfileData) => {
  const [updateForm, setUpdateForm] = useState("");
  const [propertyUpdated, setPropertyUpdated] = useState(false);

  const getPropertyDataValue = useCallback(
    (term) => {
      let val;
      if (userPropertyProfileData) {
        if (PROPERTY_PROFILE[term]?.valueMap) {
          val =
            PROPERTY_PROFILE[term].valueMap[userPropertyProfileData[term] ?? 1];
        } else if (term === "poolType") {
          // poolType is the only non-boolean pool term.
          val = POOL_ENUM_DISPLAY_MAP[userPropertyProfileData[term]];
        } else {
          val = userPropertyProfileData[term];
        }
      }
      return val;
    },
    [userPropertyProfileData]
  );

  useEffect(() => {
    if (userPropertyProfileData) {
      trackEvent("Property", {
        roofAge: getPropertyDataValue("constructionYearRoof"),
        burglarAlarm: getPropertyDataValue("burglarAlarm"),
        fireAlarm: getPropertyDataValue("fireAlarm"),
        pool: getPropertyDataValue("poolType"),
        poolFence: getPropertyDataValue("poolFence"),
        trampoline: getPropertyDataValue("trampoline"),
      });
      scrollToTop();
    }
  }, [getPropertyDataValue, userPropertyProfileData]);

  const poolDisplayData = {};
  if (userPropertyProfileData) {
    POOL_DATA.forEach((term) => {
      if (term === "poolType") {
        poolDisplayData[term] =
          POOL_ENUM_DISPLAY_MAP[userPropertyProfileData[term]];
        // Due to a double negative being used, we have to flip the expected response
      } else if (term === "poolFenceAboveGround") {
        poolDisplayData[term] = userPropertyProfileData[term] ? "No" : "Yes";
      } else if (userPropertyProfileData[term] !== null) {
        poolDisplayData[term] = userPropertyProfileData[term] ? "Yes" : "No";
      }
    });
  }

  return {
    propertyUpdated,
    poolDisplayData,
    updateForm,
    setPropertyUpdated,
    setUpdateForm,
  };
};
