import cx from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";
import { Container } from "react-grid-system";
import { useLoaderData, useNavigate } from "react-router-dom";

import { BaseLayout } from "components/base-layout";
import { AuthAppContext } from "components/root/auth-app-provider";
import { type ClaimConstantsLoaderData } from "components/root/router";
import { LEAKBOT_SUCCESS_TIMEOUT } from "consts";
import { useLeakbotPageDataQuery } from "gql/__generated__/hooks";
import LeakbotConfirmAddress from "pages/leakbot/leakbot-confirm-address";
import LeakbotConfirmContact from "pages/leakbot/leakbot-confirm-contact";
import LeakbotHome from "pages/leakbot/leakbot-home";
import LeakbotReview from "pages/leakbot/leakbot-review";
import { setCookie, trackEvent, useFlags } from "utils";

import { existingPhoneNumber, formatLeakBotData } from "./leakbot-utils";
import styles from "./leakbot.module.scss";

enum STEPS {
  landing,
  address,
  contact,
  review,
}

const stepEventsMap: Record<STEPS, string> = {
  [STEPS.landing]: "LeakBot - Landing page loads",
  [STEPS.address]: "LeakBot - Confirm address page loads",
  [STEPS.contact]: "LeakBot - Confirm contact page loads",
  [STEPS.review]: "LeakBot - Review page loads",
};

const LeakBot = () => {
  const navigate = useNavigate();
  const { retireProxy } = useFlags();
  const loaderData = useLoaderData() as ClaimConstantsLoaderData;
  const [step, setStep] = useState<STEPS>(STEPS.landing);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ready, setReady] = useState(false);
  const [agreeSetup, setAgreeSetup] = useState(false);
  const [policyHolderPhone, setPolicyHolderPhone] = useState<string>();

  const { selectedPolicyId, userDetails, userInfo } =
    useContext(AuthAppContext);

  const { data, loading } = useLeakbotPageDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  const doClaimConstantsQuery = useCallback(() => {
    const claimsConstants = loaderData?.claimsConstants;
    if (claimsConstants) {
      setPolicyHolderPhone(claimsConstants?.PolicyHolderDetails?.phoneNumber);
    }
  }, [loaderData?.claimsConstants]);

  useEffect(() => {
    if (retireProxy) {
      doClaimConstantsQuery();
    } else {
      setPolicyHolderPhone(
        data?.userClaimsConstants?.PolicyHolderDetails?.phoneNumber
      );
    }
  }, [
    doClaimConstantsQuery,
    data?.userClaimsConstants?.PolicyHolderDetails,
    retireProxy,
  ]);

  useEffect(() => {
    if (!loading) {
      setEmail(userInfo?.email ?? "");
      setPhone(existingPhoneNumber(policyHolderPhone));
      setReady(true);
    }
  }, [userInfo, policyHolderPhone, loading]);

  useEffect(() => {
    trackEvent(stepEventsMap[step]);
  }, [step]);

  const onSubmitSuccess = () => {
    setCookie(
      `mss-leakbot-newly-enrolled-${selectedPolicyId}`,
      true,
      LEAKBOT_SUCCESS_TIMEOUT
    );
    navigate("/my/overview");
  };

  if (loading || !ready) {
    return null;
  }

  const { name, address } = formatLeakBotData(userDetails);
  return (
    <BaseLayout hideNavigation>
      <div
        className={cx(
          styles.lbOuter,
          [STEPS.landing, STEPS.review].includes(step)
            ? styles.whiteBackground
            : null
        )}
      >
        <Container>
          {step === STEPS.landing && (
            <LeakbotHome
              onNext={() => setStep(STEPS.address)}
              onBack={() => navigate("/")}
            />
          )}
          {step === STEPS.address && (
            <LeakbotConfirmAddress
              onNext={() => setStep(STEPS.contact)}
              onBack={() => setStep(STEPS.landing)}
              address={userDetails?.propertyAddress}
              policyId={selectedPolicyId}
            />
          )}
          {step === STEPS.contact && (
            <LeakbotConfirmContact
              onNext={() => setStep(STEPS.review)}
              onBack={() => setStep(STEPS.address)}
              name={name}
              email={email}
              phone={phone}
              setEmail={setEmail}
              setPhone={setPhone}
            />
          )}
          {step === STEPS.review && (
            <LeakbotReview
              onBack={() => setStep(STEPS.contact)}
              name={name}
              policyId={selectedPolicyId}
              address={address}
              email={email}
              phone={phone}
              agreeSetup={agreeSetup}
              setAgreeSetup={setAgreeSetup}
              onSubmitSuccess={onSubmitSuccess}
            />
          )}
        </Container>
      </div>
    </BaseLayout>
  );
};

export default LeakBot;
