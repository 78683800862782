import {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { Button, Span } from "@icg360/design-system";

import {
  NEW_BIZ_WRITE_OUT_MSG,
  RENEWAL_WRITE_OUT_MSG,
} from "components/home-services/constants";
import { AuthAppContext } from "components/root/auth-app-provider";
import { type GetPolicyQuery } from "gql/__generated__/hooks";
import {
  type BadgeProps,
  WelcomeBannerSection,
} from "pages/overview/welcome-banner/welcome-banner-section";
import { formatDate, trackEvent, useFlags } from "utils";

type StatusContent = {
  badgeProps?: BadgeProps;
  body: ReactNode;
  detailsButtonPath?: string;
  detailsButtonEventDetails?: string;
  buttonText?: string;
};

const getStatusContent = (
  policyType: string,
  policyStatus: string,
  effectiveDate: string,
  dueDate: string,
  writeOutsFlag: boolean,
  inspection?: NonNullable<GetPolicyQuery["getPolicy"]>["inspection"],
  pendingCancellationDate?: NonNullable<
    GetPolicyQuery["getPolicy"]
  >["pendingCancellationDate"]
): StatusContent => {
  // H06 policies don't require inspections
  if (policyType === "H06") {
    return {
      body: "Your policy does not require inspection. For any questions or concerns, please contact SageSure.",
    };
  }

  let writeOutDetails = {};
  // There are new business writeouts
  if (inspection?.newBusinessInspectionWriteOuts?.referralList?.length) {
    const buttonPath = writeOutsFlag
      ? "/my/inspection-review/proof"
      : `/my/property/services/${NEW_BIZ_WRITE_OUT_MSG}`;

    writeOutDetails = {
      detailsButtonPath: buttonPath,
      detailsButtonEventDetails: "New Business Write Out",
      body: `We found things we need you to fix or update during the inspection. We won’t be able to cover your home unless you make the fixes or the updates by ${
        dueDate ? formatDate(dueDate, "MM/DD/YYYY") : "the due date"
      }.`,
    };
  }

  // There are bees360 renewal writeouts (renewal takes precedence)
  if (inspection?.renewalWriteOuts?.bees360ReferralList?.length) {
    writeOutDetails = {
      detailsButtonPath: `/my/property/services/${RENEWAL_WRITE_OUT_MSG}`,
      detailsButtonEventDetails: "Bee360 Write Out",
    };
  }

  // Policy cancelled, potentially for underwriting reasons (should be 440?)
  if (
    policyStatus === "CANCELLEDPOLICY" &&
    !["460", "480"].includes(inspection?.statusEnum ?? "")
  ) {
    return {
      ...writeOutDetails,
      badgeProps: { appearance: "neutral", text: "Policy cancelled" },
      body: "Your policy has been cancelled. Contact your agent for next steps to obtain coverage for your home.",
      detailsButtonEventDetails: "New Business Write Out - Cancelled policy",
    };
  }

  // Policy is pending cancellation
  if (pendingCancellationDate && inspection?.statusEnum !== "480") {
    return {
      ...writeOutDetails,
      badgeProps: { appearance: "danger", text: "Pending cancellation" },
      body: "Your policy is set to cancel soon. Please repair the issues or contact your agent about next steps.",
      detailsButtonEventDetails:
        "New Business Write Out - Pending cancellation",
    };
  }

  const contentMap: Record<string, StatusContent> = {
    "100": {
      badgeProps: { appearance: "neutral", text: "Pending" },
      body: "An inspection has been ordered for your home. Please wait for completion and an inspection review by SageSure.",
    },
    "110": {
      badgeProps: { appearance: "neutral", text: "Pending" },
      body: "An inspection has been ordered for your home. Please wait for completion and an inspection review by SageSure.",
    },
    "200": {
      body: (
        <div>
          If your policy requires an inspection, SageSure will order the
          inspection near the effective date of{" "}
          <Span bold>{formatDate(effectiveDate)}</Span>.
        </div>
      ),
    },
    "210": {
      badgeProps: { appearance: "neutral", text: "Pending" },
      body: "Your inspection couldn’t be completed and a new one is required immediately. See the related email from SageSure for instructions. For any questions or concerns, please contact SageSure.",
    },
    "300": {
      badgeProps: { appearance: "neutral", text: "In-review" },
      body: "A inspection was received by SageSure. Please wait for an inspection review by SageSure.",
    },
    "400": {
      badgeProps: { appearance: "success", text: "Complete" },
      body: "Everything looks good in your home inspection and your policy is ready to go. Welcome to SageSure!",
    },
    "410": {
      badgeProps: { appearance: "success", text: "Complete" },
      body: "We made a few updates to your policy based on your home inspection review.",
      detailsButtonPath: "/my/documents",
      buttonText: "View in Documents",
    },
    "420": {
      badgeProps: { appearance: "warning", text: "Issues found" },
      body: "Based on our inspection, repairs or updates may be needed. Please review the related email from SageSure and respond by the date indicated in order to avoid cancellation.",
      ...writeOutDetails,
    },
    "430": {
      badgeProps: { appearance: "warning", text: "Issues found" },
      body: "Based on our inspection, repairs or updates may be needed. Please review the related email from SageSure and respond by the date indicated in order to avoid cancellation.",
      ...writeOutDetails,
    },
    "440": {
      badgeProps: { appearance: "warning", text: "Issues found" },
      body: "We found issues with your inspection. For any questions or concerns, please contact your insurance representative.",
      ...writeOutDetails,
    },
    "450": {
      badgeProps: { appearance: "success", text: "Complete" },
      body: "Thank you for addressing the underwriting concerns from SageSure—no further action is required. Welcome to SageSure! ",
    },
    "460": {
      // cancelled should override the `body` from `writeOutDetails`
      ...writeOutDetails,
      badgeProps: { appearance: "neutral", text: "Policy cancelled" },
      body: "Your policy was cancelled for non-underwriting reasons. Contact your agent if you have any questions or concerns.",
    },
    "480": {
      // ineligible should override the `body` from `writeOutDetails`
      ...writeOutDetails,
      badgeProps: { appearance: "danger", text: "Ineligible" },
      body: "Unfortunately, your property doesn’t meet the underwriting guidelines for this policy. You might be eligible for different options. Contact your insurance rep for next steps.",
    },
    "1000": {
      badgeProps: { appearance: "neutral", text: "Not found" },
      body: "We weren’t able to find your inspection status. Please contact your agent if you have any questions or concerns.",
    },
  };

  const unknownStatusContent: StatusContent = {
    body: "Something went wrong",
  };

  return contentMap[inspection?.statusEnum ?? ""] ?? unknownStatusContent;
};

export const UnderwritingSection = () => {
  const navigate = useNavigate();
  const { WriteOutUploads: writeOutUploads } = useFlags();
  const { selectedPolicy, userDetails, userPolicyProperties } =
    useContext(AuthAppContext);
  const [statusContent, setStatusContent] = useState<StatusContent>({
    body: "",
  });

  useEffect(() => {
    if (selectedPolicy) {
      trackEvent("welcomeTaskCard_underwriting_load", {
        enum: selectedPolicy.inspection?.statusEnum,
      });
    }
  }, [selectedPolicy]);

  useEffect(() => {
    if (selectedPolicy && userDetails) {
      const policyType = userPolicyProperties?.policyType ?? "";
      const policyStatus = userDetails.policyStatus ?? "";
      const effectiveDate = userDetails.currentTerm?.effectiveDate;
      const { inspection } = selectedPolicy;
      const dueDate = inspection?.newBusinessInspectionWriteOuts?.dueDate;
      const pendingCancellationDate = selectedPolicy.pendingCancellationDate;
      setStatusContent(
        getStatusContent(
          policyType,
          policyStatus,
          effectiveDate,
          dueDate,
          writeOutUploads,
          inspection,
          pendingCancellationDate
        )
      );
    }
  }, [
    selectedPolicy,
    userDetails,
    userPolicyProperties?.policyType,
    writeOutUploads,
  ]);

  const handleViewDetailsClick = useCallback(() => {
    trackEvent("welcomeTaskCard_underwriting_viewDetails", {
      inspectionStatus: selectedPolicy?.inspection?.statusEnum,
      details: statusContent.detailsButtonEventDetails,
    });
    if (statusContent?.detailsButtonPath) {
      navigate(statusContent?.detailsButtonPath);
    }
  }, [
    navigate,
    selectedPolicy?.inspection?.statusEnum,
    statusContent.detailsButtonPath,
    statusContent.detailsButtonEventDetails,
  ]);

  return (
    <WelcomeBannerSection
      title="Underwriting review"
      loading={!selectedPolicy}
      badgeProps={statusContent.badgeProps}
      footer={
        statusContent.detailsButtonPath ? (
          <Button
            appearance="secondary"
            size="sm"
            onPress={handleViewDetailsClick}
            data-testid="view-property-details-btn"
          >
            {statusContent.buttonText ?? "View details"}
          </Button>
        ) : null
      }
    >
      {statusContent.body}
    </WelcomeBannerSection>
  );
};
