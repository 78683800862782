import { ssData } from "data/ss-store";
import {
  LegacyUserDataDocument,
  type LegacyUserDataQuery,
  LogoutUserDocument,
  type LogoutUserMutation,
} from "gql/__generated__/hooks";
import { apolloClient, removeAllCookies, resetAnalytics } from "utils";

type RedirectCallback<T> = (destination: string) => T;
const defaultRedirect: RedirectCallback<void> = (destination: string) => {
  window.location.href = destination;
  return;
};

/**
 * Logout user and redirect to homepage.
 *
 * @param returnTo - an optional destination to return to if the user logs in
 * @param redirect - an optional redirect function
 */
export async function logout<T>(
  returnTo?: string,
  redirect?: RedirectCallback<T>
): Promise<T>;
export async function logout(returnTo?: string, redirect = defaultRedirect) {
  resetAnalytics();
  removeAllCookies();
  const query = returnTo ? `?to=${encodeURIComponent(returnTo)}` : "";
  const destination = ssData.featureFlags.universalLogin
    ? `/auth/logout${query}`
    : `/${query}`;

  if (!ssData.featureFlags.universalLogin) {
    await apolloClient.mutate<LogoutUserMutation>({
      mutation: LogoutUserDocument,
    });
  }

  return redirect(destination);
}

let currentUser: { email?: string };
export async function isLoggedIn() {
  if (ssData.featureFlags.universalLogin) {
    return !!ssData.user;
  }

  // This query combines isLoggedIn with the legacy userInfo. We don't
  // need to pass a policy ID because the server doesn't use it, but
  // the schema requires it.
  const res = await apolloClient.query<LegacyUserDataQuery>({
    query: LegacyUserDataDocument,
    fetchPolicy: "network-only",
    variables: {
      policyID: "NOT_USED",
    },
  });
  currentUser = {
    email: res.data.userInfo?.email ?? undefined,
  };
  return !!res.data.isLoggedIn?.loggedIn;
}

export function authUser() {
  // Once universalLogin integrated, useSSData to access instead of authUser func
  if (ssData.featureFlags.universalLogin) {
    return ssData.user;
  }
  return currentUser;
}

/**
 * Adds headers to requests to backend. Mostly acts as a bridge between old
 * system and Universal Login.
 *
 * Does nothing if legacy auth. If universal adds token and custom headers so
 * proxy knows to pass the request through to keystone.
 *
 * @param base - headers object to extend
 * @param throughProxy - set to false if request is going directly to keystone-api
 * @returns
 */
export function authHeaders(
  base: Record<string, string> = {},
  throughProxy = true
) {
  const headers = base;
  if (ssData.user?.token) {
    headers.Authorization = `Bearer ${ssData.user.token}`;
  }
  if (throughProxy && ssData.featureFlags.universalLogin) {
    headers["x-universal-login"] = "?1";
    if (ssData.user) {
      headers["x-username"] = ssData.user.email;
    }
  }
  return headers;
}
