import { Fragment, useContext, useEffect, useState } from "react";
import { Col } from "react-grid-system";
import { useNavigate } from "react-router-dom";

import {
  Badge,
  Button,
  Card,
  Heading,
  Hr,
  Span,
  Spinner,
} from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { CustomerCareContactModal } from "components/customer-care-contact-modal";
import { AuthAppContext } from "components/root/auth-app-provider";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { PageFooter } from "components/shared/page-footer";
import { ThumbtackModalProvider } from "components/thumbtack-modal";
import { CancelledPolicySidebar } from "components/write-outs/cancelled-policy-sidebar";
import { InspectionReviewSidebar } from "components/write-outs/inspection-review-sidebar";
import { UnderwritingConditionItem } from "components/write-outs/underwriting-condition-item";
import { UploadProof } from "components/write-outs/upload-proof";
import { daysUntil, formatDate } from "utils";

import styles from "./inspection-review.module.scss";

export const InspectionReview = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { userDetails, selectedPolicy, isPolicyDataReady } =
    useContext(AuthAppContext);

  useEffect(() => {
    const isIneligible = selectedPolicy?.inspection?.statusEnum === "480";

    if (isIneligible) {
      navigate("/my/inspection-review/ineligible", { replace: true });
    }
  }, [navigate, selectedPolicy?.inspection?.statusEnum]);

  if (!isPolicyDataReady) {
    return <BaseLayout loading />;
  }

  const { propertyAddress, policyStatus } = userDetails ?? {};
  const { zip } = propertyAddress ?? {};
  const { pendingCancellationDate } = selectedPolicy ?? {};
  const { referralList, dueDate } =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts ?? {};
  const isCancelled = policyStatus === "CANCELLEDPOLICY";
  const numberOfDays = daysUntil(dueDate);

  let headingText = "Things we need you to fix";
  let badgeText = `Due in ${numberOfDays} day${numberOfDays !== 1 ? "s" : ""}.`;
  let badgeAppearance: "warning" | "danger" | "neutral" = "warning";
  if (isCancelled) {
    headingText = "Your policy has been cancelled.";
    badgeText = "Policy cancelled";
    badgeAppearance = "neutral";
  } else if (pendingCancellationDate) {
    headingText = "Please fix the following issues to keep your policy active";
    badgeText = "Pending cancellation";
    badgeAppearance = "danger";
  }

  return (
    <ThumbtackModalProvider>
      <div
        className={submitting ? styles.loadingActive : styles.loadingOverlay}
      >
        {submitting && <Spinner className={styles.loadingSpinnerDS} />}

        <MainLayout
          topRow={
            <Col>
              <div className={styles.topRow}>
                <Button
                  appearance="link"
                  leadingIcon="Back"
                  onPress={() => navigate("/my/overview")}
                >
                  Back to Policy Overview
                </Button>
                <div className={styles.headerContainer}>
                  <div className={styles.header}>
                    <Heading size="lg">{headingText}</Heading>
                    <Badge
                      appearance={badgeAppearance}
                      icon={isCancelled ? undefined : "Clock"}
                    >
                      {badgeText}
                    </Badge>
                  </div>
                  {pendingCancellationDate ? (
                    <Span>
                      Your policy is set to cancel on{" "}
                      {formatDate(pendingCancellationDate, "MM/DD/YYYY")}.
                      Please repair the issues or contact your agent about next
                      steps.
                    </Span>
                  ) : null}
                </div>
              </div>
            </Col>
          }
          sidebar={
            <div className={styles.sidebarCards}>
              {isCancelled ? (
                <CancelledPolicySidebar />
              ) : (
                <InspectionReviewSidebar
                  setShowContactModal={setShowContactModal}
                />
              )}
              {showSuccessAlert ? <InsuranceRepresentative /> : null}
            </div>
          }
        >
          <Card className={styles.mainCardDS} border>
            <Heading size="lg" className={styles.cardHeadingDS}>
              Details
            </Heading>
            {referralList?.map((referral, idx) => {
              const { underwritingCondition } = referral ?? {};
              return underwritingCondition ? (
                <Fragment key={idx}>
                  <UnderwritingConditionItem
                    underwritingCondition={underwritingCondition}
                    zipCode={zip}
                  />
                  <Hr />
                </Fragment>
              ) : null;
            })}
            <UploadProof
              setSubmitting={setSubmitting}
              onSubmitSuccess={() => setShowSuccessAlert(true)}
              showSuccessAlert={showSuccessAlert}
            />
          </Card>
          <CustomerCareContactModal
            show={showContactModal}
            handleClose={() => setShowContactModal(false)}
            description="We can assist with any questions about billing or your SageSure account."
          />
        </MainLayout>
      </div>
      <PageFooter />
    </ThumbtackModalProvider>
  );
};
