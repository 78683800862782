import {
  ALARM_ENUM_DISPLAY_MAP,
  PROPERTY_ROOF_CODE_MAP,
  PROPERTY_ROOF_UPDATE_TYPE,
  TRAMPOLINE_ENUM_DISPLAY_MAP,
} from "consts";
import { type PropertyUpdateData } from "gql/__generated__/hooks";

export const checkRoofUpdates: (
  updateData?: PropertyUpdateData[] | null,
  roofData?: {
    constructionYearRoof?: number | null;
    roofCoveringType?: string | null;
  }
) => {
  constructionYearDisplay: string;
  roofCoveringDisplay: string;
  roofPending: boolean;
} = (updateData, roofData) => {
  const roofUpdateData = updateData?.find(
    (updates) => updates.detailName === "ROOF"
  );
  const roofUpdates = roofUpdateData?.items;
  const { constructionYearRoof, roofCoveringType } = roofData ?? {};
  const isStatusCompleted = roofUpdateData?.taskStatus === "solved";

  const roofMaterial = PROPERTY_ROOF_CODE_MAP[roofCoveringType ?? ""];
  const constructionYearDisplay = constructionYearRoof?.toString() ?? "Unknown";

  if (!roofUpdates?.length || isStatusCompleted) {
    // No updates; show existing roof data
    return {
      constructionYearDisplay,
      roofCoveringDisplay:
        PROPERTY_ROOF_UPDATE_TYPE[roofMaterial]?.label ?? "None",
      roofPending: false,
    };
  }

  const updatedYear = roofUpdates.find(
    (update) => update?.termName === "Roof Year"
  )?.enumerationValue;
  const updatedMaterialEnum = roofUpdates.find(
    (update) => update?.termName === "Roof Material"
  )?.enumerationValue;
  const updatedMaterial = PROPERTY_ROOF_CODE_MAP[updatedMaterialEnum ?? ""];

  return {
    constructionYearDisplay: updatedYear ?? constructionYearDisplay,
    roofCoveringDisplay:
      PROPERTY_ROOF_UPDATE_TYPE[updatedMaterial ?? roofMaterial]?.label ??
      "None",
    roofPending: !isStatusCompleted,
  };
};

export const checkSecurityUpdates: (
  updateData?: PropertyUpdateData[] | null,
  securityData?: {
    burglarAlarm?: string | null;
    fireAlarm?: string | null;
  }
) => {
  burglarAlarmDisplay: string;
  fireAlarmDisplay: string;
  securityPending: boolean;
} = (updateData, securityData) => {
  const securityUpdateData = updateData?.find(
    (updates) => updates.detailName === "SECURITY"
  );
  const securityUpdates = securityUpdateData?.items;
  const { burglarAlarm, fireAlarm } = securityData ?? {};
  const isStatusCompleted = securityUpdateData?.taskStatus === "solved";

  if (!securityUpdates?.length || isStatusCompleted) {
    // No updates; show existing security data
    return {
      burglarAlarmDisplay: ALARM_ENUM_DISPLAY_MAP[burglarAlarm ?? 1],
      fireAlarmDisplay: ALARM_ENUM_DISPLAY_MAP[fireAlarm ?? 1],
      securityPending: false,
    };
  }

  const updatedBurglarAlarmEnum = securityUpdates.find(
    (update) => update?.termName === "Burglar Alarm"
  )?.enumerationValue;
  const updatedFireAlarmEnum = securityUpdates.find(
    (update) => update?.termName === "Fire Alarm"
  )?.enumerationValue;

  return {
    burglarAlarmDisplay:
      ALARM_ENUM_DISPLAY_MAP[updatedBurglarAlarmEnum ?? burglarAlarm ?? 1],
    fireAlarmDisplay:
      ALARM_ENUM_DISPLAY_MAP[updatedFireAlarmEnum ?? fireAlarm ?? 1],
    securityPending: !isStatusCompleted,
  };
};

export const checkTrampolineUpdates: (
  updateData?: PropertyUpdateData[] | null,
  trampolineData?: {
    trampoline?: boolean | null;
  }
) => {
  trampolineDisplay: string;
  trampolinePaddingDisplay?: string | null;
  trampolinePending: boolean;
} = (updateData, trampolineData) => {
  const trampolineUpdateData = updateData?.find(
    (updates) => updates.detailName === "TRAMPOLINE"
  );
  const trampolineUpdates = trampolineUpdateData?.items;
  const { trampoline } = trampolineData ?? {};
  const prevTrampolineEnum = trampoline ? "100" : "200";
  const isStatusCompleted = trampolineUpdateData?.taskStatus === "solved";

  if (!trampolineUpdates?.length || isStatusCompleted) {
    // No updates; show existing trampoline data
    return {
      trampolineDisplay: trampoline ? "Yes" : "None",
      trampolinePending: false,
    };
  }

  const updatedTrampolineEnum = trampolineUpdates.find(
    (update) => update?.termName === "Trampoline"
  )?.enumerationValue;
  const updatedTrampolinePaddingEnum = trampolineUpdates.find(
    (update) => update?.termName === "Shock-absorbing Padding"
  )?.enumerationValue;

  return {
    trampolineDisplay:
      TRAMPOLINE_ENUM_DISPLAY_MAP[
        updatedTrampolineEnum ?? prevTrampolineEnum ?? "200"
      ],
    trampolinePaddingDisplay:
      TRAMPOLINE_ENUM_DISPLAY_MAP[updatedTrampolinePaddingEnum ?? "200"],
    trampolinePending: !isStatusCompleted,
  };
};
