import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Link as DSLink,
  Heading,
  Hr,
  Icon,
  List,
  Span,
  Tooltip,
} from "@icg360/design-system";

import { EmailIconLink, PhoneIconLink } from "components/common/link";
import {
  GalleryModalProvider,
  useGalleryContext,
} from "components/gallery-modal";
import { AuthAppContext } from "components/root/auth-app-provider";
import {
  ThumbtackModalProvider,
  useThumbtackModalContext,
} from "components/thumbtack-modal";

import styles from "./home-services.module.scss";

export type InspectionImage = {
  url: string;
  desc: string;
};

type InspectionWriteOutsProps = {
  referrals: Referral[];
  images: InspectionImage[];
};

export type Referral = {
  underwritingCondition: {
    referralCode: string;
    reasoning?: string;
    issueStatements?: string[];
    actionStatement: string;
    contractor?: string;
    thumbtackSearchTerm: string;
    proof?: string;
  };
  location: string;
  issues: {
    issueDetails: {
      images: { [key: string]: { baseUrl: string } };
      text: string;
    }[];
  }[];
};

export const InspectionWriteOuts = ({
  referrals,
  images,
}: InspectionWriteOutsProps) => {
  const navigate = useNavigate();
  const {
    userInsuranceRep: agent,
    propertyAddress,
    selectedPolicy,
  } = useContext(AuthAppContext);
  const isIneligible = selectedPolicy?.inspection?.statusEnum === "480";

  if (isIneligible) {
    navigate("/my/inspection-review/ineligible");
    return;
  }

  return (
    <GalleryModalProvider>
      <ThumbtackModalProvider>
        <section className={styles.renewalContainer}>
          <Card>
            <header className={styles.header}>
              <Heading size="md">Steps required to keep your policy</Heading>
              <p>
                Based on our last inspection, there are repairs and policy
                updates that need to be made to keep your policy.
              </p>
            </header>
            <div>
              <Heading size="sm">1. Make these repairs</Heading>
              <div className={styles.stepDetails}>
                <List>
                  {referrals.map((ref) => (
                    <ReferralItem
                      condition={ref.underwritingCondition}
                      location={ref.location}
                      zip={propertyAddress?.zip}
                      key={ref.underwritingCondition.referralCode}
                    />
                  ))}
                </List>
              </div>
              {images.length > 0 && (
                <div className={styles.photos}>
                  <Heading size="sm">Photos</Heading>
                  <p>View photos taken during the inspection.</p>
                  <div className={styles.thumbnails}>
                    {images.slice(0, 5).map((img, i) => (
                      <Thumbnail images={images} index={i} key={img.url} />
                    ))}
                    {images.length > 5 && (
                      <MoreButton images={images} max={5} />
                    )}
                  </div>
                </div>
              )}
            </div>
            <Hr spacing="2xl" />
            <div>
              <Heading size="sm">
                2. Send your insurance representative proof they&apos;re done
              </Heading>
              <div className={styles.stepDetails}>
                <div className={styles.agentAddress}>
                  {agent?.agencyName && <div>{agent.agencyName}</div>}
                  {agent?.agentStreetNumber || agent?.agentStreetName ? (
                    <div>{`${agent?.agentStreetNumber ?? ""} ${
                      agent?.agentStreetName ?? ""
                    }`}</div>
                  ) : null}
                  <div>
                    {agent?.agentCity && agent?.agentState && (
                      <span>{`${agent.agentCity}, ${agent.agentState}`}</span>
                    )}{" "}
                    {agent?.agentZipCode && <span>{agent.agentZipCode}</span>}
                  </div>
                </div>
                {agent?.agentEmail ? (
                  <div>
                    <EmailIconLink email={agent?.agentEmail} />
                  </div>
                ) : null}
                {agent?.agentPhone ? (
                  <div>
                    <PhoneIconLink phone={agent?.agentPhone} />
                  </div>
                ) : null}
              </div>
            </div>
            <Hr spacing="2xl" />
            <div>
              <Heading size="sm">3. Wait while we review everything</Heading>
              <div className={styles.stepDetails}>
                <p>
                  If everything looks good we’ll work with your insurance
                  representative to renew your policy. This usually takes about
                  a week. If you have any questions, contact your insurance
                  representative.
                </p>
              </div>
            </div>
          </Card>
        </section>
      </ThumbtackModalProvider>
    </GalleryModalProvider>
  );
};

const Thumbnail = ({ images, index }) => {
  const { openGallery } = useGalleryContext();
  const img = images[index];
  return (
    <a
      className={styles.referralThumbLink}
      href={img.url}
      onClick={(e) => {
        e.preventDefault();
        openGallery(images, index);
      }}
    >
      <img src={img.url} className={styles.referralThumb} alt="inspection" />
    </a>
  );
};

const MoreButton = ({ images, max }) => {
  const { openGallery } = useGalleryContext();
  return (
    <Button
      appearance="link"
      onPress={() => {
        openGallery(images, max);
      }}
    >
      + {images.length - max} more
    </Button>
  );
};

type ReferralItemProps = {
  location: Referral["location"];
  condition: Referral["underwritingCondition"];
  zip?: string | null;
};

const ReferralItem = ({ location, condition, zip }: ReferralItemProps) => {
  const { openThumbtackModal } = useThumbtackModalContext();
  let tip = condition.reasoning;
  // For roof replacements we generate the tooltip from a list of issues.
  if (!condition.reasoning && (condition.issueStatements ?? []).length > 0) {
    tip = `Based on what we found (${condition.issueStatements?.join(
      ", "
    )}), a complete roof replacement is needed.`;
  }

  return (
    <li>
      <div className={styles.referralContainer}>
        <Heading size="sm">
          {condition.actionStatement}
          {tip && (
            <Tooltip content={tip} direction="right">
              <Span className={styles.moreInfoDS} color="quiet">
                <Icon name="Question" />
              </Span>
            </Tooltip>
          )}
        </Heading>
        <div className={styles.whoWhatWhere}>
          {location && (
            <p>
              <strong>Where’s the issue:</strong> {location}
            </p>
          )}
          {condition.contractor && (
            <p>
              <strong>Who should fix it:</strong>{" "}
              <DSLink
                onPress={() => {
                  openThumbtackModal(zip ?? "", condition.thumbtackSearchTerm);
                }}
              >
                {condition.contractor}
              </DSLink>
            </p>
          )}
          {condition.proof && (
            <p>
              <strong>What you need to do:</strong> {condition.proof}
            </p>
          )}
        </div>
      </div>
    </li>
  );
};
