import { useContext } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import { useSSData } from "data/ss-store";
import { useResetPolicyMutation } from "gql/__generated__/hooks";
import { logout, trackEvent } from "utils";

export const useLogout = () => {
  const { featureFlags } = useSSData();
  const [resetPolicy] = useResetPolicyMutation();
  const { selectedPolicyId } = useContext(AuthAppContext);

  const logoutHandler = async () => {
    trackEvent("Log Out");

    if (!featureFlags.universalLogin && selectedPolicyId) {
      await resetPolicy({ variables: { policyID: selectedPolicyId } });
    }

    await logout();
  };

  return { logout: logoutHandler };
};
