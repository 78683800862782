import { Field, Form, Formik, useFormikContext } from "formik";
import cloneDeep from "lodash.clonedeep";

import { Button } from "@icg360/design-system";

import { InputField } from "components/shared/form-fields";
import { updatePhoneNumberSchema } from "consts";
import { useUpdateInsuredInfoMutation } from "gql/__generated__/hooks";
import { contactItemDataQuery } from "gql/queries";
import { formatPhone, logError, trackEvent, useFlags } from "utils";

import styles from "./update-phone-number.module.scss";

const initialValues = {
  phoneNumber: "",
  confirmPhoneNumber: "",
};

type UpdatePhoneNumberProps = {
  policyId: string;
  userEmail: string;
  onContactInfoUpdateError: () => void;
  onContactInfoUpdateSuccess: () => void;
  onCancelForm: () => void;
  updatePhoneDisplay: (phoneNumber) => void;
};

const UpdatePhoneNumber = ({
  policyId,
  userEmail,
  onContactInfoUpdateError,
  onContactInfoUpdateSuccess,
  onCancelForm,
  updatePhoneDisplay,
}: UpdatePhoneNumberProps) => {
  const { retireProxy } = useFlags();
  const [updatePhoneNumber] = useUpdateInsuredInfoMutation();

  const handleSubmit = async (
    { phoneNumber },
    { setSubmitting, resetForm }
  ) => {
    try {
      setSubmitting(true);

      const { data: updatePhoneNumberData } = await updatePhoneNumber({
        variables: {
          policyID: policyId,
          emailAddress: userEmail,
          userUpdates: {
            phoneNumber: phoneNumber.replace(/[\D]/g, ""),
          },
        },
        update: (store, { data }) => {
          if (data?.updateInsuredInfo) {
            if (retireProxy) {
              updatePhoneDisplay(formatPhone(phoneNumber.replace(/[\D]/g, "")));
              return;
            } else {
              const readData = cloneDeep(
                store.readQuery<contactItemDataQuery>({
                  query: contactItemDataQuery,
                  variables: { policyID: policyId },
                })
              );

              if (phoneNumber && readData) {
                readData.userClaimsConstants = {
                  ...readData.userClaimsConstants,
                  PolicyHolderDetails: {
                    ...readData.userClaimsConstants.PolicyHolderDetails,
                    phoneNumber: formatPhone(phoneNumber.replace(/[\D]/g, "")),
                  },
                };

                store.writeQuery({
                  query: contactItemDataQuery,
                  data: readData,
                  variables: { policyID: policyId },
                });
              }
            }
          }
        },
        context: {
          clientName: "keystone-api",
        },
      });

      if (updatePhoneNumberData?.updateInsuredInfo) {
        trackEvent("Update Phone Number - complete");
        onContactInfoUpdateSuccess();
        resetForm();
      } else {
        trackEvent("Error Displayed (Update Contact Phone Number)");
        onContactInfoUpdateError();
      }

      setSubmitting(false);
    } catch (err) {
      trackEvent("Error Displayed (Update Contact Phone Number)", err);
      onContactInfoUpdateError();
      setSubmitting(false);
      logError(`Update phone number: ${err.message}`);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={updatePhoneNumberSchema}
    >
      <UpdatePhoneNumberForm onCancelForm={onCancelForm} />
    </Formik>
  );
};

type UpdatePhonNumberFormProps = {
  onCancelForm: () => void;
};

const UpdatePhoneNumberForm = ({ onCancelForm }: UpdatePhonNumberFormProps) => {
  const { dirty, isSubmitting, isValid } = useFormikContext();

  return (
    <Form>
      <div className={styles.fieldsWrapper}>
        <Field
          component={InputField}
          name="phoneNumber"
          title="New phone number"
        />
        <Field
          component={InputField}
          name="confirmPhoneNumber"
          title="Confirm phone number"
        />
      </div>
      <div className={styles.buttonRow}>
        <Button
          appearance="tertiary"
          onPress={onCancelForm}
          data-testid="phone-button-cancel"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!isValid || !dirty || isSubmitting}
          loading={isSubmitting}
          data-testid="phone-update-btn"
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default UpdatePhoneNumber;
