import { createContext, useContext, useEffect, useState } from "react";

import { CONFIG } from "config";

import { KeystoneApiClient } from "../utils/keystone-api-client";
import { logError } from "../utils/logger";

/**
 * A container that emits updates to subscribers when state changes.
 * This is an internal implementation detail. To access current state from
 * within the React context use the hook -- `useSSUserData`.
 *
 * To access state outside of React, use `ssData` export.
 */
class SSDataStore {
  state: SSData;
  private listeners: Array<(state: SSData) => void>;

  constructor(init: SSData) {
    this.state = init;
    this.listeners = [];
  }

  update(change: Partial<SSData>) {
    Object.keys(change).forEach((k) => {
      this.state[k] = change[k];
    });
    this.emit();
  }

  subscribe(callback: (state: SSData) => void) {
    this.listeners.push(callback);
  }

  private emit() {
    this.listeners.forEach((fn) => fn(this.state));
  }
}

// Parse data rendered into the page.
const store = new SSDataStore({ featureFlags: {} });
try {
  const parsed = JSON.parse(window.SS_DATA);
  if (parsed) {
    store.update(parsed);
  }
} catch (err) {
  logError(`parsing server-side data: ${err.message}`);
}

/**
 * For accessing current user state outside of React context.
 */
export const ssData = store.state;

// Only use for debugging! Type SS_STATE in the console to view.
window.SS_STATE = ssData;

/**
 * Reference to centralized instance of the Keystone API client.
 *
 * It's also available via the useKeystone hook with the React context.
 */
export const keystone = new KeystoneApiClient(CONFIG.KEYSTONE_API_HREF);
if (ssData.user?.token) {
  keystone.setToken(ssData.user.token);
}

// Setup context
const initData = {
  state: ssData,
};
type SSDataContextValue = typeof initData;
const SSDataContext = createContext<SSDataContextValue>(initData);

/**
 * Provides access to user data and Keystone API client, including state
 * rendered by the server.
 */
export const SSDataProvider = ({ children }) => {
  const [state, setState] = useState(initData);
  useEffect(() => {
    store.subscribe((update) => {
      setState(() => {
        return { state: update };
      });
    });
  }, []);
  return (
    <SSDataContext.Provider value={state}>{children}</SSDataContext.Provider>
  );
};

/**
 * Access user data in React context.
 */
export const useSSData = () => {
  const context = useContext(SSDataContext);
  if (!context) {
    throw new Error("useSSData must be used within an SSDataProvider");
  }
  return context.state;
};

/**
 * Access Keystone API Client.
 */
export const useKeystone = () => {
  return keystone;
};
