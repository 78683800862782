import { useContext, useEffect, useState } from "react";
import {
  type KeystoneOfferEligibility,
  type OfferStatusCode,
} from "src/utils/keystone-api-mapping";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import {
  authHeaders,
  handleUnauthorized,
  logError,
  trackEvent,
  useFlags,
} from "utils";

const ELIGIBLE_STATUSES: OfferStatusCode[] = ["ELIGIBLE", "SENT", "OFFERED"];

export const useFeaturedOffers = () => {
  const { featuredOffers: featuredOffersFlag, shingleResealOffer } = useFlags();
  const { userDetails } = useContext(AuthAppContext);
  const [featuredOffers, setFeaturedOffers] = useState<
    KeystoneOfferEligibility[]
  >([]);

  useEffect(() => {
    if (!userDetails?.keystonePolicyId || !featuredOffersFlag) {
      return;
    }

    const implementedOfferingNames = ["ting-offer"];
    if (shingleResealOffer) {
      implementedOfferingNames.push("shingle-opportunity");
    }

    (async () => {
      try {
        const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/offers/${userDetails.keystonePolicyId}?featured=true`;
        const res = await fetch(endpoint, {
          headers: authHeaders({
            Accept: "application/json",
            "content-type": "application/json",
          }),
          credentials: "include",
        });
        if (res.ok) {
          const offers = (await res.json()).filter(
            (offer) =>
              ELIGIBLE_STATUSES.includes(offer.offerStatusCode) &&
              implementedOfferingNames.includes(offer.offeringInternalName)
          );
          setFeaturedOffers(offers);
          trackEvent("Featured Offers Loaded", {
            offers: offers.map((offer) => offer.offeringInternalName),
          });
        } else {
          if ([401, 403].includes(res.status)) {
            await handleUnauthorized({ endpoint });
          }
        }
      } catch (err) {
        // swallow the error, we just won't show any featured offers
        logError(`Featured offers: ${err.message}`);
      }
    })();
  }, [featuredOffersFlag, shingleResealOffer, userDetails?.keystonePolicyId]);

  return featuredOffers;
};
