import { CheckboxGroup, Heading, Span } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import {
  PropertyUpdateTrampolineContext,
  type PropertyUpdateTrampolineContextState,
} from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import styles from "./property-update-trampoline.module.scss";

export const TrampolineConfirmation = () => {
  const { state, setFields } =
    useMultiStepFormState<PropertyUpdateTrampolineContextState>(
      PropertyUpdateTrampolineContext
    );

  return (
    <PropertyUpdateLayout
      step={3}
      numSteps={4}
      heading="Update roof details"
      buttonProps={{
        primaryDisabled: !state?.confirmNoTrampoline,
        primaryPath: "../review",
      }}
    >
      <div className={styles.confirmationWrapper}>
        <Heading size="md">Please review and confirm your updates</Heading>
        <div className={styles.confirmationDetails}>
          <Span>
            I confirm that I no longer own or have never owned a trampoline on
            my property. I agree to notify SageSure in the future if I add a
            trampoline, and I understand liability coverage is excluded.
          </Span>

          <CheckboxGroup
            aria-label="Yes, I agree."
            value={[state?.confirmNoTrampoline && "confirm"]}
          >
            <CheckboxGroup.Checkbox
              value="confirm"
              onChange={(val) => {
                setFields({ confirmNoTrampoline: val });
              }}
            >
              Yes, I agree
            </CheckboxGroup.Checkbox>
          </CheckboxGroup>
        </div>
      </div>
    </PropertyUpdateLayout>
  );
};
