import { Heading, RadioGroup, Span } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import {
  PropertyUpdateTrampolineContext,
  type PropertyUpdateTrampolineContextState,
} from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import styles from "./property-update-trampoline.module.scss";

export const TrampolineOnProperty = () => {
  const { state, setFields } =
    useMultiStepFormState<PropertyUpdateTrampolineContextState>(
      PropertyUpdateTrampolineContext
    );

  return (
    <PropertyUpdateLayout
      step={1}
      numSteps={3}
      heading="Update trampoline details"
      buttonProps={{
        primaryDisabled: !state?.trampoline,
        primaryPath: state?.trampoline === "yes" ? "padding" : "proof",
      }}
    >
      <div className={styles.radioContentWrapper}>
        <Heading size="md">Do you have a trampoline on the property?</Heading>
        <RadioGroup
          aria-label="Trampoline options"
          defaultValue={state?.trampoline}
          onChange={(value: "yes" | "no") => setFields({ trampoline: value })}
          className={styles.radioGroupDS}
          grid
        >
          <RadioGroup.Card title="Yes" value="yes" />
          <RadioGroup.Card title="No" value="no" />
        </RadioGroup>
        <Span color="quiet">
          Your policy may contain liability coverage restrictions regarding your
          trampoline, please contact your insurance representative for further
          guidance.
        </Span>
      </div>
    </PropertyUpdateLayout>
  );
};
