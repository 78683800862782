import { Heading, RadioGroup, Span } from "@icg360/design-system";
import { type ChoiceCardProps } from "@icg360/design-system/dist/src/components/inputs/choice-cards";

import { type AlarmType } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import {
  PropertyUpdateSecurityContext,
  type PropertyUpdateSecurityContextState,
} from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import { PropertyUpload } from "../property-upload";
import styles from "./property-update-security.module.scss";

export type AlarmChoiceProps = Omit<ChoiceCardProps, "value"> & {
  value: AlarmType;
};

export const alarmOptions: AlarmChoiceProps[] = [
  {
    title: "No",
    value: "none",
  },
  {
    title: "Yes, a local alarm",
    value: "local",
  },
  {
    title: "Yes, a central alarm",
    value: "central",
  },
];

export const SecurityBurglarAlarm = () => {
  const STEP = 1;
  const { setFields, state } =
    useMultiStepFormState<PropertyUpdateSecurityContextState>(
      PropertyUpdateSecurityContext
    );

  const nextDisabled =
    state?.burglarAlarm === "central" && !state?.burglarAlarmFiles?.length;

  return (
    <PropertyUpdateLayout
      step={STEP}
      numSteps={3}
      heading="Update security details"
      buttonProps={{ primaryPath: "fire-alarm", primaryDisabled: nextDisabled }}
    >
      <div>
        <Heading size="md" className={styles.questionHeadingDS}>
          Do you have a burglar alarm system?
        </Heading>
        <RadioGroup
          aria-label="Burglar alarm options"
          defaultValue={state?.burglarAlarm ?? "none"}
          onChange={(value: AlarmType) => setFields({ burglarAlarm: value })}
          className={styles.radioGroupDS}
          grid
        >
          {alarmOptions.map((option) => (
            <RadioGroup.Card key={option.value} {...option} />
          ))}
        </RadioGroup>
      </div>
      {state?.burglarAlarm === "central" ? (
        <div className={styles.uploadSection}>
          <Span className={styles.uploadPromptDS}>
            Please upload the <Span bold>alarm certificate</Span>:
          </Span>
          <PropertyUpload
            filesField="burglarAlarmFiles"
            propertyType="security"
            state={state}
            setFields={setFields}
          />
        </div>
      ) : null}
    </PropertyUpdateLayout>
  );
};
