import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * Currency Scalar type accepts numbers and strings with '$' sign
   * Any other types are invalid
   */
  Currency: { input: any; output: any };
  /**
   * extend type Query {
   *  policyPaymentPlans(policyID: ID!): [PaymentPlanDetails]!
   *  easypayProcessingFees(policyID: ID!): String!
   *  serviceFees(policyID: ID!): String!
   * }
   *  Date is month day and year only. It is assumed to be
   *  in the timezone of the policy
   *  Ex: "2019-01-31"
   */
  Date: { input: any; output: any };
  /**
   * DateTime is the full timestamp. This
   * represents an exact instance in time.
   * Ex: "2011-10-05T14:48:00.000Z"
   */
  DateTime: { input: any; output: any };
  /**
   * Generic JSON scalar
   * Used for stubbing data
   */
  JSON: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type AchData = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  authorize?: InputMaybe<Scalars["Boolean"]["input"]>;
  bankStatementName?: InputMaybe<Scalars["String"]["input"]>;
  confirmAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  confirmRoutingNumber?: InputMaybe<Scalars["String"]["input"]>;
  draftDate?: InputMaybe<Scalars["String"]["input"]>;
  easypayMethod?: InputMaybe<Scalars["String"]["input"]>;
  financialInstitutionName?: InputMaybe<Scalars["String"]["input"]>;
  routingNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Accounting = {
  __typename?: "Accounting";
  amountDue?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["String"]["output"]>;
  easyPayAccount?: Maybe<EasyPayDetails>;
  invoiceAmountCurrent?: Maybe<Scalars["String"]["output"]>;
  invoiceAmountNext?: Maybe<Scalars["String"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["String"]["output"]>;
  minimumPayment?: Maybe<Scalars["String"]["output"]>;
  minimumPaymentDue?: Maybe<Scalars["String"]["output"]>;
  outstandingBalanceDue?: Maybe<Scalars["String"]["output"]>;
  paymentPlan?: Maybe<PaymentPlan>;
  remainingBalance?: Maybe<Scalars["String"]["output"]>;
  totalBalance?: Maybe<Scalars["String"]["output"]>;
};

export type AdditionalCoverages = {
  __typename?: "AdditionalCoverages";
  identityFraudCoverage?: Maybe<Scalars["String"]["output"]>;
  identityFraudCoveragePremium?: Maybe<Scalars["String"]["output"]>;
  identityFraudIncreaseLimit?: Maybe<Scalars["String"]["output"]>;
  identityFraudTotalLimit?: Maybe<Scalars["String"]["output"]>;
  mechanicalBreakdownCoverage?: Maybe<Scalars["String"]["output"]>;
  mechanicalBreakdownCoveragePremium?: Maybe<Scalars["String"]["output"]>;
  mechanicalElectricalEquipmentGroundLevel?: Maybe<Scalars["String"]["output"]>;
  serviceLineCoverage?: Maybe<Scalars["String"]["output"]>;
  serviceLineDeductible?: Maybe<Scalars["String"]["output"]>;
  serviceLinePremium?: Maybe<Scalars["String"]["output"]>;
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  street1?: Maybe<Scalars["String"]["output"]>;
  street2?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
  zipPlus4?: Maybe<Scalars["String"]["output"]>;
};

export type Adjuster = {
  __typename?: "Adjuster";
  AdjusterName: Scalars["String"]["output"];
  DateAssigned: Scalars["String"]["output"];
  Email?: Maybe<Scalars["String"]["output"]>;
  PhoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export type Bees360Content = {
  __typename?: "Bees360Content";
  bees360ReferralList?: Maybe<Array<Maybe<Bees360Referral>>>;
  projectId?: Maybe<Scalars["Int"]["output"]>;
  statusEnum?: Maybe<Scalars["Int"]["output"]>;
};

export type Bees360Referral = {
  __typename?: "Bees360Referral";
  factors?: Maybe<Array<Maybe<Factor>>>;
  location?: Maybe<Scalars["String"]["output"]>;
  referralActionRequired?: Maybe<Scalars["String"]["output"]>;
  referralCategory?: Maybe<ReferralCategory>;
  resolved?: Maybe<Scalars["Boolean"]["output"]>;
  underwritingAction?: Maybe<UnderwritingAction>;
  underwritingCondition?: Maybe<UnderwritingCondition>;
};

export type Billing = {
  __typename?: "Billing";
  accounting?: Maybe<Accounting>;
  billingAddress?: Maybe<BillingAddress>;
  installments?: Maybe<Array<Installment>>;
  mortgagees?: Maybe<Array<Mortgagee>>;
  pendingChanges?: Maybe<PendingChanges>;
};

export type BillingAddress = {
  __typename?: "BillingAddress";
  line1?: Maybe<Scalars["String"]["output"]>;
  line2?: Maybe<Scalars["String"]["output"]>;
  line3?: Maybe<Scalars["String"]["output"]>;
};

export type BridgeDataInput = {
  email: Scalars["String"]["input"];
  origin: Origin;
  password: Scalars["String"]["input"];
  policyNumber: Scalars["String"]["input"];
  uuid: Scalars["String"]["input"];
};

/**
 * extend type Mutation {
 *  resetPolicy(policyID: String!): Boolean
 *  updateMortgagee(mortgagee: MortgageeInput!, policyId: String!, mortgageeIndex: Int!, readablePolicyId: String): Mortgagee!
 *  updateMailingAddress(mailingAddress: MailingAddressInput!, policyId: String!): Address!
 *  updateData(policyID: String!): Boolean!
 *  TODO remove these generically named paperless mutations after updating keystone-ui usage
 *  updatePaperlessEmail(opInsuredEmailAddressBilling: String!, policyID: String!): Boolean!
 *  paperlessEnroll(policyID: String!): PaperlessEmails!
 *   paperlessUnenroll(policyID: String!): Boolean!
 *  updatePaperlessBillingEmail(opInsuredEmailAddressBilling: String!, policyID: String!): Boolean!
 *   paperlessBillingEnroll(policyID: String!): PaperlessEmails!
 *  paperlessBillingUnenroll(policyID: String!): Boolean!
 *  updatePaperlessDocumentsEmail(opInsuredEmailAddressDocuments: String!, policyID: String!): Boolean!
 *  paperlessDocumentsEnroll(policyID: String!): Boolean!
 *  paperlessDocumentsUnenroll(policyID: String!): Boolean!
 *  paperlessDocumentsEnrollFromEmail(data: String!): Response!
 *  easypayEnroll(paymentData: PaymentData!): EasypayEnrollResponse!
 * }
 */
export type BurglarAlarmType = "CENTRAL" | "LOCAL" | "NONE";

export type CcData = {
  authorize?: InputMaybe<Scalars["Boolean"]["input"]>;
  cardExpirationDate?: InputMaybe<Scalars["String"]["input"]>;
  cardNumber?: InputMaybe<Scalars["String"]["input"]>;
  cardZipCode?: InputMaybe<Scalars["String"]["input"]>;
  cardholderName?: InputMaybe<Scalars["String"]["input"]>;
  confirmCardNumber?: InputMaybe<Scalars["String"]["input"]>;
  creditCardType?: InputMaybe<Scalars["String"]["input"]>;
  draftDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type CacheControlScope = "PRIVATE" | "PUBLIC";

export type CarrierClaimsDetails = {
  __typename?: "CarrierClaimsDetails";
  carrierDetailsText: Scalars["String"]["output"];
  carrierLogo: Scalars["String"]["output"];
  claimsActivityInitialText: Scalars["String"]["output"];
  claimsContactCarrierText: Scalars["String"]["output"];
  claimsFormReviewText: Scalars["String"]["output"];
  claimsReceiveCarrierCallText: Scalars["String"]["output"];
  claimsReviewText: Scalars["String"]["output"];
};

export type CarrierInfo = {
  __typename?: "CarrierInfo";
  email?: Maybe<Scalars["String"]["output"]>;
  fax?: Maybe<Scalars["String"]["output"]>;
  hours?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameShort?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  website?: Maybe<Scalars["String"]["output"]>;
};

export type Claim = {
  __typename?: "Claim";
  ClaimDescription?: Maybe<Scalars["String"]["output"]>;
  ClaimNumber?: Maybe<Scalars["String"]["output"]>;
  DateOfLoss?: Maybe<Scalars["String"]["output"]>;
  DateReceived?: Maybe<Scalars["String"]["output"]>;
  IndependentAdjusterName?: Maybe<Scalars["String"]["output"]>;
  IndependentAdjusterPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  LossCauseDescription?: Maybe<Scalars["String"]["output"]>;
  Status?: Maybe<Status>;
  SubmissionNumber?: Maybe<Scalars["String"]["output"]>;
  payments?: Maybe<Array<Payment>>;
};

export type ClaimConstants = {
  __typename?: "ClaimConstants";
  CarrierDetails: CarrierClaimsDetails;
  LossTypes: Array<Scalars["String"]["output"]>;
  PolicyHolderDetails: PolicyHolderDetails;
  PrimaryPhoneTypes: Array<Scalars["String"]["output"]>;
  RelationshipToCommercialPolicyHolderTypes: Array<Scalars["String"]["output"]>;
  RelationshipToPolicyHolderTypes: Array<Scalars["String"]["output"]>;
  SecondaryPhoneTypes: Array<Scalars["String"]["output"]>;
};

export type ClaimList = {
  __typename?: "ClaimList";
  ClaimsInfo?: Maybe<Array<Claim>>;
  ClaimsTrackerInfo?: Maybe<Array<ClaimTrackerItem>>;
  InsuredName?: Maybe<Scalars["String"]["output"]>;
  PolicyNumber?: Maybe<Scalars["String"]["output"]>;
  SubmissionsInfo?: Maybe<Array<Submission>>;
};

export type ClaimSubmittedBy = {
  __typename?: "ClaimSubmittedBy";
  Address?: Maybe<Scalars["String"]["output"]>;
  AlternatePhoneNumber?: Maybe<Scalars["String"]["output"]>;
  AlternatePhoneNumberType?: Maybe<Scalars["String"]["output"]>;
  City?: Maybe<Scalars["String"]["output"]>;
  Name?: Maybe<Scalars["String"]["output"]>;
  PrimaryPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  PrimaryPhoneNumberType?: Maybe<Scalars["String"]["output"]>;
  Relationship?: Maybe<Scalars["String"]["output"]>;
  State?: Maybe<Scalars["String"]["output"]>;
  Zip?: Maybe<Scalars["String"]["output"]>;
};

export type ClaimSubmittedByInput = {
  AlternatePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  AlternatePhoneNumberType?: InputMaybe<Scalars["String"]["input"]>;
  Name?: InputMaybe<Scalars["String"]["input"]>;
  PrimaryPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  PrimaryPhoneNumberType?: InputMaybe<Scalars["String"]["input"]>;
  Relationship?: InputMaybe<Scalars["String"]["input"]>;
};

export type ClaimTrackerCarrierClaim = {
  __typename?: "ClaimTrackerCarrierClaim";
  ClosedDate: Scalars["String"]["output"];
  CustomerContactDate: Scalars["String"]["output"];
  DateOfLoss: Scalars["String"]["output"];
  DateSubmitted: Scalars["String"]["output"];
  LossDescription: Scalars["String"]["output"];
  Number: Scalars["String"]["output"];
  TypeOfLoss: Scalars["String"]["output"];
};

export type ClaimTrackerFootNotes = {
  __typename?: "ClaimTrackerFootNotes";
  Disclaimer?: Maybe<FootNote>;
  Weather?: Maybe<FootNote>;
};

export type ClaimTrackerItem = {
  __typename?: "ClaimTrackerItem";
  Adjuster?: Maybe<Adjuster>;
  CarrierClaim?: Maybe<ClaimTrackerCarrierClaim>;
  FNOLSubmission?: Maybe<Submission>;
  FootNotes?: Maybe<ClaimTrackerFootNotes>;
  Inspection?: Maybe<Inspection>;
  Milestones?: Maybe<ClaimTrackerMilestones>;
  Payments?: Maybe<Array<ClaimTrackerPayment>>;
  Status: Status;
};

export type ClaimTrackerMilestones = {
  __typename?: "ClaimTrackerMilestones";
  AdjusterAssignment?: Maybe<Milestone>;
  ClaimReport?: Maybe<Milestone>;
  ClaimSettlement?: Maybe<Milestone>;
  CustomerContact?: Maybe<Milestone>;
  FieldInspectionReport?: Maybe<Milestone>;
  PropertyInspection?: Maybe<Milestone>;
};

export type ClaimTrackerPayment = {
  __typename?: "ClaimTrackerPayment";
  CheckNumber?: Maybe<Scalars["String"]["output"]>;
  Coverage?: Maybe<Scalars["String"]["output"]>;
  DateOfPayment: Scalars["String"]["output"];
  PayableTo?: Maybe<Scalars["String"]["output"]>;
  PaymentAmount: Scalars["String"]["output"];
};

export type CoApplicant = {
  __typename?: "CoApplicant";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type ContactInfo = {
  __typename?: "ContactInfo";
  AlternatePhoneNumber?: Maybe<Scalars["String"]["output"]>;
  AlternatePhoneNumberType?: Maybe<Scalars["String"]["output"]>;
  Email?: Maybe<Scalars["String"]["output"]>;
  Mobile?: Maybe<Scalars["String"]["output"]>;
  Name?: Maybe<Scalars["String"]["output"]>;
  PrimaryPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  PrimaryPhoneNumberType?: Maybe<Scalars["String"]["output"]>;
  Relationship?: Maybe<Scalars["String"]["output"]>;
  WantsToReceiveSMSUpdates?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ContactInfoInput = {
  AlternatePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  AlternatePhoneNumberType?: InputMaybe<Scalars["String"]["input"]>;
  Email?: InputMaybe<Scalars["String"]["input"]>;
  Mobile?: InputMaybe<Scalars["String"]["input"]>;
  Name?: InputMaybe<Scalars["String"]["input"]>;
  PrimaryPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  PrimaryPhoneNumberType?: InputMaybe<Scalars["String"]["input"]>;
  Relationship?: InputMaybe<Scalars["String"]["input"]>;
  WantsToReceiveSMSUpdates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Coverage = {
  __typename?: "Coverage";
  eligibility: Scalars["Boolean"]["output"];
  name: CoverageName;
  premiumNew?: Maybe<Scalars["Currency"]["output"]>;
  price?: Maybe<Scalars["Currency"]["output"]>;
  proratedPrice?: Maybe<Scalars["Currency"]["output"]>;
  protectionValue?: Maybe<Scalars["Int"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
};

/**
 * extend type Query {
 *  userClaimsByPolicy(policyID: ID!): [Claim]
 *  userClaimsList(policyID: ID!): ClaimList
 *  userClaimsConstants(policyID: ID!): ClaimConstants
 * }
 * extend type Mutation {
 *  submitClaim(claimSubmission: SubmissionInput!, policyID: String!): String!
 *  fnolChannelSubmission(event: JSON!): JSON!
 * }
 */
export type CoverageName =
  | "IDENTITY_FRAUD"
  | "MECHANICAL_BREAKDOWN"
  | "SERVICE_LINE";

export type CoverageUpsell = {
  __typename?: "CoverageUpsell";
  coverage?: Maybe<Coverage>;
  policyRenewal?: Maybe<PolicyRenewal>;
  premiumPrevious?: Maybe<Scalars["Currency"]["output"]>;
};

export type CurrentTerm = {
  __typename?: "CurrentTerm";
  agencyLocationCode?: Maybe<Scalars["String"]["output"]>;
  agentName?: Maybe<Scalars["String"]["output"]>;
  allOtherPerilsDeductible?: Maybe<Scalars["String"]["output"]>;
  coverageA?: Maybe<Scalars["String"]["output"]>;
  coverageB?: Maybe<Scalars["String"]["output"]>;
  coverageC?: Maybe<Scalars["String"]["output"]>;
  coverageD?: Maybe<Scalars["String"]["output"]>;
  coverageE?: Maybe<Scalars["String"]["output"]>;
  coverageEAggregate?: Maybe<Scalars["String"]["output"]>;
  coverageEPerOccurrence?: Maybe<Scalars["String"]["output"]>;
  coverageF?: Maybe<Scalars["String"]["output"]>;
  coverageL?: Maybe<Scalars["String"]["output"]>;
  coverageM?: Maybe<Scalars["String"]["output"]>;
  dbaName?: Maybe<Scalars["String"]["output"]>;
  effectiveDate?: Maybe<Scalars["Date"]["output"]>;
  effectiveDatePolicyTerm?: Maybe<Scalars["Date"]["output"]>;
  expirationDate?: Maybe<Scalars["Date"]["output"]>;
  hurricaneDeductible?: Maybe<Scalars["String"]["output"]>;
  hurricaneDeductibleAmount?: Maybe<Scalars["String"]["output"]>;
  identityFraudCoveragePremium?: Maybe<Scalars["String"]["output"]>;
  mechanicalBreakdownCoveragePremium?: Maybe<Scalars["String"]["output"]>;
  moldLimit?: Maybe<Scalars["String"]["output"]>;
  policyNumber?: Maybe<Scalars["String"]["output"]>;
  propertyDamageLiabilityDeductiblePerClaim?: Maybe<
    Scalars["String"]["output"]
  >;
  propertyDamageLiabilityDeductiblePerOccurrence?: Maybe<
    Scalars["String"]["output"]
  >;
  serviceLinePremium?: Maybe<Scalars["String"]["output"]>;
  termLocationData?: Maybe<Array<TermLocationData>>;
  totalPremium?: Maybe<Scalars["String"]["output"]>;
  windHailDeductible?: Maybe<Scalars["String"]["output"]>;
};

export type DecodeData = {
  __typename?: "DecodeData";
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  policyId?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
  zipcode?: Maybe<Scalars["String"]["output"]>;
};

export type DetailedRecipient = {
  __typename?: "DetailedRecipient";
  email?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  recipientId?: Maybe<Scalars["String"]["output"]>;
  recipientType?: Maybe<RecipientType>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type Details = {
  __typename?: "Details";
  additionalAddresses?: Maybe<Array<Address>>;
  coApplicant?: Maybe<Array<CoApplicant>>;
  currentTerm?: Maybe<CurrentTerm>;
  insuredPreferences?: Maybe<InsuredPreferences>;
  isBookRoll?: Maybe<Scalars["Boolean"]["output"]>;
  isCommercial?: Maybe<Scalars["Boolean"]["output"]>;
  isEnrolledInLeakBot?: Maybe<Scalars["Boolean"]["output"]>;
  keystonePolicyId?: Maybe<Scalars["String"]["output"]>;
  mailingAddress?: Maybe<Address>;
  mortgageeIndex?: Maybe<Scalars["Int"]["output"]>;
  policyStateAttributes?: Maybe<PolicyStateAttributes>;
  policyStatus?: Maybe<Scalars["String"]["output"]>;
  primaryInsured?: Maybe<PrimaryInsured>;
  propertyAddress?: Maybe<Address>;
  quoteNumber?: Maybe<Scalars["String"]["output"]>;
};

export type Document = {
  __typename?: "Document";
  group?: Maybe<Scalars["String"]["output"]>;
  href?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  lastUpdated?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Scalars["String"]["output"]>;
  route?: Maybe<Scalars["String"]["output"]>;
  templateId?: Maybe<Scalars["String"]["output"]>;
};

export type DocumentStatus =
  | "AUTHENTICATION_FAILED"
  | "COMPLETED"
  | "COMPLETED_OR_VOIDED_PURGED"
  | "COMPLETED_OR_VOIDED_PURGING_SOON"
  | "CORRECTING"
  | "DECLINED"
  | "DELIVERED"
  | "DELIVERY_FAILURE"
  | "DRAFT"
  | "EXPIRED"
  | "NEEDS_TO_SIGN"
  | "NEEDS_TO_VIEW"
  | "SENT"
  | "VOIDED"
  | "WAITING_FOR_OTHERS";

export type Documents = {
  __typename?: "Documents";
  grouped?: Maybe<Array<Array<Document>>>;
  latest?: Maybe<LatestDocuments>;
};

export type DocusignStatus = {
  __typename?: "DocusignStatus";
  apiUserId?: Maybe<Scalars["String"]["output"]>;
  completedDateTime?: Maybe<Scalars["DateTime"]["output"]>;
  createdDateTime?: Maybe<Scalars["DateTime"]["output"]>;
  documentStatus?: Maybe<DocumentStatus>;
  documentType?: Maybe<Scalars["String"]["output"]>;
  emailSubject?: Maybe<Scalars["String"]["output"]>;
  embeddedSigningUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  initialSentDateTime?: Maybe<Scalars["DateTime"]["output"]>;
  lastModifiedDateTime?: Maybe<Scalars["DateTime"]["output"]>;
  sentDateTime?: Maybe<Scalars["DateTime"]["output"]>;
  signers?: Maybe<Array<Maybe<DetailedRecipient>>>;
};

export type EasyPayDetails = {
  __typename?: "EasyPayDetails";
  lastFourDigits?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
};

export type EasypayEnrollResponse = {
  __typename?: "EasypayEnrollResponse";
  data?: Maybe<InsuredPreferences>;
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EndorsementUpdates = {
  aboveGroundPoolMinimumHeight?: InputMaybe<Scalars["Boolean"]["input"]>;
  burglarAlarm?: InputMaybe<BurglarAlarmType>;
  constructionYearRoof?: InputMaybe<Scalars["Int"]["input"]>;
  divingBoard?: InputMaybe<Scalars["Boolean"]["input"]>;
  fireAlarm?: InputMaybe<FireAlarmType>;
  poolAttachedToDecking?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolCovered?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolDeckGateLocks?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolFence?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolFenceLockingGate?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolFenceMinimumHeight?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolFilled?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolImmovableLadder?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolSlide?: InputMaybe<Scalars["Boolean"]["input"]>;
  poolType?: InputMaybe<PoolType>;
  roofCoveringType?: InputMaybe<RoofCoveringType>;
  trampoline?: InputMaybe<Scalars["Boolean"]["input"]>;
  trampolineHasShockAbsorbingPadding?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Factor = {
  __typename?: "Factor";
  factorDataList?: Maybe<Array<Maybe<FactorData>>>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type FactorData = {
  __typename?: "FactorData";
  direction?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Scalars["JSON"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type FireAlarmType = "CENTRAL" | "LOCAL" | "NONE";

export type FootNote = {
  __typename?: "FootNote";
  Message?: Maybe<Scalars["String"]["output"]>;
  Show?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Image = {
  __typename?: "Image";
  baseUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
};

export type Inspection = {
  __typename?: "Inspection";
  DateInspectionCompleted: Scalars["String"]["output"];
  DateReportCompleted?: Maybe<Scalars["String"]["output"]>;
  completed?: Maybe<Scalars["Boolean"]["output"]>;
  completedDate?: Maybe<Scalars["String"]["output"]>;
  confirmationNumber?: Maybe<Scalars["String"]["output"]>;
  dispositionCode?: Maybe<Scalars["String"]["output"]>;
  dispositionCodeEnum?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  newBusinessInspectionWriteOuts?: Maybe<NewBusinessInspectionMessage>;
  orderDate?: Maybe<Scalars["String"]["output"]>;
  previewUrl?: Maybe<Scalars["String"]["output"]>;
  renewalWriteOuts?: Maybe<Bees360Content>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusEnum?: Maybe<Scalars["String"]["output"]>;
  surveyDate?: Maybe<Scalars["String"]["output"]>;
  underwritingUpdatedDate?: Maybe<Scalars["String"]["output"]>;
  vendorName?: Maybe<Scalars["String"]["output"]>;
};

export type Installment = {
  __typename?: "Installment";
  amount?: Maybe<Scalars["Float"]["output"]>;
  charges?: Maybe<Scalars["Float"]["output"]>;
  dueDateMillis?: Maybe<Scalars["Date"]["output"]>;
  feesAndPremiums?: Maybe<Scalars["Float"]["output"]>;
  invoiceDateMillis?: Maybe<Scalars["Date"]["output"]>;
  number?: Maybe<Scalars["Float"]["output"]>;
};

export type InsuranceRep = {
  __typename?: "InsuranceRep";
  agencyName?: Maybe<Scalars["String"]["output"]>;
  agentCity?: Maybe<Scalars["String"]["output"]>;
  agentEmail?: Maybe<Scalars["String"]["output"]>;
  agentPhone?: Maybe<Scalars["String"]["output"]>;
  agentState?: Maybe<Scalars["String"]["output"]>;
  agentStreetName?: Maybe<Scalars["String"]["output"]>;
  agentStreetNumber?: Maybe<Scalars["String"]["output"]>;
  agentZipCode?: Maybe<Scalars["String"]["output"]>;
};

export type InsuredPreferences = {
  __typename?: "InsuredPreferences";
  easyPayEnrollmentStatus?: Maybe<Scalars["String"]["output"]>;
  opInsuredContactFirstName?: Maybe<Scalars["String"]["output"]>;
  opInsuredContactLastName?: Maybe<Scalars["String"]["output"]>;
  opInsuredEmailAddress?: Maybe<Scalars["String"]["output"]>;
  opInsuredEmailAddressBilling?: Maybe<Scalars["String"]["output"]>;
  opInsuredEmailAddressDocuments?: Maybe<Scalars["String"]["output"]>;
  paperlessBillingEnrollmentStatus?: Maybe<Scalars["String"]["output"]>;
  paperlessDocumentEnrollmentStatus?: Maybe<Scalars["String"]["output"]>;
};

export type InsuredUpdateables = {
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  mailingAddress?: InputMaybe<MailingAddress>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type IsLoggedIn = {
  __typename?: "IsLoggedIn";
  loggedIn?: Maybe<Scalars["Boolean"]["output"]>;
};

/** The core Policy type. */
export type KeystonePolicyGraphQlView = {
  __typename?: "KeystonePolicyGraphQLView";
  inspection?: Maybe<Inspection>;
  pendingCancellationDate?: Maybe<Scalars["String"]["output"]>;
};

export type LatestDocuments = {
  __typename?: "LatestDocuments";
  declaration?: Maybe<Document>;
  invoice?: Maybe<Document>;
};

export type LocationOfLoss = {
  __typename?: "LocationOfLoss";
  Address?: Maybe<Scalars["String"]["output"]>;
  AddressLine2?: Maybe<Scalars["String"]["output"]>;
  City?: Maybe<Scalars["String"]["output"]>;
  State?: Maybe<Scalars["String"]["output"]>;
  Zip?: Maybe<Scalars["String"]["output"]>;
};

export type LocationOfLossInput = {
  Address?: InputMaybe<Scalars["String"]["input"]>;
  AddressLine2?: InputMaybe<Scalars["String"]["input"]>;
  City?: InputMaybe<Scalars["String"]["input"]>;
  State?: InputMaybe<Scalars["String"]["input"]>;
  Zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoginResponse = {
  __typename?: "LoginResponse";
  data?: Maybe<UserInfo>;
  errorCode?: Maybe<Scalars["Int"]["output"]>;
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  redirect?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type MailingAddress = {
  city: Scalars["String"]["input"];
  mailingAddressUpdateDetails?: InputMaybe<Scalars["String"]["input"]>;
  mailingAddressUpdateReason?: InputMaybe<MailingAddressUpdateReason>;
  state?: InputMaybe<UsState>;
  streetLine1: Scalars["String"]["input"];
  streetLine2?: InputMaybe<Scalars["String"]["input"]>;
  zipFirstFive: Scalars["String"]["input"];
  zipLastFour?: InputMaybe<Scalars["String"]["input"]>;
};

export type MailingAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street1?: InputMaybe<Scalars["String"]["input"]>;
  street2?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type MailingAddressUpdateReason =
  | "FIXING_TYPO"
  | "MOVED_INTEND_TO_SELL"
  | "MOVED_PERMANENTLY"
  | "MOVED_RENTING_TO_TENANTS"
  | "SEND_DOCUMENTS_ELSEWHERE"
  | "TEMPORARY_RESIDENCE"
  | "UPCOMING_MOVE";

export type Milestone = {
  __typename?: "Milestone";
  CompletedName?: Maybe<Scalars["String"]["output"]>;
  Message?: Maybe<Scalars["String"]["output"]>;
  Name?: Maybe<Scalars["String"]["output"]>;
  ShowMilestone?: Maybe<Scalars["Boolean"]["output"]>;
  ShowSla?: Maybe<Scalars["Boolean"]["output"]>;
  Sla?: Maybe<Scalars["String"]["output"]>;
};

export type Mortgagee = {
  __typename?: "Mortgagee";
  city?: Maybe<Scalars["String"]["output"]>;
  index?: Maybe<Scalars["Int"]["output"]>;
  loanNumber?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  street1?: Maybe<Scalars["String"]["output"]>;
  street2?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type MortgageeInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  loanNumber?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street1?: InputMaybe<Scalars["String"]["input"]>;
  street2?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  addPolicy: Response;
  addRoofShingleResealIntake?: Maybe<Scalars["Int"]["output"]>;
  authenticateUser: LoginResponse;
  /**
   * policyId: Either policyNumber or insightPolicyId
   * coverageName:
   * protectionValue:
   * message:
   */
  contactPolicyRepresentative: RepresentativeNotication;
  convertEnvelopeIdToSigningLink?: Maybe<Scalars["String"]["output"]>;
  dataBridge: Scalars["String"]["output"];
  decodeData: DecodeData;
  easypayEnroll: EasypayEnrollResponse;
  fnolChannelSubmission: Scalars["JSON"]["output"];
  generatePoaWithSigningLink?: Maybe<Scalars["String"]["output"]>;
  loginUser: LoginResponse;
  loginUserGoogle: LoginResponse;
  logoutUser?: Maybe<Scalars["String"]["output"]>;
  paperlessBillingEnroll: PaperlessEmails;
  paperlessBillingUnenroll: Scalars["Boolean"]["output"];
  paperlessDocumentsEnroll: Scalars["Boolean"]["output"];
  paperlessDocumentsEnrollFromEmail: Response;
  paperlessDocumentsUnenroll: Scalars["Boolean"]["output"];
  paperlessEnroll: PaperlessEmails;
  paperlessUnenroll: Scalars["Boolean"]["output"];
  /**
   * policyId: Either policyNumber or insightPolicyId
   * coverageName:
   * protectionValue:
   */
  purchaseCoverage: CoverageUpsell;
  registerUser: Response;
  registerUserGoogle: Response;
  requestReset: Response;
  resetPassword: Response;
  resetPolicy?: Maybe<Scalars["Boolean"]["output"]>;
  reverify: Response;
  /**
   * policyId: Self-explanatory
   * emailAddress: The email address associated with the logged in user
   * username: The user's login name if they do not use an email address. Only use if no email.
   * endorsementUpdates: The fields to be set for update notification. Currently only contains fireAlarm and burglarAlarm.
   */
  sendPropertyProfileUpdateNotification?: Maybe<Scalars["Boolean"]["output"]>;
  submitClaim: Scalars["String"]["output"];
  unlinkPolicy: Response;
  updateData: Scalars["Boolean"]["output"];
  updateEmail: Scalars["String"]["output"];
  /**
   * policyId: Self-explanatory
   * emailAddress: The user's email address for logging in, if it exists. Prioritized over username.
   * username: The user's login name if they do not use an email address. Only use if no email.
   * userUpdates: The fields to be set for update.  Currently only contains phoneNumber.
   */
  updateInsuredInfo?: Maybe<Scalars["Boolean"]["output"]>;
  updateMailingAddress: Address;
  updateMortgagee: Mortgagee;
  updatePaperlessBillingEmail: Scalars["Boolean"]["output"];
  updatePaperlessDocumentsEmail: Scalars["Boolean"]["output"];
  updatePaperlessEmail: Scalars["Boolean"]["output"];
  /**
   * policyId: Self-explanatory
   * emailAddress: The email address associated with the logged in user
   * username: The user's login name if they do not use an email address. Only use if no email.
   * endorsementUpdates: The fields to be set for update. Currently only contains fireAlarm and burglarAlarm.
   */
  updatePolicyIntervalData?: Maybe<Scalars["Boolean"]["output"]>;
  verifyUserOwnership?: Maybe<Response>;
};

export type MutationAddPolicyArgs = {
  email: Scalars["String"]["input"];
  policyId: Scalars["String"]["input"];
  zipCode: Scalars["String"]["input"];
};

export type MutationAddRoofShingleResealIntakeArgs = {
  roofShingleResealIntake: RoofShingleResealIntakeInput;
};

export type MutationAuthenticateUserArgs = {
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type MutationContactPolicyRepresentativeArgs = {
  coverageName: CoverageName;
  message?: InputMaybe<Scalars["String"]["input"]>;
  policyId: Scalars["String"]["input"];
  protectionValue: Scalars["Int"]["input"];
};

export type MutationConvertEnvelopeIdToSigningLinkArgs = {
  envelopeId: Scalars["String"]["input"];
};

export type MutationDataBridgeArgs = {
  bridgeData: BridgeDataInput;
};

export type MutationDecodeDataArgs = {
  data: Scalars["String"]["input"];
};

export type MutationEasypayEnrollArgs = {
  paymentData: PaymentData;
};

export type MutationFnolChannelSubmissionArgs = {
  event: Scalars["JSON"]["input"];
};

export type MutationGeneratePoaWithSigningLinkArgs = {
  emailAddress: Scalars["String"]["input"];
  policyId: Scalars["String"]["input"];
};

export type MutationLoginUserArgs = {
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type MutationLoginUserGoogleArgs = {
  googleJWT: Scalars["String"]["input"];
};

export type MutationPaperlessBillingEnrollArgs = {
  policyID: Scalars["String"]["input"];
};

export type MutationPaperlessBillingUnenrollArgs = {
  policyID: Scalars["String"]["input"];
};

export type MutationPaperlessDocumentsEnrollArgs = {
  policyID: Scalars["String"]["input"];
};

export type MutationPaperlessDocumentsEnrollFromEmailArgs = {
  data: Scalars["String"]["input"];
};

export type MutationPaperlessDocumentsUnenrollArgs = {
  policyID: Scalars["String"]["input"];
};

export type MutationPaperlessEnrollArgs = {
  policyID: Scalars["String"]["input"];
};

export type MutationPaperlessUnenrollArgs = {
  policyID: Scalars["String"]["input"];
};

export type MutationPurchaseCoverageArgs = {
  coverageName: CoverageName;
  policyId: Scalars["String"]["input"];
  protectionValue: Scalars["Int"]["input"];
};

export type MutationRegisterUserArgs = {
  userInfo: UserRegistrationInfoInput;
};

export type MutationRegisterUserGoogleArgs = {
  userInfo: UserRegistrationGoogleInfoInput;
};

export type MutationRequestResetArgs = {
  email: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  password: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationResetPolicyArgs = {
  policyID: Scalars["String"]["input"];
};

export type MutationReverifyArgs = {
  email: Scalars["String"]["input"];
};

export type MutationSendPropertyProfileUpdateNotificationArgs = {
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  endorsementUpdates: EndorsementUpdates;
  policyId: Scalars["String"]["input"];
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSubmitClaimArgs = {
  claimSubmission: SubmissionInput;
  policyID: Scalars["String"]["input"];
};

export type MutationUnlinkPolicyArgs = {
  email: Scalars["String"]["input"];
  policyId: Scalars["String"]["input"];
};

export type MutationUpdateDataArgs = {
  policyID: Scalars["String"]["input"];
};

export type MutationUpdateEmailArgs = {
  email: Scalars["String"]["input"];
  policyId: Scalars["String"]["input"];
};

export type MutationUpdateInsuredInfoArgs = {
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  policyId: Scalars["String"]["input"];
  userUpdates: InsuredUpdateables;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateMailingAddressArgs = {
  mailingAddress: MailingAddressInput;
  policyId: Scalars["String"]["input"];
};

export type MutationUpdateMortgageeArgs = {
  mortgagee: MortgageeInput;
  mortgageeIndex: Scalars["Int"]["input"];
  policyId: Scalars["String"]["input"];
  readablePolicyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdatePaperlessBillingEmailArgs = {
  opInsuredEmailAddressBilling: Scalars["String"]["input"];
  policyID: Scalars["String"]["input"];
};

export type MutationUpdatePaperlessDocumentsEmailArgs = {
  opInsuredEmailAddressDocuments: Scalars["String"]["input"];
  policyID: Scalars["String"]["input"];
};

export type MutationUpdatePaperlessEmailArgs = {
  opInsuredEmailAddressBilling: Scalars["String"]["input"];
  policyID: Scalars["String"]["input"];
};

export type MutationUpdatePolicyIntervalDataArgs = {
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  endorsementUpdates: EndorsementUpdates;
  policyId: Scalars["String"]["input"];
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationVerifyUserOwnershipArgs = {
  verificationInfo: UserVerificationInfoInput;
};

export type NewBusinessInspectionMessage = {
  __typename?: "NewBusinessInspectionMessage";
  dueDate?: Maybe<Scalars["Date"]["output"]>;
  images?: Maybe<Array<Maybe<Image>>>;
  projectId?: Maybe<Scalars["String"]["output"]>;
  referralList?: Maybe<Array<Maybe<Referral>>>;
  statusEnum?: Maybe<Scalars["Int"]["output"]>;
  vendorName?: Maybe<Scalars["String"]["output"]>;
};

export type OptionalCoverages = {
  __typename?: "OptionalCoverages";
  coverages?: Maybe<Array<Maybe<Coverage>>>;
  policyRenewal?: Maybe<PolicyRenewal>;
  premiumCurrent?: Maybe<Scalars["Currency"]["output"]>;
};

export type Origin = "link" | "register";

export type PaperlessEmails = {
  __typename?: "PaperlessEmails";
  opInsuredEmailAddress?: Maybe<Scalars["String"]["output"]>;
  opInsuredEmailAddressBilling?: Maybe<Scalars["String"]["output"]>;
  opInsuredEmailAddressDocuments?: Maybe<Scalars["String"]["output"]>;
};

export type Payment = {
  __typename?: "Payment";
  paymentAmount?: Maybe<Scalars["String"]["output"]>;
  paymentDate?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentData = {
  ach?: InputMaybe<AchData>;
  cc?: InputMaybe<CcData>;
  draftDate?: InputMaybe<Scalars["String"]["input"]>;
  easypayMethod: Scalars["String"]["input"];
  enrollDocuments?: InputMaybe<Scalars["String"]["input"]>;
  paymentFrequency: Scalars["String"]["input"];
  paymentMethod: Scalars["String"]["input"];
  policyNumber: Scalars["String"]["input"];
};

export type PaymentPlan = {
  __typename?: "PaymentPlan";
  planType?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentPlanDetails = {
  __typename?: "PaymentPlanDetails";
  billingMethod?: Maybe<Scalars["String"]["output"]>;
  downPayment?: Maybe<Scalars["String"]["output"]>;
  installmentAmount?: Maybe<Scalars["String"]["output"]>;
  installmentCharge?: Maybe<Scalars["String"]["output"]>;
  numberOfPayments?: Maybe<Scalars["String"]["output"]>;
  paymentPlanSetupFee?: Maybe<Scalars["String"]["output"]>;
  planId?: Maybe<Scalars["String"]["output"]>;
};

export type PendingChanges = {
  __typename?: "PendingChanges";
  ScheduledPayments?: Maybe<ScheduledPayments>;
};

export type PolicyHolderDetails = {
  __typename?: "PolicyHolderDetails";
  emailAddress: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
};

export type PolicyInfo = {
  __typename?: "PolicyInfo";
  insightPolicyId: Scalars["String"]["output"];
  isCommercial?: Maybe<Scalars["Boolean"]["output"]>;
  policyId: Scalars["String"]["output"];
  policyState?: Maybe<Scalars["String"]["output"]>;
  propertyAddress?: Maybe<PropertyAddress>;
};

export type PolicyProperties = {
  __typename?: "PolicyProperties";
  agencyLocationCode?: Maybe<Scalars["String"]["output"]>;
  carrierGroup?: Maybe<Scalars["String"]["output"]>;
  policyState?: Maybe<Scalars["String"]["output"]>;
  policyType?: Maybe<Scalars["String"]["output"]>;
};

export type PolicyRenewal = {
  __typename?: "PolicyRenewal";
  endDate?: Maybe<Scalars["Date"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
};

export type PolicyStateAttributes = {
  __typename?: "PolicyStateAttributes";
  effectiveDate?: Maybe<Scalars["String"]["output"]>;
  reasonCode?: Maybe<PolicyStateReasonCode>;
};

export type PolicyStateReasonCode = {
  __typename?: "PolicyStateReasonCode";
  description?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type PoolType = "ABOVEGROUNDPOOL" | "INGROUNDPOOL" | "NONE" | "UNKNOWN";

export type PrimaryInsured = {
  __typename?: "PrimaryInsured";
  emailAddress?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyProfileData = {
  __typename?: "PropertyProfileData";
  burglarAlarm?: Maybe<Scalars["String"]["output"]>;
  constructionYearRoof?: Maybe<Scalars["Int"]["output"]>;
  divingBoardOrSlide?: Maybe<Scalars["Boolean"]["output"]>;
  fireAlarm?: Maybe<Scalars["String"]["output"]>;
  immovablePoolLadder?: Maybe<Scalars["Boolean"]["output"]>;
  poolCovering?: Maybe<Scalars["Boolean"]["output"]>;
  poolFence?: Maybe<Scalars["String"]["output"]>;
  poolFenceAboveGround?: Maybe<Scalars["Boolean"]["output"]>;
  poolType?: Maybe<Scalars["String"]["output"]>;
  propertyUpdateData?: Maybe<Array<Maybe<PropertyUpdateData>>>;
  roofCoveringType?: Maybe<Scalars["String"]["output"]>;
  trampoline?: Maybe<Scalars["Boolean"]["output"]>;
  unlockedPoolGate?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PropertyUpdateData = {
  __typename?: "PropertyUpdateData";
  detailName?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<Array<Maybe<PropertyUpdateEntry>>>;
  taskStatus?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyUpdateEntry = {
  __typename?: "PropertyUpdateEntry";
  enumerationValue?: Maybe<Scalars["String"]["output"]>;
  termName?: Maybe<Scalars["String"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  docusignStatus?: Maybe<Array<Maybe<DocusignStatus>>>;
  easypayProcessingFees: Scalars["String"]["output"];
  /** policyId: Either policyNumber or insightPolicyId */
  getCoveragePrices: OptionalCoverages;
  /** policyId: Either policyNumber or insightPolicyId */
  getEligibleCoverages: OptionalCoverages;
  getPoaSignatureStatus?: Maybe<Array<Maybe<DocusignStatus>>>;
  getPolicy?: Maybe<KeystonePolicyGraphQlView>;
  getRoofShingleIntakeByInsightPolicyId?: Maybe<RoofShingleResealIntake>;
  isLoggedIn?: Maybe<IsLoggedIn>;
  paperlessDocumentsEnrollmentStatus: Scalars["String"]["output"];
  paperlessEnrollmentStatus: Scalars["String"]["output"];
  policyPaymentPlans: Array<PaymentPlanDetails>;
  /**
   * policyId: Either policyNumber or insightPolicyId
   * coverageName:
   * protectionValue:
   */
  previewCoverage: OptionalCoverages;
  serviceFees: Scalars["String"]["output"];
  userAdditionalCoverages?: Maybe<AdditionalCoverages>;
  userBasicInfo?: Maybe<UserInfo>;
  userBilling?: Maybe<Billing>;
  userBillingAddress?: Maybe<Scalars["String"]["output"]>;
  userCarrierInfo?: Maybe<CarrierInfo>;
  userClaimsByPolicy?: Maybe<Array<Claim>>;
  userClaimsConstants?: Maybe<ClaimConstants>;
  userClaimsList?: Maybe<ClaimList>;
  userDetails?: Maybe<Details>;
  userDocuments?: Maybe<Documents>;
  userDocumentsEmailAddress?: Maybe<Scalars["String"]["output"]>;
  userIdentification?: Maybe<UserIdentification>;
  userInfo?: Maybe<UserInfo>;
  userInsuranceRep?: Maybe<InsuranceRep>;
  userPolicies?: Maybe<Array<PolicyInfo>>;
  userPolicyID?: Maybe<Scalars["String"]["output"]>;
  userPolicyIDs?: Maybe<Array<PolicyInfo>>;
  userPolicyProperties?: Maybe<PolicyProperties>;
  userPropertyProfileData?: Maybe<PropertyProfileData>;
  userTransactions?: Maybe<Transactions>;
  verifyRoofShingleResealIntakeEligibility?: Maybe<RoofShingleResealIntakeEligibility>;
};

export type QueryDocusignStatusArgs = {
  insightPolicyId: Scalars["String"]["input"];
};

export type QueryEasypayProcessingFeesArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryGetCoveragePricesArgs = {
  policyId: Scalars["String"]["input"];
};

export type QueryGetEligibleCoveragesArgs = {
  policyId: Scalars["String"]["input"];
};

export type QueryGetPoaSignatureStatusArgs = {
  insightPolicyId: Scalars["String"]["input"];
};

export type QueryGetPolicyArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetRoofShingleIntakeByInsightPolicyIdArgs = {
  insightPolicyId: Scalars["String"]["input"];
};

export type QueryPaperlessDocumentsEnrollmentStatusArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryPaperlessEnrollmentStatusArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryPolicyPaymentPlansArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryPreviewCoverageArgs = {
  coverageName: CoverageName;
  policyId: Scalars["String"]["input"];
  protectionValue: Scalars["Int"]["input"];
};

export type QueryServiceFeesArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserAdditionalCoveragesArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserBasicInfoArgs = {
  email: Scalars["String"]["input"];
};

export type QueryUserBillingArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserBillingAddressArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserCarrierInfoArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserClaimsByPolicyArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserClaimsConstantsArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserClaimsListArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserDetailsArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserDocumentsArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserDocumentsEmailAddressArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserIdentificationArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserInfoArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserInsuranceRepArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserPoliciesArgs = {
  email: Scalars["String"]["input"];
};

export type QueryUserPolicyPropertiesArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserPropertyProfileDataArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryUserTransactionsArgs = {
  policyID: Scalars["ID"]["input"];
};

export type QueryVerifyRoofShingleResealIntakeEligibilityArgs = {
  policyId: Scalars["String"]["input"];
  postal: Scalars["String"]["input"];
};

export type RecipientType =
  | "AGENT"
  | "CARBONCOPY"
  | "CERTIFIEDDELIVERY"
  | "EDITOR"
  | "INPERSONSIGNER"
  | "INTERMEDIARY"
  | "SEAL"
  | "SIGNER"
  | "WITNESS";

export type Referral = {
  __typename?: "Referral";
  location?: Maybe<Scalars["String"]["output"]>;
  referralActionRequired?: Maybe<Scalars["String"]["output"]>;
  referralCategory?: Maybe<ReferralCategory>;
  resolved?: Maybe<Scalars["Boolean"]["output"]>;
  underwritingAction?: Maybe<UnderwritingAction>;
  underwritingCondition?: Maybe<UnderwritingCondition>;
};

export type ReferralCategory =
  | "ANIMALS"
  | "ATTRACTIVE_NUISANCE"
  | "EXTERIOR"
  | "LIABILITY"
  | "LOSS"
  | "OCCUPANCY"
  | "OTHER"
  | "PENDING_DOCS"
  | "PROPERTY"
  | "RATING_VARIABLE_CHANGE"
  | "ROOF"
  | "RVC_OR_ITV"
  | "STRUCTURE"
  | "TREES"
  | "UNDISCLOSED_FEATURES";

export type RepresentativeNotication = {
  __typename?: "RepresentativeNotication";
  agentName?: Maybe<Scalars["String"]["output"]>;
  agentPhone?: Maybe<Scalars["String"]["output"]>;
  coverage?: Maybe<Coverage>;
};

export type Response = {
  __typename?: "Response";
  errorCode?: Maybe<Scalars["Int"]["output"]>;
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  redirect?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RoofCoveringType =
  | "ARCHITECTURALSHINGLES"
  | "ASPHALTCOMPOSITE"
  | "BUILTUP"
  | "CONCRETECLAY"
  | "METALOTHER"
  | "METALSTEEL"
  | "REINFORCEDCONCRETE"
  | "RUBBER"
  | "SINGLEPLY"
  | "TLOCKINTERLOCKINGSHINGLES"
  | "UNKNOWN"
  | "WOODSHAKE"
  | "WOODSHINGLE";

export type RoofShingleResealIntake = {
  __typename?: "RoofShingleResealIntake";
  city?: Maybe<Scalars["String"]["output"]>;
  createdTimestamp?: Maybe<Scalars["Date"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  insightPolicyId?: Maybe<Scalars["String"]["output"]>;
  ipAddress?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postal?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  street1?: Maybe<Scalars["String"]["output"]>;
  street2?: Maybe<Scalars["String"]["output"]>;
};

/**
 * extend type Mutation {
 *  dataBridge(bridgeData: BridgeDataInput!): String!
 * }
 */
export type RoofShingleResealIntakeEligibility = {
  __typename?: "RoofShingleResealIntakeEligibility";
  address?: Maybe<Address>;
  eligible?: Maybe<Scalars["Boolean"]["output"]>;
  insightPolicyId?: Maybe<Scalars["String"]["output"]>;
};

export type RoofShingleResealIntakeInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  createdTimestamp?: InputMaybe<Scalars["Date"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  insightPolicyId?: InputMaybe<Scalars["String"]["input"]>;
  ipAddress?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  postal?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street1?: InputMaybe<Scalars["String"]["input"]>;
  street2?: InputMaybe<Scalars["String"]["input"]>;
};

export type ScheduledPayment = {
  __typename?: "ScheduledPayment";
  paymentAmount?: Maybe<Scalars["Float"]["output"]>;
  paymentDate?: Maybe<Scalars["String"]["output"]>;
  totalScheduledAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type ScheduledPayments = {
  __typename?: "ScheduledPayments";
  scheduledPaymentList?: Maybe<Array<ScheduledPayment>>;
};

export type Status = "Closed" | "Open" | "ReOpen";

export type Submission = {
  __typename?: "Submission";
  ClaimSubmittedBy?: Maybe<ClaimSubmittedBy>;
  ClaimSubmittedByPolicyholder?: Maybe<Scalars["Boolean"]["output"]>;
  ContactInfo?: Maybe<ContactInfo>;
  DateOfLoss?: Maybe<Scalars["String"]["output"]>;
  DateReceived?: Maybe<Scalars["String"]["output"]>;
  LocationOfLoss?: Maybe<LocationOfLoss>;
  LossAtInsuredProperty?: Maybe<Scalars["Boolean"]["output"]>;
  LossDescription?: Maybe<Scalars["String"]["output"]>;
  SubmissionNumber: Scalars["String"]["output"];
  TypeOfLoss?: Maybe<Scalars["String"]["output"]>;
};

export type SubmissionInput = {
  ClaimSubmittedBy?: InputMaybe<ClaimSubmittedByInput>;
  ClaimSubmittedByPolicyholder?: InputMaybe<Scalars["Boolean"]["input"]>;
  ContactInfo?: InputMaybe<ContactInfoInput>;
  DateOfLoss?: InputMaybe<Scalars["String"]["input"]>;
  LocationOfLoss?: InputMaybe<LocationOfLossInput>;
  LossAtInsuredProperty?: InputMaybe<Scalars["Boolean"]["input"]>;
  LossDescription?: InputMaybe<Scalars["String"]["input"]>;
  Source?: InputMaybe<Scalars["String"]["input"]>;
  TimeOfLoss?: InputMaybe<Scalars["String"]["input"]>;
  TypeOfLoss?: InputMaybe<Scalars["String"]["input"]>;
};

export type TermLocationData = {
  __typename?: "TermLocationData";
  allOtherPerilsDeductible?: Maybe<Scalars["String"]["output"]>;
  allOtherPerilsDeductibleDollarAmount?: Maybe<Scalars["String"]["output"]>;
  coverageA?: Maybe<Scalars["String"]["output"]>;
  coverageC?: Maybe<Scalars["String"]["output"]>;
  deductibleAmount?: Maybe<Scalars["String"]["output"]>;
  hurricaneDeductible?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Scalars["Int"]["output"]>;
  windHailDeductible?: Maybe<Scalars["String"]["output"]>;
};

export type Transaction = {
  __typename?: "Transaction";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["String"]["output"]>;
  creditCardType?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  method?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Transactions = {
  __typename?: "Transactions";
  lastPaymentDate?: Maybe<Scalars["String"]["output"]>;
  latestPayment?: Maybe<Scalars["String"]["output"]>;
  transactions?: Maybe<Array<Transaction>>;
};

/** An enum for all 50 US state two letter abbreviations */
export type UsState =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";

export type UnderwritingAction =
  | "CONDITION_ACCEPTABLE"
  | "ENDORSEMENT"
  | "NO_ACTION_TAKEN"
  | "SURCHARGE"
  | "UNKNOWN"
  | "WRITE_OUT";

export type UnderwritingCondition = {
  __typename?: "UnderwritingCondition";
  actionStatement?: Maybe<Scalars["String"]["output"]>;
  category?: Maybe<ReferralCategory>;
  contractor?: Maybe<Scalars["String"]["output"]>;
  issueStatements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  proof?: Maybe<Scalars["String"]["output"]>;
  reasoning?: Maybe<Scalars["String"]["output"]>;
  referralCode?: Maybe<Scalars["String"]["output"]>;
  referralText?: Maybe<Scalars["String"]["output"]>;
  searchTerm?: Maybe<Scalars["String"]["output"]>;
  serviceProvider?: Maybe<Scalars["Int"]["output"]>;
};

export type UserIdentification = {
  __typename?: "UserIdentification";
  city?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  policyNum?: Maybe<Scalars["String"]["output"]>;
  policyStatus?: Maybe<Scalars["String"]["output"]>;
  productType?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type UserInfo = {
  __typename?: "UserInfo";
  authProvider?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
  userName?: Maybe<Scalars["String"]["output"]>;
};

export type UserRegistrationGoogleInfoInput = {
  googleJWT: Scalars["String"]["input"];
  policyList: Scalars["String"]["input"];
  zipCode: Scalars["String"]["input"];
};

export type UserRegistrationInfoInput = {
  email: Scalars["String"]["input"];
  emailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  policyList: Scalars["String"]["input"];
  zipCode: Scalars["String"]["input"];
};

export type UserVerificationInfoInput = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  policyList: Scalars["String"]["input"];
  zipCode: Scalars["String"]["input"];
};

export type PropertyAddress = {
  __typename?: "propertyAddress";
  city?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  street1?: Maybe<Scalars["String"]["output"]>;
  street2?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
  zipPlus4?: Maybe<Scalars["String"]["output"]>;
};

export type AddPolicyMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  policyId: Scalars["String"]["input"];
  zipCode: Scalars["String"]["input"];
}>;

export type AddPolicyMutation = {
  __typename?: "Mutation";
  addPolicy: {
    __typename?: "Response";
    success?: boolean | null;
    errors?: Array<string> | null;
    errorCode?: number | null;
  };
};

export type AddRoofShingleResealIntakeMutationVariables = Exact<{
  roofShingleResealIntake: RoofShingleResealIntakeInput;
}>;

export type AddRoofShingleResealIntakeMutation = {
  __typename?: "Mutation";
  addRoofShingleResealIntake?: number | null;
};

export type AuthenticateUserMutationVariables = Exact<{
  username: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type AuthenticateUserMutation = {
  __typename?: "Mutation";
  authenticateUser: {
    __typename?: "LoginResponse";
    errors?: Array<string> | null;
    errorCode?: number | null;
    success?: boolean | null;
  };
};

export type ContactPolicyRepresentativeMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
  coverageName: CoverageName;
  protectionValue: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ContactPolicyRepresentativeMutation = {
  __typename?: "Mutation";
  contactPolicyRepresentative: {
    __typename?: "RepresentativeNotication";
    agentName?: string | null;
    agentPhone?: string | null;
    coverage?: { __typename?: "Coverage"; name: CoverageName } | null;
  };
};

export type ConvertEnvelopeIdToSigningLinkMutationVariables = Exact<{
  envelopeId: Scalars["String"]["input"];
}>;

export type ConvertEnvelopeIdToSigningLinkMutation = {
  __typename?: "Mutation";
  convertEnvelopeIdToSigningLink?: string | null;
};

export type DecodeDataMutationVariables = Exact<{
  data: Scalars["String"]["input"];
}>;

export type DecodeDataMutation = {
  __typename?: "Mutation";
  decodeData: {
    __typename?: "DecodeData";
    success?: boolean | null;
    policyId?: string | null;
    zipcode?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type EasypayEnrollMutationVariables = Exact<{
  paymentData: PaymentData;
}>;

export type EasypayEnrollMutation = {
  __typename?: "Mutation";
  easypayEnroll: {
    __typename?: "EasypayEnrollResponse";
    success?: boolean | null;
    errors?: Array<string> | null;
    data?: {
      __typename?: "InsuredPreferences";
      easyPayEnrollmentStatus?: string | null;
    } | null;
  };
};

export type GeneratePoaLinkMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
  emailAddress: Scalars["String"]["input"];
}>;

export type GeneratePoaLinkMutation = {
  __typename?: "Mutation";
  generatePoaWithSigningLink?: string | null;
};

export type LoginUserMutationVariables = Exact<{
  username: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type LoginUserMutation = {
  __typename?: "Mutation";
  loginUser: {
    __typename?: "LoginResponse";
    errors?: Array<string> | null;
    errorCode?: number | null;
    success?: boolean | null;
    redirect?: string | null;
    data?: {
      __typename?: "UserInfo";
      userId?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

export type LoginUserGoogleMutationVariables = Exact<{
  googleJWT: Scalars["String"]["input"];
}>;

export type LoginUserGoogleMutation = {
  __typename?: "Mutation";
  loginUserGoogle: {
    __typename?: "LoginResponse";
    errors?: Array<string> | null;
    errorCode?: number | null;
    success?: boolean | null;
    redirect?: string | null;
    data?: {
      __typename?: "UserInfo";
      userId?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      authProvider?: string | null;
    } | null;
  };
};

export type LogoutUserMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutUserMutation = {
  __typename?: "Mutation";
  logoutUser?: string | null;
};

export type PaperlessBillingEnrollMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
}>;

export type PaperlessBillingEnrollMutation = {
  __typename?: "Mutation";
  paperlessBillingEnroll: {
    __typename?: "PaperlessEmails";
    opInsuredEmailAddress?: string | null;
    opInsuredEmailAddressBilling?: string | null;
  };
};

export type PaperlessDocumentsEnrollMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
}>;

export type PaperlessDocumentsEnrollMutation = {
  __typename?: "Mutation";
  paperlessDocumentsEnroll: boolean;
};

export type PaperlessDocumentsEnrollFromEmailMutationVariables = Exact<{
  data: Scalars["String"]["input"];
}>;

export type PaperlessDocumentsEnrollFromEmailMutation = {
  __typename?: "Mutation";
  paperlessDocumentsEnrollFromEmail: {
    __typename?: "Response";
    success?: boolean | null;
    errorCode?: number | null;
    errors?: Array<string> | null;
  };
};

export type PurchaseCoverageMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
  coverageName: CoverageName;
  protectionValue: Scalars["Int"]["input"];
}>;

export type PurchaseCoverageMutation = {
  __typename?: "Mutation";
  purchaseCoverage: {
    __typename?: "CoverageUpsell";
    premiumPrevious?: any | null;
    coverage?: {
      __typename?: "Coverage";
      name: CoverageName;
      proratedPrice?: any | null;
      premiumNew?: any | null;
      startDate?: any | null;
      price?: any | null;
    } | null;
    policyRenewal?: {
      __typename?: "PolicyRenewal";
      startDate?: any | null;
      endDate?: any | null;
    } | null;
  };
};

export type RegisterUserMutationVariables = Exact<{
  userInfo: UserRegistrationInfoInput;
}>;

export type RegisterUserMutation = {
  __typename?: "Mutation";
  registerUser: {
    __typename?: "Response";
    success?: boolean | null;
    errors?: Array<string> | null;
    errorCode?: number | null;
  };
};

export type RegisterUserGoogleMutationVariables = Exact<{
  userInfo: UserRegistrationGoogleInfoInput;
}>;

export type RegisterUserGoogleMutation = {
  __typename?: "Mutation";
  registerUserGoogle: {
    __typename?: "Response";
    success?: boolean | null;
    errors?: Array<string> | null;
    errorCode?: number | null;
  };
};

export type RequestResetMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type RequestResetMutation = {
  __typename?: "Mutation";
  requestReset: {
    __typename?: "Response";
    errors?: Array<string> | null;
    success?: boolean | null;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: {
    __typename?: "Response";
    errors?: Array<string> | null;
    success?: boolean | null;
  };
};

export type ResetPolicyMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
}>;

export type ResetPolicyMutation = {
  __typename?: "Mutation";
  resetPolicy?: boolean | null;
};

export type ReverifyMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ReverifyMutation = {
  __typename?: "Mutation";
  reverify: {
    __typename?: "Response";
    errors?: Array<string> | null;
    success?: boolean | null;
  };
};

export type SendPropertyProfileUpdateNotificationMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  endorsementUpdates: EndorsementUpdates;
}>;

export type SendPropertyProfileUpdateNotificationMutation = {
  __typename?: "Mutation";
  sendPropertyProfileUpdateNotification?: boolean | null;
};

export type SubmitFnolMutationVariables = Exact<{
  event: Scalars["JSON"]["input"];
}>;

export type SubmitFnolMutation = {
  __typename?: "Mutation";
  fnolChannelSubmission: any;
};

export type UnlinkPolicyMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  policyId: Scalars["String"]["input"];
}>;

export type UnlinkPolicyMutation = {
  __typename?: "Mutation";
  unlinkPolicy: {
    __typename?: "Response";
    success?: boolean | null;
    errors?: Array<string> | null;
    errorCode?: number | null;
  };
};

export type UpdateDataMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
}>;

export type UpdateDataMutation = {
  __typename?: "Mutation";
  updateData: boolean;
};

export type UpdateInsuredInfoMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  userUpdates: InsuredUpdateables;
}>;

export type UpdateInsuredInfoMutation = {
  __typename?: "Mutation";
  updateInsuredInfo?: boolean | null;
};

export type UpdateMortgageeMutationVariables = Exact<{
  mortgagee: MortgageeInput;
  policyId: Scalars["String"]["input"];
  mortgageeIndex: Scalars["Int"]["input"];
  readablePolicyId: Scalars["String"]["input"];
}>;

export type UpdateMortgageeMutation = {
  __typename?: "Mutation";
  updateMortgagee: {
    __typename?: "Mortgagee";
    name?: string | null;
    loanNumber?: string | null;
    street1?: string | null;
    street2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    index?: number | null;
  };
};

export type UpdatePaperlessDocumentsEmailMutationVariables = Exact<{
  opInsuredEmailAddressDocuments: Scalars["String"]["input"];
  policyID: Scalars["String"]["input"];
}>;

export type UpdatePaperlessDocumentsEmailMutation = {
  __typename?: "Mutation";
  updatePaperlessDocumentsEmail: boolean;
};

export type UpdatePaperlessEmailMutationVariables = Exact<{
  opInsuredEmailAddressBilling: Scalars["String"]["input"];
  policyID: Scalars["String"]["input"];
}>;

export type UpdatePaperlessEmailMutation = {
  __typename?: "Mutation";
  updatePaperlessEmail: boolean;
};

export type UpdatePolicyIntervalDataMutationVariables = Exact<{
  policyID: Scalars["String"]["input"];
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  endorsementUpdates: EndorsementUpdates;
}>;

export type UpdatePolicyIntervalDataMutation = {
  __typename?: "Mutation";
  updatePolicyIntervalData?: boolean | null;
};

export type VerifyUserOwnershipMutationVariables = Exact<{
  verificationInfo: UserVerificationInfoInput;
}>;

export type VerifyUserOwnershipMutation = {
  __typename?: "Mutation";
  verifyUserOwnership?: {
    __typename?: "Response";
    success?: boolean | null;
    errors?: Array<string> | null;
    errorCode?: number | null;
  } | null;
};

export type CommonPolicyDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
  email: Scalars["String"]["input"];
}>;

export type CommonPolicyDataQuery = {
  __typename?: "Query";
  userBilling?: {
    __typename?: "Billing";
    accounting?: {
      __typename?: "Accounting";
      dueDate?: string | null;
      minimumPaymentDue?: string | null;
      invoiceAmountCurrent?: string | null;
      outstandingBalanceDue?: string | null;
      paymentPlan?: {
        __typename?: "PaymentPlan";
        planType?: string | null;
      } | null;
      easyPayAccount?: {
        __typename?: "EasyPayDetails";
        lastFourDigits?: string | null;
        paymentMethod?: string | null;
      } | null;
    } | null;
    mortgagees?: Array<{
      __typename?: "Mortgagee";
      name?: string | null;
      loanNumber?: string | null;
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      index?: number | null;
    }> | null;
    billingAddress?: {
      __typename?: "BillingAddress";
      line1?: string | null;
      line2?: string | null;
      line3?: string | null;
    } | null;
    installments?: Array<{
      __typename?: "Installment";
      amount?: number | null;
      charges?: number | null;
      dueDateMillis?: any | null;
      feesAndPremiums?: number | null;
      invoiceDateMillis?: any | null;
      number?: number | null;
    }> | null;
    pendingChanges?: {
      __typename?: "PendingChanges";
      ScheduledPayments?: {
        __typename?: "ScheduledPayments";
        scheduledPaymentList?: Array<{
          __typename?: "ScheduledPayment";
          paymentDate?: string | null;
          paymentAmount?: number | null;
          totalScheduledAmount?: number | null;
        }> | null;
      } | null;
    } | null;
  } | null;
  userDetails?: {
    __typename?: "Details";
    policyStatus?: string | null;
    quoteNumber?: string | null;
    isCommercial?: boolean | null;
    isBookRoll?: boolean | null;
    keystonePolicyId?: string | null;
    mortgageeIndex?: number | null;
    isEnrolledInLeakBot?: boolean | null;
    propertyAddress?: {
      __typename?: "Address";
      firstName?: string | null;
      lastName?: string | null;
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    mailingAddress?: {
      __typename?: "Address";
      firstName?: string | null;
      lastName?: string | null;
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    additionalAddresses?: Array<{
      __typename?: "Address";
      firstName?: string | null;
      lastName?: string | null;
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    }> | null;
    currentTerm?: {
      __typename?: "CurrentTerm";
      policyNumber?: string | null;
      agencyLocationCode?: string | null;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      effectiveDatePolicyTerm?: any | null;
      agentName?: string | null;
      totalPremium?: string | null;
      coverageA?: string | null;
      coverageB?: string | null;
      coverageC?: string | null;
      coverageD?: string | null;
      coverageE?: string | null;
      coverageEPerOccurrence?: string | null;
      coverageEAggregate?: string | null;
      coverageF?: string | null;
      coverageL?: string | null;
      coverageM?: string | null;
      hurricaneDeductible?: string | null;
      hurricaneDeductibleAmount?: string | null;
      allOtherPerilsDeductible?: string | null;
      dbaName?: string | null;
      windHailDeductible?: string | null;
      propertyDamageLiabilityDeductiblePerClaim?: string | null;
      propertyDamageLiabilityDeductiblePerOccurrence?: string | null;
      termLocationData?: Array<{
        __typename?: "TermLocationData";
        location?: number | null;
        coverageA?: string | null;
        coverageC?: string | null;
        deductibleAmount?: string | null;
        hurricaneDeductible?: string | null;
        windHailDeductible?: string | null;
        allOtherPerilsDeductibleDollarAmount?: string | null;
        allOtherPerilsDeductible?: string | null;
      }> | null;
    } | null;
    insuredPreferences?: {
      __typename?: "InsuredPreferences";
      easyPayEnrollmentStatus?: string | null;
      paperlessBillingEnrollmentStatus?: string | null;
      paperlessDocumentEnrollmentStatus?: string | null;
      opInsuredEmailAddress?: string | null;
      opInsuredEmailAddressBilling?: string | null;
      opInsuredEmailAddressDocuments?: string | null;
      opInsuredContactFirstName?: string | null;
      opInsuredContactLastName?: string | null;
    } | null;
    primaryInsured?: {
      __typename?: "PrimaryInsured";
      name?: string | null;
      emailAddress?: string | null;
    } | null;
    coApplicant?: Array<{
      __typename?: "CoApplicant";
      firstName: string;
      lastName: string;
    }> | null;
    policyStateAttributes?: {
      __typename?: "PolicyStateAttributes";
      effectiveDate?: string | null;
      reasonCode?: {
        __typename?: "PolicyStateReasonCode";
        description?: string | null;
        label?: string | null;
        value?: string | null;
      } | null;
    } | null;
  } | null;
  userDocuments?: {
    __typename?: "Documents";
    grouped?: Array<
      Array<{
        __typename?: "Document";
        group?: string | null;
        href?: string | null;
        label?: string | null;
        lastUpdated?: string | null;
        location?: string | null;
        route?: string | null;
        templateId?: string | null;
      }>
    > | null;
    latest?: {
      __typename?: "LatestDocuments";
      declaration?: {
        __typename?: "Document";
        group?: string | null;
        href?: string | null;
        label?: string | null;
        lastUpdated?: string | null;
        location?: string | null;
        route?: string | null;
        templateId?: string | null;
      } | null;
      invoice?: {
        __typename?: "Document";
        group?: string | null;
        href?: string | null;
        label?: string | null;
        lastUpdated?: string | null;
        location?: string | null;
        route?: string | null;
        templateId?: string | null;
      } | null;
    } | null;
  } | null;
  userBasicInfo?: {
    __typename?: "UserInfo";
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    userName?: string | null;
    authProvider?: string | null;
  } | null;
  userInsuranceRep?: {
    __typename?: "InsuranceRep";
    agencyName?: string | null;
    agentStreetNumber?: string | null;
    agentStreetName?: string | null;
    agentCity?: string | null;
    agentState?: string | null;
    agentZipCode?: string | null;
    agentPhone?: string | null;
    agentEmail?: string | null;
  } | null;
  userPolicyProperties?: {
    __typename?: "PolicyProperties";
    carrierGroup?: string | null;
    policyType?: string | null;
    agencyLocationCode?: string | null;
    policyState?: string | null;
  } | null;
  userCarrierInfo?: {
    __typename?: "CarrierInfo";
    name?: string | null;
    nameShort?: string | null;
    id?: string | null;
    logo?: string | null;
    email?: string | null;
    website?: string | null;
    phone?: string | null;
    hours?: string | null;
    fax?: string | null;
  } | null;
};

export type ContactItemDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type ContactItemDataQuery = {
  __typename?: "Query";
  userBillingAddress?: string | null;
  userDetails?: {
    __typename?: "Details";
    isCommercial?: boolean | null;
    primaryInsured?: {
      __typename?: "PrimaryInsured";
      emailAddress?: string | null;
    } | null;
    mailingAddress?: {
      __typename?: "Address";
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    insuredPreferences?: {
      __typename?: "InsuredPreferences";
      paperlessDocumentEnrollmentStatus?: string | null;
      paperlessBillingEnrollmentStatus?: string | null;
      opInsuredEmailAddressDocuments?: string | null;
      opInsuredEmailAddress?: string | null;
    } | null;
  } | null;
  userClaimsConstants?: {
    __typename?: "ClaimConstants";
    PolicyHolderDetails: {
      __typename?: "PolicyHolderDetails";
      phoneNumber: string;
    };
  } | null;
  userBilling?: {
    __typename?: "Billing";
    accounting?: {
      __typename?: "Accounting";
      paymentPlan?: {
        __typename?: "PaymentPlan";
        planType?: string | null;
      } | null;
    } | null;
  } | null;
  userPolicyProperties?: {
    __typename?: "PolicyProperties";
    carrierGroup?: string | null;
  } | null;
};

export type DocusignStatusQueryVariables = Exact<{
  insightPolicyId: Scalars["String"]["input"];
}>;

export type DocusignStatusQuery = {
  __typename?: "Query";
  docusignStatus?: Array<{
    __typename?: "DocusignStatus";
    documentType?: string | null;
    id?: string | null;
    documentStatus?: DocumentStatus | null;
    createdDateTime?: any | null;
    signers?: Array<{
      __typename?: "DetailedRecipient";
      name?: string | null;
      recipientType?: RecipientType | null;
      status?: string | null;
    } | null> | null;
  } | null> | null;
};

export type EasypayProcessingFeesQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type EasypayProcessingFeesQuery = {
  __typename?: "Query";
  easypayProcessingFees: string;
};

export type GetEligibleCoveragesQueryVariables = Exact<{
  policyID: Scalars["String"]["input"];
}>;

export type GetEligibleCoveragesQuery = {
  __typename?: "Query";
  getEligibleCoverages: {
    __typename?: "OptionalCoverages";
    coverages?: Array<{
      __typename?: "Coverage";
      name: CoverageName;
      protectionValue?: number | null;
      eligibility: boolean;
      price?: any | null;
      premiumNew?: any | null;
      proratedPrice?: any | null;
      startDate?: any | null;
    } | null> | null;
  };
};

export type GetPolicyQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetPolicyQuery = {
  __typename?: "Query";
  getPolicy?: {
    __typename?: "KeystonePolicyGraphQLView";
    pendingCancellationDate?: string | null;
    inspection?: {
      __typename?: "Inspection";
      status?: string | null;
      completed?: boolean | null;
      completedDate?: string | null;
      dispositionCodeEnum?: string | null;
      dispositionCode?: string | null;
      statusEnum?: string | null;
      orderDate?: string | null;
      surveyDate?: string | null;
      underwritingUpdatedDate?: string | null;
      previewUrl?: string | null;
      vendorName?: string | null;
      confirmationNumber?: string | null;
      newBusinessInspectionWriteOuts?: {
        __typename?: "NewBusinessInspectionMessage";
        dueDate?: any | null;
        vendorName?: string | null;
        projectId?: string | null;
        referralList?: Array<{
          __typename?: "Referral";
          underwritingAction?: UnderwritingAction | null;
          resolved?: boolean | null;
          referralActionRequired?: string | null;
          location?: string | null;
          referralCategory?: ReferralCategory | null;
          underwritingCondition?: {
            __typename?: "UnderwritingCondition";
            referralCode?: string | null;
            referralText?: string | null;
            category?: ReferralCategory | null;
            actionStatement?: string | null;
            issueStatements?: Array<string | null> | null;
            reasoning?: string | null;
            proof?: string | null;
            contractor?: string | null;
            serviceProvider?: number | null;
            searchTerm?: string | null;
          } | null;
        } | null> | null;
        images?: Array<{
          __typename?: "Image";
          id?: string | null;
          baseUrl?: string | null;
          thumbnailUrl?: string | null;
          notes?: Array<string | null> | null;
        } | null> | null;
      } | null;
      renewalWriteOuts?: {
        __typename?: "Bees360Content";
        projectId?: number | null;
        bees360ReferralList?: Array<{
          __typename?: "Bees360Referral";
          underwritingAction?: UnderwritingAction | null;
          resolved?: boolean | null;
          referralActionRequired?: string | null;
          location?: string | null;
          referralCategory?: ReferralCategory | null;
          underwritingCondition?: {
            __typename?: "UnderwritingCondition";
            referralCode?: string | null;
            referralText?: string | null;
            category?: ReferralCategory | null;
            actionStatement?: string | null;
            issueStatements?: Array<string | null> | null;
            reasoning?: string | null;
            proof?: string | null;
            contractor?: string | null;
            serviceProvider?: number | null;
            searchTerm?: string | null;
          } | null;
          factors?: Array<{
            __typename?: "Factor";
            name?: string | null;
            factorDataList?: Array<{
              __typename?: "FactorData";
              text?: string | null;
              direction?: string | null;
              images?: any | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never }>;

export type IsLoggedInQuery = {
  __typename?: "Query";
  isLoggedIn?: { __typename?: "IsLoggedIn"; loggedIn?: boolean | null } | null;
};

export type OptionalCoveragesQueryVariables = Exact<{
  policyID: Scalars["String"]["input"];
}>;

export type OptionalCoveragesQuery = {
  __typename?: "Query";
  getEligibleCoverages: {
    __typename?: "OptionalCoverages";
    coverages?: Array<{
      __typename?: "Coverage";
      name: CoverageName;
      protectionValue?: number | null;
      eligibility: boolean;
      price?: any | null;
      premiumNew?: any | null;
      proratedPrice?: any | null;
      startDate?: any | null;
    } | null> | null;
  };
};

export type PaperlessEnrollmentStatusQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type PaperlessEnrollmentStatusQuery = {
  __typename?: "Query";
  paperlessEnrollmentStatus: string;
};

export type PoaSignatureStatusQueryVariables = Exact<{
  insightPolicyId: Scalars["String"]["input"];
}>;

export type PoaSignatureStatusQuery = {
  __typename?: "Query";
  getPoaSignatureStatus?: Array<{
    __typename?: "DocusignStatus";
    documentType?: string | null;
    documentStatus?: DocumentStatus | null;
    embeddedSigningUrl?: string | null;
    id?: string | null;
    emailSubject?: string | null;
    apiUserId?: string | null;
  } | null> | null;
};

export type PolicyPaperlessDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type PolicyPaperlessDataQuery = {
  __typename?: "Query";
  userBillingAddress?: string | null;
  userDetails?: {
    __typename?: "Details";
    isCommercial?: boolean | null;
    insuredPreferences?: {
      __typename?: "InsuredPreferences";
      paperlessDocumentEnrollmentStatus?: string | null;
      paperlessBillingEnrollmentStatus?: string | null;
      opInsuredEmailAddressDocuments?: string | null;
      opInsuredEmailAddress?: string | null;
    } | null;
  } | null;
  userBilling?: {
    __typename?: "Billing";
    accounting?: {
      __typename?: "Accounting";
      paymentPlan?: {
        __typename?: "PaymentPlan";
        planType?: string | null;
      } | null;
    } | null;
  } | null;
};

export type PolicyPaymentDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type PolicyPaymentDataQuery = {
  __typename?: "Query";
  userBillingAddress?: string | null;
  userDetails?: {
    __typename?: "Details";
    keystonePolicyId?: string | null;
    policyStatus?: string | null;
    insuredPreferences?: {
      __typename?: "InsuredPreferences";
      easyPayEnrollmentStatus?: string | null;
    } | null;
  } | null;
  userBilling?: {
    __typename?: "Billing";
    accounting?: {
      __typename?: "Accounting";
      paymentPlan?: {
        __typename?: "PaymentPlan";
        planType?: string | null;
      } | null;
    } | null;
    mortgagees?: Array<{
      __typename?: "Mortgagee";
      name?: string | null;
      loanNumber?: string | null;
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      index?: number | null;
    }> | null;
  } | null;
};

export type PolicyPaymentPlansQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type PolicyPaymentPlansQuery = {
  __typename?: "Query";
  policyPaymentPlans: Array<{
    __typename?: "PaymentPlanDetails";
    planId?: string | null;
    downPayment?: string | null;
    installmentAmount?: string | null;
    paymentPlanSetupFee?: string | null;
    installmentCharge?: string | null;
    numberOfPayments?: string | null;
    billingMethod?: string | null;
  }>;
};

export type ServiceFeesQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type ServiceFeesQuery = { __typename?: "Query"; serviceFees: string };

export type LegacyUserDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type LegacyUserDataQuery = {
  __typename?: "Query";
  isLoggedIn?: { __typename?: "IsLoggedIn"; loggedIn?: boolean | null } | null;
  userInfo?: {
    __typename?: "UserInfo";
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    userName?: string | null;
    authProvider?: string | null;
  } | null;
};

export type UserAdditionalCoveragesQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type UserAdditionalCoveragesQuery = {
  __typename?: "Query";
  userAdditionalCoverages?: {
    __typename?: "AdditionalCoverages";
    identityFraudCoverage?: string | null;
    identityFraudCoveragePremium?: string | null;
    identityFraudIncreaseLimit?: string | null;
    identityFraudTotalLimit?: string | null;
    mechanicalBreakdownCoverage?: string | null;
    mechanicalBreakdownCoveragePremium?: string | null;
    mechanicalElectricalEquipmentGroundLevel?: string | null;
    serviceLineCoverage?: string | null;
    serviceLineDeductible?: string | null;
    serviceLinePremium?: string | null;
  } | null;
};

export type UserBillingAddressQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type UserBillingAddressQuery = {
  __typename?: "Query";
  userBillingAddress?: string | null;
};

export type UserClaimsConstantsQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type UserClaimsConstantsQuery = {
  __typename?: "Query";
  userClaimsConstants?: {
    __typename?: "ClaimConstants";
    LossTypes: Array<string>;
    RelationshipToPolicyHolderTypes: Array<string>;
    RelationshipToCommercialPolicyHolderTypes: Array<string>;
    PrimaryPhoneTypes: Array<string>;
    SecondaryPhoneTypes: Array<string>;
    CarrierDetails: {
      __typename?: "CarrierClaimsDetails";
      claimsActivityInitialText: string;
      carrierDetailsText: string;
      carrierLogo: string;
      claimsFormReviewText: string;
      claimsReviewText: string;
      claimsContactCarrierText: string;
      claimsReceiveCarrierCallText: string;
    };
    PolicyHolderDetails: {
      __typename?: "PolicyHolderDetails";
      name: string;
      emailAddress: string;
      phoneNumber: string;
    };
  } | null;
};

export type UserClaimsListQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type UserClaimsListQuery = {
  __typename?: "Query";
  userClaimsList?: {
    __typename?: "ClaimList";
    PolicyNumber?: string | null;
    InsuredName?: string | null;
    ClaimsTrackerInfo?: Array<{
      __typename?: "ClaimTrackerItem";
      Status: Status;
      CarrierClaim?: {
        __typename?: "ClaimTrackerCarrierClaim";
        DateSubmitted: string;
        Number: string;
        DateOfLoss: string;
        TypeOfLoss: string;
        CustomerContactDate: string;
        ClosedDate: string;
      } | null;
      FNOLSubmission?: {
        __typename?: "Submission";
        SubmissionNumber: string;
        DateOfLoss?: string | null;
        DateReceived?: string | null;
        LossAtInsuredProperty?: boolean | null;
        TypeOfLoss?: string | null;
        LossDescription?: string | null;
        ClaimSubmittedByPolicyholder?: boolean | null;
        LocationOfLoss?: {
          __typename?: "LocationOfLoss";
          Address?: string | null;
          AddressLine2?: string | null;
          City?: string | null;
          State?: string | null;
          Zip?: string | null;
        } | null;
        ClaimSubmittedBy?: {
          __typename?: "ClaimSubmittedBy";
          Name?: string | null;
          Relationship?: string | null;
          PrimaryPhoneNumber?: string | null;
          PrimaryPhoneNumberType?: string | null;
          AlternatePhoneNumber?: string | null;
          AlternatePhoneNumberType?: string | null;
        } | null;
        ContactInfo?: {
          __typename?: "ContactInfo";
          Name?: string | null;
          Relationship?: string | null;
          Mobile?: string | null;
          Email?: string | null;
          PrimaryPhoneNumber?: string | null;
          PrimaryPhoneNumberType?: string | null;
          AlternatePhoneNumber?: string | null;
          AlternatePhoneNumberType?: string | null;
          WantsToReceiveSMSUpdates?: boolean | null;
        } | null;
      } | null;
      Inspection?: {
        __typename?: "Inspection";
        DateInspectionCompleted: string;
        DateReportCompleted?: string | null;
      } | null;
      Adjuster?: {
        __typename?: "Adjuster";
        DateAssigned: string;
        AdjusterName: string;
        PhoneNumber?: string | null;
        Email?: string | null;
      } | null;
      Payments?: Array<{
        __typename?: "ClaimTrackerPayment";
        DateOfPayment: string;
        PaymentAmount: string;
        Coverage?: string | null;
        CheckNumber?: string | null;
        PayableTo?: string | null;
      }> | null;
      Milestones?: {
        __typename?: "ClaimTrackerMilestones";
        ClaimReport?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        AdjusterAssignment?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        CustomerContact?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        PropertyInspection?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        FieldInspectionReport?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        ClaimSettlement?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
      } | null;
      FootNotes?: {
        __typename?: "ClaimTrackerFootNotes";
        Disclaimer?: {
          __typename?: "FootNote";
          Message?: string | null;
          Show?: boolean | null;
        } | null;
        Weather?: {
          __typename?: "FootNote";
          Message?: string | null;
          Show?: boolean | null;
        } | null;
      } | null;
    }> | null;
    ClaimsInfo?: Array<{
      __typename?: "Claim";
      ClaimNumber?: string | null;
      DateOfLoss?: string | null;
      DateReceived?: string | null;
      Status?: Status | null;
      LossCauseDescription?: string | null;
      ClaimDescription?: string | null;
      IndependentAdjusterName?: string | null;
      IndependentAdjusterPhoneNumber?: string | null;
      SubmissionNumber?: string | null;
      payments?: Array<{
        __typename?: "Payment";
        paymentAmount?: string | null;
        paymentDate?: string | null;
      }> | null;
    }> | null;
    SubmissionsInfo?: Array<{
      __typename?: "Submission";
      SubmissionNumber: string;
      DateOfLoss?: string | null;
      DateReceived?: string | null;
      LossAtInsuredProperty?: boolean | null;
      TypeOfLoss?: string | null;
      LossDescription?: string | null;
      ClaimSubmittedByPolicyholder?: boolean | null;
      LocationOfLoss?: {
        __typename?: "LocationOfLoss";
        Address?: string | null;
        AddressLine2?: string | null;
        City?: string | null;
        State?: string | null;
        Zip?: string | null;
      } | null;
      ClaimSubmittedBy?: {
        __typename?: "ClaimSubmittedBy";
        Name?: string | null;
        Relationship?: string | null;
        PrimaryPhoneNumber?: string | null;
        PrimaryPhoneNumberType?: string | null;
        AlternatePhoneNumber?: string | null;
        AlternatePhoneNumberType?: string | null;
      } | null;
      ContactInfo?: {
        __typename?: "ContactInfo";
        Name?: string | null;
        Relationship?: string | null;
        Mobile?: string | null;
        Email?: string | null;
        PrimaryPhoneNumber?: string | null;
        PrimaryPhoneNumberType?: string | null;
        AlternatePhoneNumber?: string | null;
        AlternatePhoneNumberType?: string | null;
        WantsToReceiveSMSUpdates?: boolean | null;
      } | null;
    }> | null;
  } | null;
};

export type UserPolicyIDsQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type UserPolicyIDsQuery = {
  __typename?: "Query";
  userPolicies?: Array<{
    __typename?: "PolicyInfo";
    policyId: string;
    isCommercial?: boolean | null;
    policyState?: string | null;
    insightPolicyId: string;
    propertyAddress?: {
      __typename?: "propertyAddress";
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
  }> | null;
};

export type UserPropertyProfileDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type UserPropertyProfileDataQuery = {
  __typename?: "Query";
  userPropertyProfileData?: {
    __typename?: "PropertyProfileData";
    constructionYearRoof?: number | null;
    poolType?: string | null;
    poolFence?: string | null;
    burglarAlarm?: string | null;
    fireAlarm?: string | null;
    trampoline?: boolean | null;
    roofCoveringType?: string | null;
    poolFenceAboveGround?: boolean | null;
    divingBoardOrSlide?: boolean | null;
    poolCovering?: boolean | null;
    immovablePoolLadder?: boolean | null;
    unlockedPoolGate?: boolean | null;
    propertyUpdateData?: Array<{
      __typename?: "PropertyUpdateData";
      detailName?: string | null;
      taskStatus?: string | null;
      items?: Array<{
        __typename?: "PropertyUpdateEntry";
        termName?: string | null;
        enumerationValue?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UserTransactionsQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type UserTransactionsQuery = {
  __typename?: "Query";
  userTransactions?: {
    __typename?: "Transactions";
    latestPayment?: string | null;
    lastPaymentDate?: string | null;
    transactions?: Array<{
      __typename?: "Transaction";
      type?: string | null;
      description?: string | null;
      amount?: string | null;
      date?: any | null;
      accountNumber?: string | null;
      creditCardType?: string | null;
      method?: string | null;
    }> | null;
  } | null;
};

export type VerifyRoofShingleResealIntakeEligibilityQueryVariables = Exact<{
  policyId: Scalars["String"]["input"];
  postal: Scalars["String"]["input"];
}>;

export type VerifyRoofShingleResealIntakeEligibilityQuery = {
  __typename?: "Query";
  verifyRoofShingleResealIntakeEligibility?: {
    __typename?: "RoofShingleResealIntakeEligibility";
    insightPolicyId?: string | null;
    eligible?: boolean | null;
    address?: {
      __typename?: "Address";
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
  } | null;
};

export type BillingPageDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type BillingPageDataQuery = {
  __typename?: "Query";
  userBillingAddress?: string | null;
  userTransactions?: {
    __typename?: "Transactions";
    transactions?: Array<{
      __typename?: "Transaction";
      type?: string | null;
      description?: string | null;
      amount?: string | null;
      date?: any | null;
      accountNumber?: string | null;
      creditCardType?: string | null;
      method?: string | null;
    }> | null;
  } | null;
  policyPaymentPlans: Array<{
    __typename?: "PaymentPlanDetails";
    planId?: string | null;
    downPayment?: string | null;
    installmentAmount?: string | null;
    paymentPlanSetupFee?: string | null;
    installmentCharge?: string | null;
    numberOfPayments?: string | null;
    billingMethod?: string | null;
  }>;
};

export type ClaimsPageDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type ClaimsPageDataQuery = {
  __typename?: "Query";
  userCarrierInfo?: {
    __typename?: "CarrierInfo";
    name?: string | null;
    nameShort?: string | null;
    id?: string | null;
    logo?: string | null;
    email?: string | null;
    website?: string | null;
    phone?: string | null;
    hours?: string | null;
    fax?: string | null;
  } | null;
  userClaimsConstants?: {
    __typename?: "ClaimConstants";
    LossTypes: Array<string>;
    RelationshipToPolicyHolderTypes: Array<string>;
    RelationshipToCommercialPolicyHolderTypes: Array<string>;
    PrimaryPhoneTypes: Array<string>;
    SecondaryPhoneTypes: Array<string>;
    CarrierDetails: {
      __typename?: "CarrierClaimsDetails";
      claimsActivityInitialText: string;
      carrierDetailsText: string;
      carrierLogo: string;
      claimsFormReviewText: string;
      claimsReviewText: string;
      claimsContactCarrierText: string;
      claimsReceiveCarrierCallText: string;
    };
    PolicyHolderDetails: {
      __typename?: "PolicyHolderDetails";
      name: string;
      emailAddress: string;
      phoneNumber: string;
    };
  } | null;
  userClaimsList?: {
    __typename?: "ClaimList";
    PolicyNumber?: string | null;
    InsuredName?: string | null;
    ClaimsTrackerInfo?: Array<{
      __typename?: "ClaimTrackerItem";
      Status: Status;
      CarrierClaim?: {
        __typename?: "ClaimTrackerCarrierClaim";
        DateSubmitted: string;
        Number: string;
        DateOfLoss: string;
        TypeOfLoss: string;
        CustomerContactDate: string;
        ClosedDate: string;
      } | null;
      FNOLSubmission?: {
        __typename?: "Submission";
        SubmissionNumber: string;
        DateOfLoss?: string | null;
        DateReceived?: string | null;
        LossAtInsuredProperty?: boolean | null;
        TypeOfLoss?: string | null;
        LossDescription?: string | null;
        ClaimSubmittedByPolicyholder?: boolean | null;
        LocationOfLoss?: {
          __typename?: "LocationOfLoss";
          Address?: string | null;
          AddressLine2?: string | null;
          City?: string | null;
          State?: string | null;
          Zip?: string | null;
        } | null;
        ClaimSubmittedBy?: {
          __typename?: "ClaimSubmittedBy";
          Name?: string | null;
          Relationship?: string | null;
          PrimaryPhoneNumber?: string | null;
          PrimaryPhoneNumberType?: string | null;
          AlternatePhoneNumber?: string | null;
          AlternatePhoneNumberType?: string | null;
        } | null;
        ContactInfo?: {
          __typename?: "ContactInfo";
          Name?: string | null;
          Relationship?: string | null;
          Mobile?: string | null;
          Email?: string | null;
          PrimaryPhoneNumber?: string | null;
          PrimaryPhoneNumberType?: string | null;
          AlternatePhoneNumber?: string | null;
          AlternatePhoneNumberType?: string | null;
          WantsToReceiveSMSUpdates?: boolean | null;
        } | null;
      } | null;
      Inspection?: {
        __typename?: "Inspection";
        DateInspectionCompleted: string;
        DateReportCompleted?: string | null;
      } | null;
      Adjuster?: {
        __typename?: "Adjuster";
        DateAssigned: string;
        AdjusterName: string;
        PhoneNumber?: string | null;
        Email?: string | null;
      } | null;
      Payments?: Array<{
        __typename?: "ClaimTrackerPayment";
        DateOfPayment: string;
        PaymentAmount: string;
        Coverage?: string | null;
        CheckNumber?: string | null;
        PayableTo?: string | null;
      }> | null;
      Milestones?: {
        __typename?: "ClaimTrackerMilestones";
        ClaimReport?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        AdjusterAssignment?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        CustomerContact?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        PropertyInspection?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        FieldInspectionReport?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
        ClaimSettlement?: {
          __typename?: "Milestone";
          Name?: string | null;
          CompletedName?: string | null;
          Message?: string | null;
          Sla?: string | null;
          ShowSla?: boolean | null;
          ShowMilestone?: boolean | null;
        } | null;
      } | null;
      FootNotes?: {
        __typename?: "ClaimTrackerFootNotes";
        Disclaimer?: {
          __typename?: "FootNote";
          Message?: string | null;
          Show?: boolean | null;
        } | null;
        Weather?: {
          __typename?: "FootNote";
          Message?: string | null;
          Show?: boolean | null;
        } | null;
      } | null;
    }> | null;
    ClaimsInfo?: Array<{
      __typename?: "Claim";
      ClaimNumber?: string | null;
      DateOfLoss?: string | null;
      DateReceived?: string | null;
      Status?: Status | null;
      LossCauseDescription?: string | null;
      ClaimDescription?: string | null;
      IndependentAdjusterName?: string | null;
      IndependentAdjusterPhoneNumber?: string | null;
      SubmissionNumber?: string | null;
      payments?: Array<{
        __typename?: "Payment";
        paymentAmount?: string | null;
        paymentDate?: string | null;
      }> | null;
    }> | null;
    SubmissionsInfo?: Array<{
      __typename?: "Submission";
      SubmissionNumber: string;
      DateOfLoss?: string | null;
      DateReceived?: string | null;
      LossAtInsuredProperty?: boolean | null;
      TypeOfLoss?: string | null;
      LossDescription?: string | null;
      ClaimSubmittedByPolicyholder?: boolean | null;
      LocationOfLoss?: {
        __typename?: "LocationOfLoss";
        Address?: string | null;
        AddressLine2?: string | null;
        City?: string | null;
        State?: string | null;
        Zip?: string | null;
      } | null;
      ClaimSubmittedBy?: {
        __typename?: "ClaimSubmittedBy";
        Name?: string | null;
        Relationship?: string | null;
        PrimaryPhoneNumber?: string | null;
        PrimaryPhoneNumberType?: string | null;
        AlternatePhoneNumber?: string | null;
        AlternatePhoneNumberType?: string | null;
      } | null;
      ContactInfo?: {
        __typename?: "ContactInfo";
        Name?: string | null;
        Relationship?: string | null;
        Mobile?: string | null;
        Email?: string | null;
        PrimaryPhoneNumber?: string | null;
        PrimaryPhoneNumberType?: string | null;
        AlternatePhoneNumber?: string | null;
        AlternatePhoneNumberType?: string | null;
        WantsToReceiveSMSUpdates?: boolean | null;
      } | null;
    }> | null;
  } | null;
};

export type ExternalClaimSubmitQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type ExternalClaimSubmitQuery = {
  __typename?: "Query";
  userDetails?: {
    __typename?: "Details";
    propertyAddress?: {
      __typename?: "Address";
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    currentTerm?: {
      __typename?: "CurrentTerm";
      expirationDate?: any | null;
      effectiveDate?: any | null;
    } | null;
  } | null;
  userPolicyProperties?: {
    __typename?: "PolicyProperties";
    carrierGroup?: string | null;
    policyType?: string | null;
  } | null;
  userCarrierInfo?: {
    __typename?: "CarrierInfo";
    id?: string | null;
    name?: string | null;
  } | null;
  userClaimsConstants?: {
    __typename?: "ClaimConstants";
    PolicyHolderDetails: {
      __typename?: "PolicyHolderDetails";
      name: string;
      emailAddress: string;
      phoneNumber: string;
    };
  } | null;
};

export type LeakbotPageDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type LeakbotPageDataQuery = {
  __typename?: "Query";
  userClaimsConstants?: {
    __typename?: "ClaimConstants";
    PolicyHolderDetails: {
      __typename?: "PolicyHolderDetails";
      phoneNumber: string;
    };
  } | null;
};

export type CoveragePriceInfoQueryVariables = Exact<{
  policyID: Scalars["String"]["input"];
  coverageName: CoverageName;
  protectionValue: Scalars["Int"]["input"];
}>;

export type CoveragePriceInfoQuery = {
  __typename?: "Query";
  previewCoverage: {
    __typename?: "OptionalCoverages";
    coverages?: Array<{
      __typename?: "Coverage";
      name: CoverageName;
      protectionValue?: number | null;
      price?: any | null;
      premiumNew?: any | null;
      proratedPrice?: any | null;
      startDate?: any | null;
    } | null> | null;
    policyRenewal?: {
      __typename?: "PolicyRenewal";
      startDate?: any | null;
      endDate?: any | null;
    } | null;
  };
};

export type ReviewQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type ReviewQuery = {
  __typename?: "Query";
  easypayProcessingFees: string;
  policyPaymentPlans: Array<{
    __typename?: "PaymentPlanDetails";
    downPayment?: string | null;
    installmentAmount?: string | null;
    paymentPlanSetupFee?: string | null;
    installmentCharge?: string | null;
    numberOfPayments?: string | null;
    billingMethod?: string | null;
  }>;
};

export type ContactSettingsDataQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ContactSettingsDataQuery = {
  __typename?: "Query";
  userPolicies?: Array<{
    __typename?: "PolicyInfo";
    policyId: string;
    isCommercial?: boolean | null;
    policyState?: string | null;
    propertyAddress?: {
      __typename?: "propertyAddress";
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
  }> | null;
};

export type EnrollmentStatusDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type EnrollmentStatusDataQuery = {
  __typename?: "Query";
  userDetails?: {
    __typename?: "Details";
    isEnrolledInLeakBot?: boolean | null;
  } | null;
};

export type HomeServicesDataQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type HomeServicesDataQuery = {
  __typename?: "Query";
  userPolicies?: Array<{
    __typename?: "PolicyInfo";
    policyId: string;
    isCommercial?: boolean | null;
    policyState?: string | null;
    propertyAddress?: {
      __typename?: "propertyAddress";
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
  }> | null;
};

export type PaperlessSettingsDataQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type PaperlessSettingsDataQuery = {
  __typename?: "Query";
  userPolicies?: Array<{
    __typename?: "PolicyInfo";
    policyId: string;
    isCommercial?: boolean | null;
    policyState?: string | null;
    propertyAddress?: {
      __typename?: "propertyAddress";
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
  }> | null;
};

export type PaymentSettingsDataQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type PaymentSettingsDataQuery = {
  __typename?: "Query";
  userPolicies?: Array<{
    __typename?: "PolicyInfo";
    policyId: string;
    isCommercial?: boolean | null;
    policyState?: string | null;
    propertyAddress?: {
      __typename?: "propertyAddress";
      street1?: string | null;
      street2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
  }> | null;
};

export type PolicyItemDataQueryVariables = Exact<{
  policyID: Scalars["ID"]["input"];
}>;

export type PolicyItemDataQuery = {
  __typename?: "Query";
  userDetails?: {
    __typename?: "Details";
    currentTerm?: {
      __typename?: "CurrentTerm";
      expirationDate?: any | null;
      effectiveDatePolicyTerm?: any | null;
    } | null;
  } | null;
};

export type SecurityDataQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type SecurityDataQuery = {
  __typename?: "Query";
  userBasicInfo?: {
    __typename?: "UserInfo";
    email?: string | null;
    userName?: string | null;
    authProvider?: string | null;
  } | null;
};

export const AddPolicyDocument = gql`
  mutation addPolicy($email: String!, $policyId: String!, $zipCode: String!) {
    addPolicy(email: $email, policyId: $policyId, zipCode: $zipCode) {
      success
      errors
      errorCode
    }
  }
`;
export type AddPolicyMutationFn = Apollo.MutationFunction<
  AddPolicyMutation,
  AddPolicyMutationVariables
>;

/**
 * __useAddPolicyMutation__
 *
 * To run a mutation, you first call `useAddPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPolicyMutation, { data, loading, error }] = useAddPolicyMutation({
 *   variables: {
 *      email: // value for 'email'
 *      policyId: // value for 'policyId'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useAddPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPolicyMutation,
    AddPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPolicyMutation, AddPolicyMutationVariables>(
    AddPolicyDocument,
    options
  );
}
export type AddPolicyMutationHookResult = ReturnType<
  typeof useAddPolicyMutation
>;
export type AddPolicyMutationResult = Apollo.MutationResult<AddPolicyMutation>;
export type AddPolicyMutationOptions = Apollo.BaseMutationOptions<
  AddPolicyMutation,
  AddPolicyMutationVariables
>;
export const AddRoofShingleResealIntakeDocument = gql`
  mutation addRoofShingleResealIntake(
    $roofShingleResealIntake: RoofShingleResealIntakeInput!
  ) {
    addRoofShingleResealIntake(
      roofShingleResealIntake: $roofShingleResealIntake
    )
  }
`;
export type AddRoofShingleResealIntakeMutationFn = Apollo.MutationFunction<
  AddRoofShingleResealIntakeMutation,
  AddRoofShingleResealIntakeMutationVariables
>;

/**
 * __useAddRoofShingleResealIntakeMutation__
 *
 * To run a mutation, you first call `useAddRoofShingleResealIntakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoofShingleResealIntakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoofShingleResealIntakeMutation, { data, loading, error }] = useAddRoofShingleResealIntakeMutation({
 *   variables: {
 *      roofShingleResealIntake: // value for 'roofShingleResealIntake'
 *   },
 * });
 */
export function useAddRoofShingleResealIntakeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRoofShingleResealIntakeMutation,
    AddRoofShingleResealIntakeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRoofShingleResealIntakeMutation,
    AddRoofShingleResealIntakeMutationVariables
  >(AddRoofShingleResealIntakeDocument, options);
}
export type AddRoofShingleResealIntakeMutationHookResult = ReturnType<
  typeof useAddRoofShingleResealIntakeMutation
>;
export type AddRoofShingleResealIntakeMutationResult =
  Apollo.MutationResult<AddRoofShingleResealIntakeMutation>;
export type AddRoofShingleResealIntakeMutationOptions =
  Apollo.BaseMutationOptions<
    AddRoofShingleResealIntakeMutation,
    AddRoofShingleResealIntakeMutationVariables
  >;
export const AuthenticateUserDocument = gql`
  mutation authenticateUser($username: String!, $password: String!) {
    authenticateUser(username: $username, password: $password) {
      errors
      errorCode
      success
    }
  }
`;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<
  AuthenticateUserMutation,
  AuthenticateUserMutationVariables
>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
  >(AuthenticateUserDocument, options);
}
export type AuthenticateUserMutationHookResult = ReturnType<
  typeof useAuthenticateUserMutation
>;
export type AuthenticateUserMutationResult =
  Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateUserMutation,
  AuthenticateUserMutationVariables
>;
export const ContactPolicyRepresentativeDocument = gql`
  mutation contactPolicyRepresentative(
    $policyID: String!
    $coverageName: CoverageName!
    $protectionValue: Int!
    $message: String
  ) {
    contactPolicyRepresentative(
      policyId: $policyID
      coverageName: $coverageName
      protectionValue: $protectionValue
      message: $message
    ) {
      agentName
      agentPhone
      coverage {
        name
      }
    }
  }
`;
export type ContactPolicyRepresentativeMutationFn = Apollo.MutationFunction<
  ContactPolicyRepresentativeMutation,
  ContactPolicyRepresentativeMutationVariables
>;

/**
 * __useContactPolicyRepresentativeMutation__
 *
 * To run a mutation, you first call `useContactPolicyRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactPolicyRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactPolicyRepresentativeMutation, { data, loading, error }] = useContactPolicyRepresentativeMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *      coverageName: // value for 'coverageName'
 *      protectionValue: // value for 'protectionValue'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useContactPolicyRepresentativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactPolicyRepresentativeMutation,
    ContactPolicyRepresentativeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContactPolicyRepresentativeMutation,
    ContactPolicyRepresentativeMutationVariables
  >(ContactPolicyRepresentativeDocument, options);
}
export type ContactPolicyRepresentativeMutationHookResult = ReturnType<
  typeof useContactPolicyRepresentativeMutation
>;
export type ContactPolicyRepresentativeMutationResult =
  Apollo.MutationResult<ContactPolicyRepresentativeMutation>;
export type ContactPolicyRepresentativeMutationOptions =
  Apollo.BaseMutationOptions<
    ContactPolicyRepresentativeMutation,
    ContactPolicyRepresentativeMutationVariables
  >;
export const ConvertEnvelopeIdToSigningLinkDocument = gql`
  mutation convertEnvelopeIdToSigningLink($envelopeId: String!) {
    convertEnvelopeIdToSigningLink(envelopeId: $envelopeId)
  }
`;
export type ConvertEnvelopeIdToSigningLinkMutationFn = Apollo.MutationFunction<
  ConvertEnvelopeIdToSigningLinkMutation,
  ConvertEnvelopeIdToSigningLinkMutationVariables
>;

/**
 * __useConvertEnvelopeIdToSigningLinkMutation__
 *
 * To run a mutation, you first call `useConvertEnvelopeIdToSigningLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertEnvelopeIdToSigningLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertEnvelopeIdToSigningLinkMutation, { data, loading, error }] = useConvertEnvelopeIdToSigningLinkMutation({
 *   variables: {
 *      envelopeId: // value for 'envelopeId'
 *   },
 * });
 */
export function useConvertEnvelopeIdToSigningLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertEnvelopeIdToSigningLinkMutation,
    ConvertEnvelopeIdToSigningLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConvertEnvelopeIdToSigningLinkMutation,
    ConvertEnvelopeIdToSigningLinkMutationVariables
  >(ConvertEnvelopeIdToSigningLinkDocument, options);
}
export type ConvertEnvelopeIdToSigningLinkMutationHookResult = ReturnType<
  typeof useConvertEnvelopeIdToSigningLinkMutation
>;
export type ConvertEnvelopeIdToSigningLinkMutationResult =
  Apollo.MutationResult<ConvertEnvelopeIdToSigningLinkMutation>;
export type ConvertEnvelopeIdToSigningLinkMutationOptions =
  Apollo.BaseMutationOptions<
    ConvertEnvelopeIdToSigningLinkMutation,
    ConvertEnvelopeIdToSigningLinkMutationVariables
  >;
export const DecodeDataDocument = gql`
  mutation decodeData($data: String!) {
    decodeData(data: $data) {
      success
      policyId
      zipcode
      email
      firstName
      lastName
    }
  }
`;
export type DecodeDataMutationFn = Apollo.MutationFunction<
  DecodeDataMutation,
  DecodeDataMutationVariables
>;

/**
 * __useDecodeDataMutation__
 *
 * To run a mutation, you first call `useDecodeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecodeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decodeDataMutation, { data, loading, error }] = useDecodeDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDecodeDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DecodeDataMutation,
    DecodeDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DecodeDataMutation, DecodeDataMutationVariables>(
    DecodeDataDocument,
    options
  );
}
export type DecodeDataMutationHookResult = ReturnType<
  typeof useDecodeDataMutation
>;
export type DecodeDataMutationResult =
  Apollo.MutationResult<DecodeDataMutation>;
export type DecodeDataMutationOptions = Apollo.BaseMutationOptions<
  DecodeDataMutation,
  DecodeDataMutationVariables
>;
export const EasypayEnrollDocument = gql`
  mutation easypayEnroll($paymentData: PaymentData!) {
    easypayEnroll(paymentData: $paymentData) {
      data {
        easyPayEnrollmentStatus
      }
      success
      errors
    }
  }
`;
export type EasypayEnrollMutationFn = Apollo.MutationFunction<
  EasypayEnrollMutation,
  EasypayEnrollMutationVariables
>;

/**
 * __useEasypayEnrollMutation__
 *
 * To run a mutation, you first call `useEasypayEnrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEasypayEnrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [easypayEnrollMutation, { data, loading, error }] = useEasypayEnrollMutation({
 *   variables: {
 *      paymentData: // value for 'paymentData'
 *   },
 * });
 */
export function useEasypayEnrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EasypayEnrollMutation,
    EasypayEnrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EasypayEnrollMutation,
    EasypayEnrollMutationVariables
  >(EasypayEnrollDocument, options);
}
export type EasypayEnrollMutationHookResult = ReturnType<
  typeof useEasypayEnrollMutation
>;
export type EasypayEnrollMutationResult =
  Apollo.MutationResult<EasypayEnrollMutation>;
export type EasypayEnrollMutationOptions = Apollo.BaseMutationOptions<
  EasypayEnrollMutation,
  EasypayEnrollMutationVariables
>;
export const GeneratePoaLinkDocument = gql`
  mutation generatePoaLink($policyID: String!, $emailAddress: String!) {
    generatePoaWithSigningLink(policyId: $policyID, emailAddress: $emailAddress)
  }
`;
export type GeneratePoaLinkMutationFn = Apollo.MutationFunction<
  GeneratePoaLinkMutation,
  GeneratePoaLinkMutationVariables
>;

/**
 * __useGeneratePoaLinkMutation__
 *
 * To run a mutation, you first call `useGeneratePoaLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePoaLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePoaLinkMutation, { data, loading, error }] = useGeneratePoaLinkMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useGeneratePoaLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratePoaLinkMutation,
    GeneratePoaLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratePoaLinkMutation,
    GeneratePoaLinkMutationVariables
  >(GeneratePoaLinkDocument, options);
}
export type GeneratePoaLinkMutationHookResult = ReturnType<
  typeof useGeneratePoaLinkMutation
>;
export type GeneratePoaLinkMutationResult =
  Apollo.MutationResult<GeneratePoaLinkMutation>;
export type GeneratePoaLinkMutationOptions = Apollo.BaseMutationOptions<
  GeneratePoaLinkMutation,
  GeneratePoaLinkMutationVariables
>;
export const LoginUserDocument = gql`
  mutation loginUser($username: String!, $password: String!) {
    loginUser(username: $username, password: $password) {
      errors
      errorCode
      success
      redirect
      data {
        userId
        email
        firstName
        lastName
      }
    }
  }
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginUserMutation,
    LoginUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options
  );
}
export type LoginUserMutationHookResult = ReturnType<
  typeof useLoginUserMutation
>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const LoginUserGoogleDocument = gql`
  mutation loginUserGoogle($googleJWT: String!) {
    loginUserGoogle(googleJWT: $googleJWT) {
      errors
      errorCode
      success
      redirect
      data {
        userId
        email
        firstName
        lastName
        authProvider
      }
    }
  }
`;
export type LoginUserGoogleMutationFn = Apollo.MutationFunction<
  LoginUserGoogleMutation,
  LoginUserGoogleMutationVariables
>;

/**
 * __useLoginUserGoogleMutation__
 *
 * To run a mutation, you first call `useLoginUserGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserGoogleMutation, { data, loading, error }] = useLoginUserGoogleMutation({
 *   variables: {
 *      googleJWT: // value for 'googleJWT'
 *   },
 * });
 */
export function useLoginUserGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginUserGoogleMutation,
    LoginUserGoogleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginUserGoogleMutation,
    LoginUserGoogleMutationVariables
  >(LoginUserGoogleDocument, options);
}
export type LoginUserGoogleMutationHookResult = ReturnType<
  typeof useLoginUserGoogleMutation
>;
export type LoginUserGoogleMutationResult =
  Apollo.MutationResult<LoginUserGoogleMutation>;
export type LoginUserGoogleMutationOptions = Apollo.BaseMutationOptions<
  LoginUserGoogleMutation,
  LoginUserGoogleMutationVariables
>;
export const LogoutUserDocument = gql`
  mutation logoutUser {
    logoutUser
  }
`;
export type LogoutUserMutationFn = Apollo.MutationFunction<
  LogoutUserMutation,
  LogoutUserMutationVariables
>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutUserMutation,
    LogoutUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(
    LogoutUserDocument,
    options
  );
}
export type LogoutUserMutationHookResult = ReturnType<
  typeof useLogoutUserMutation
>;
export type LogoutUserMutationResult =
  Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<
  LogoutUserMutation,
  LogoutUserMutationVariables
>;
export const PaperlessBillingEnrollDocument = gql`
  mutation paperlessBillingEnroll($policyID: String!) {
    paperlessBillingEnroll(policyID: $policyID) {
      opInsuredEmailAddress
      opInsuredEmailAddressBilling
    }
  }
`;
export type PaperlessBillingEnrollMutationFn = Apollo.MutationFunction<
  PaperlessBillingEnrollMutation,
  PaperlessBillingEnrollMutationVariables
>;

/**
 * __usePaperlessBillingEnrollMutation__
 *
 * To run a mutation, you first call `usePaperlessBillingEnrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaperlessBillingEnrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paperlessBillingEnrollMutation, { data, loading, error }] = usePaperlessBillingEnrollMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function usePaperlessBillingEnrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaperlessBillingEnrollMutation,
    PaperlessBillingEnrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaperlessBillingEnrollMutation,
    PaperlessBillingEnrollMutationVariables
  >(PaperlessBillingEnrollDocument, options);
}
export type PaperlessBillingEnrollMutationHookResult = ReturnType<
  typeof usePaperlessBillingEnrollMutation
>;
export type PaperlessBillingEnrollMutationResult =
  Apollo.MutationResult<PaperlessBillingEnrollMutation>;
export type PaperlessBillingEnrollMutationOptions = Apollo.BaseMutationOptions<
  PaperlessBillingEnrollMutation,
  PaperlessBillingEnrollMutationVariables
>;
export const PaperlessDocumentsEnrollDocument = gql`
  mutation paperlessDocumentsEnroll($policyID: String!) {
    paperlessDocumentsEnroll(policyID: $policyID)
  }
`;
export type PaperlessDocumentsEnrollMutationFn = Apollo.MutationFunction<
  PaperlessDocumentsEnrollMutation,
  PaperlessDocumentsEnrollMutationVariables
>;

/**
 * __usePaperlessDocumentsEnrollMutation__
 *
 * To run a mutation, you first call `usePaperlessDocumentsEnrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaperlessDocumentsEnrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paperlessDocumentsEnrollMutation, { data, loading, error }] = usePaperlessDocumentsEnrollMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function usePaperlessDocumentsEnrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaperlessDocumentsEnrollMutation,
    PaperlessDocumentsEnrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaperlessDocumentsEnrollMutation,
    PaperlessDocumentsEnrollMutationVariables
  >(PaperlessDocumentsEnrollDocument, options);
}
export type PaperlessDocumentsEnrollMutationHookResult = ReturnType<
  typeof usePaperlessDocumentsEnrollMutation
>;
export type PaperlessDocumentsEnrollMutationResult =
  Apollo.MutationResult<PaperlessDocumentsEnrollMutation>;
export type PaperlessDocumentsEnrollMutationOptions =
  Apollo.BaseMutationOptions<
    PaperlessDocumentsEnrollMutation,
    PaperlessDocumentsEnrollMutationVariables
  >;
export const PaperlessDocumentsEnrollFromEmailDocument = gql`
  mutation paperlessDocumentsEnrollFromEmail($data: String!) {
    paperlessDocumentsEnrollFromEmail(data: $data) {
      success
      errorCode
      errors
    }
  }
`;
export type PaperlessDocumentsEnrollFromEmailMutationFn =
  Apollo.MutationFunction<
    PaperlessDocumentsEnrollFromEmailMutation,
    PaperlessDocumentsEnrollFromEmailMutationVariables
  >;

/**
 * __usePaperlessDocumentsEnrollFromEmailMutation__
 *
 * To run a mutation, you first call `usePaperlessDocumentsEnrollFromEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaperlessDocumentsEnrollFromEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paperlessDocumentsEnrollFromEmailMutation, { data, loading, error }] = usePaperlessDocumentsEnrollFromEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePaperlessDocumentsEnrollFromEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaperlessDocumentsEnrollFromEmailMutation,
    PaperlessDocumentsEnrollFromEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaperlessDocumentsEnrollFromEmailMutation,
    PaperlessDocumentsEnrollFromEmailMutationVariables
  >(PaperlessDocumentsEnrollFromEmailDocument, options);
}
export type PaperlessDocumentsEnrollFromEmailMutationHookResult = ReturnType<
  typeof usePaperlessDocumentsEnrollFromEmailMutation
>;
export type PaperlessDocumentsEnrollFromEmailMutationResult =
  Apollo.MutationResult<PaperlessDocumentsEnrollFromEmailMutation>;
export type PaperlessDocumentsEnrollFromEmailMutationOptions =
  Apollo.BaseMutationOptions<
    PaperlessDocumentsEnrollFromEmailMutation,
    PaperlessDocumentsEnrollFromEmailMutationVariables
  >;
export const PurchaseCoverageDocument = gql`
  mutation purchaseCoverage(
    $policyID: String!
    $coverageName: CoverageName!
    $protectionValue: Int!
  ) {
    purchaseCoverage(
      policyId: $policyID
      coverageName: $coverageName
      protectionValue: $protectionValue
    ) {
      coverage {
        name
        proratedPrice
        premiumNew
        startDate
        price
      }
      premiumPrevious
      policyRenewal {
        startDate
        endDate
      }
    }
  }
`;
export type PurchaseCoverageMutationFn = Apollo.MutationFunction<
  PurchaseCoverageMutation,
  PurchaseCoverageMutationVariables
>;

/**
 * __usePurchaseCoverageMutation__
 *
 * To run a mutation, you first call `usePurchaseCoverageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseCoverageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseCoverageMutation, { data, loading, error }] = usePurchaseCoverageMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *      coverageName: // value for 'coverageName'
 *      protectionValue: // value for 'protectionValue'
 *   },
 * });
 */
export function usePurchaseCoverageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseCoverageMutation,
    PurchaseCoverageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PurchaseCoverageMutation,
    PurchaseCoverageMutationVariables
  >(PurchaseCoverageDocument, options);
}
export type PurchaseCoverageMutationHookResult = ReturnType<
  typeof usePurchaseCoverageMutation
>;
export type PurchaseCoverageMutationResult =
  Apollo.MutationResult<PurchaseCoverageMutation>;
export type PurchaseCoverageMutationOptions = Apollo.BaseMutationOptions<
  PurchaseCoverageMutation,
  PurchaseCoverageMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation registerUser($userInfo: UserRegistrationInfoInput!) {
    registerUser(userInfo: $userInfo) {
      success
      errors
      errorCode
    }
  }
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const RegisterUserGoogleDocument = gql`
  mutation registerUserGoogle($userInfo: UserRegistrationGoogleInfoInput!) {
    registerUserGoogle(userInfo: $userInfo) {
      success
      errors
      errorCode
    }
  }
`;
export type RegisterUserGoogleMutationFn = Apollo.MutationFunction<
  RegisterUserGoogleMutation,
  RegisterUserGoogleMutationVariables
>;

/**
 * __useRegisterUserGoogleMutation__
 *
 * To run a mutation, you first call `useRegisterUserGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserGoogleMutation, { data, loading, error }] = useRegisterUserGoogleMutation({
 *   variables: {
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useRegisterUserGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserGoogleMutation,
    RegisterUserGoogleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterUserGoogleMutation,
    RegisterUserGoogleMutationVariables
  >(RegisterUserGoogleDocument, options);
}
export type RegisterUserGoogleMutationHookResult = ReturnType<
  typeof useRegisterUserGoogleMutation
>;
export type RegisterUserGoogleMutationResult =
  Apollo.MutationResult<RegisterUserGoogleMutation>;
export type RegisterUserGoogleMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserGoogleMutation,
  RegisterUserGoogleMutationVariables
>;
export const RequestResetDocument = gql`
  mutation requestReset($email: String!) {
    requestReset(email: $email) {
      errors
      success
    }
  }
`;
export type RequestResetMutationFn = Apollo.MutationFunction<
  RequestResetMutation,
  RequestResetMutationVariables
>;

/**
 * __useRequestResetMutation__
 *
 * To run a mutation, you first call `useRequestResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetMutation, { data, loading, error }] = useRequestResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestResetMutation,
    RequestResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestResetMutation,
    RequestResetMutationVariables
  >(RequestResetDocument, options);
}
export type RequestResetMutationHookResult = ReturnType<
  typeof useRequestResetMutation
>;
export type RequestResetMutationResult =
  Apollo.MutationResult<RequestResetMutation>;
export type RequestResetMutationOptions = Apollo.BaseMutationOptions<
  RequestResetMutation,
  RequestResetMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword(
    $token: String!
    $userId: String!
    $password: String!
  ) {
    resetPassword(token: $token, userId: $userId, password: $password) {
      errors
      success
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      userId: // value for 'userId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResetPolicyDocument = gql`
  mutation resetPolicy($policyID: String!) {
    resetPolicy(policyID: $policyID)
  }
`;
export type ResetPolicyMutationFn = Apollo.MutationFunction<
  ResetPolicyMutation,
  ResetPolicyMutationVariables
>;

/**
 * __useResetPolicyMutation__
 *
 * To run a mutation, you first call `useResetPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPolicyMutation, { data, loading, error }] = useResetPolicyMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useResetPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPolicyMutation,
    ResetPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPolicyMutation, ResetPolicyMutationVariables>(
    ResetPolicyDocument,
    options
  );
}
export type ResetPolicyMutationHookResult = ReturnType<
  typeof useResetPolicyMutation
>;
export type ResetPolicyMutationResult =
  Apollo.MutationResult<ResetPolicyMutation>;
export type ResetPolicyMutationOptions = Apollo.BaseMutationOptions<
  ResetPolicyMutation,
  ResetPolicyMutationVariables
>;
export const ReverifyDocument = gql`
  mutation reverify($email: String!) {
    reverify(email: $email) {
      errors
      success
    }
  }
`;
export type ReverifyMutationFn = Apollo.MutationFunction<
  ReverifyMutation,
  ReverifyMutationVariables
>;

/**
 * __useReverifyMutation__
 *
 * To run a mutation, you first call `useReverifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverifyMutation, { data, loading, error }] = useReverifyMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useReverifyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReverifyMutation,
    ReverifyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReverifyMutation, ReverifyMutationVariables>(
    ReverifyDocument,
    options
  );
}
export type ReverifyMutationHookResult = ReturnType<typeof useReverifyMutation>;
export type ReverifyMutationResult = Apollo.MutationResult<ReverifyMutation>;
export type ReverifyMutationOptions = Apollo.BaseMutationOptions<
  ReverifyMutation,
  ReverifyMutationVariables
>;
export const SendPropertyProfileUpdateNotificationDocument = gql`
  mutation sendPropertyProfileUpdateNotification(
    $policyID: String!
    $emailAddress: String
    $username: String
    $endorsementUpdates: EndorsementUpdates!
  ) {
    sendPropertyProfileUpdateNotification(
      policyId: $policyID
      emailAddress: $emailAddress
      username: $username
      endorsementUpdates: $endorsementUpdates
    )
  }
`;
export type SendPropertyProfileUpdateNotificationMutationFn =
  Apollo.MutationFunction<
    SendPropertyProfileUpdateNotificationMutation,
    SendPropertyProfileUpdateNotificationMutationVariables
  >;

/**
 * __useSendPropertyProfileUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useSendPropertyProfileUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPropertyProfileUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPropertyProfileUpdateNotificationMutation, { data, loading, error }] = useSendPropertyProfileUpdateNotificationMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *      emailAddress: // value for 'emailAddress'
 *      username: // value for 'username'
 *      endorsementUpdates: // value for 'endorsementUpdates'
 *   },
 * });
 */
export function useSendPropertyProfileUpdateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPropertyProfileUpdateNotificationMutation,
    SendPropertyProfileUpdateNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPropertyProfileUpdateNotificationMutation,
    SendPropertyProfileUpdateNotificationMutationVariables
  >(SendPropertyProfileUpdateNotificationDocument, options);
}
export type SendPropertyProfileUpdateNotificationMutationHookResult =
  ReturnType<typeof useSendPropertyProfileUpdateNotificationMutation>;
export type SendPropertyProfileUpdateNotificationMutationResult =
  Apollo.MutationResult<SendPropertyProfileUpdateNotificationMutation>;
export type SendPropertyProfileUpdateNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    SendPropertyProfileUpdateNotificationMutation,
    SendPropertyProfileUpdateNotificationMutationVariables
  >;
export const SubmitFnolDocument = gql`
  mutation submitFNOL($event: JSON!) {
    fnolChannelSubmission(event: $event)
  }
`;
export type SubmitFnolMutationFn = Apollo.MutationFunction<
  SubmitFnolMutation,
  SubmitFnolMutationVariables
>;

/**
 * __useSubmitFnolMutation__
 *
 * To run a mutation, you first call `useSubmitFnolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFnolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFnolMutation, { data, loading, error }] = useSubmitFnolMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useSubmitFnolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitFnolMutation,
    SubmitFnolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitFnolMutation, SubmitFnolMutationVariables>(
    SubmitFnolDocument,
    options
  );
}
export type SubmitFnolMutationHookResult = ReturnType<
  typeof useSubmitFnolMutation
>;
export type SubmitFnolMutationResult =
  Apollo.MutationResult<SubmitFnolMutation>;
export type SubmitFnolMutationOptions = Apollo.BaseMutationOptions<
  SubmitFnolMutation,
  SubmitFnolMutationVariables
>;
export const UnlinkPolicyDocument = gql`
  mutation unlinkPolicy($email: String!, $policyId: String!) {
    unlinkPolicy(email: $email, policyId: $policyId) {
      success
      errors
      errorCode
    }
  }
`;
export type UnlinkPolicyMutationFn = Apollo.MutationFunction<
  UnlinkPolicyMutation,
  UnlinkPolicyMutationVariables
>;

/**
 * __useUnlinkPolicyMutation__
 *
 * To run a mutation, you first call `useUnlinkPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkPolicyMutation, { data, loading, error }] = useUnlinkPolicyMutation({
 *   variables: {
 *      email: // value for 'email'
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useUnlinkPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkPolicyMutation,
    UnlinkPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkPolicyMutation,
    UnlinkPolicyMutationVariables
  >(UnlinkPolicyDocument, options);
}
export type UnlinkPolicyMutationHookResult = ReturnType<
  typeof useUnlinkPolicyMutation
>;
export type UnlinkPolicyMutationResult =
  Apollo.MutationResult<UnlinkPolicyMutation>;
export type UnlinkPolicyMutationOptions = Apollo.BaseMutationOptions<
  UnlinkPolicyMutation,
  UnlinkPolicyMutationVariables
>;
export const UpdateDataDocument = gql`
  mutation updateData($policyID: String!) {
    updateData(policyID: $policyID)
  }
`;
export type UpdateDataMutationFn = Apollo.MutationFunction<
  UpdateDataMutation,
  UpdateDataMutationVariables
>;

/**
 * __useUpdateDataMutation__
 *
 * To run a mutation, you first call `useUpdateDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataMutation, { data, loading, error }] = useUpdateDataMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUpdateDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDataMutation,
    UpdateDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDataMutation, UpdateDataMutationVariables>(
    UpdateDataDocument,
    options
  );
}
export type UpdateDataMutationHookResult = ReturnType<
  typeof useUpdateDataMutation
>;
export type UpdateDataMutationResult =
  Apollo.MutationResult<UpdateDataMutation>;
export type UpdateDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateDataMutation,
  UpdateDataMutationVariables
>;
export const UpdateInsuredInfoDocument = gql`
  mutation updateInsuredInfo(
    $policyID: String!
    $emailAddress: String
    $username: String
    $userUpdates: InsuredUpdateables!
  ) {
    updateInsuredInfo(
      policyId: $policyID
      emailAddress: $emailAddress
      username: $username
      userUpdates: $userUpdates
    )
  }
`;
export type UpdateInsuredInfoMutationFn = Apollo.MutationFunction<
  UpdateInsuredInfoMutation,
  UpdateInsuredInfoMutationVariables
>;

/**
 * __useUpdateInsuredInfoMutation__
 *
 * To run a mutation, you first call `useUpdateInsuredInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsuredInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuredInfoMutation, { data, loading, error }] = useUpdateInsuredInfoMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *      emailAddress: // value for 'emailAddress'
 *      username: // value for 'username'
 *      userUpdates: // value for 'userUpdates'
 *   },
 * });
 */
export function useUpdateInsuredInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInsuredInfoMutation,
    UpdateInsuredInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInsuredInfoMutation,
    UpdateInsuredInfoMutationVariables
  >(UpdateInsuredInfoDocument, options);
}
export type UpdateInsuredInfoMutationHookResult = ReturnType<
  typeof useUpdateInsuredInfoMutation
>;
export type UpdateInsuredInfoMutationResult =
  Apollo.MutationResult<UpdateInsuredInfoMutation>;
export type UpdateInsuredInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateInsuredInfoMutation,
  UpdateInsuredInfoMutationVariables
>;
export const UpdateMortgageeDocument = gql`
  mutation updateMortgagee(
    $mortgagee: MortgageeInput!
    $policyId: String!
    $mortgageeIndex: Int!
    $readablePolicyId: String!
  ) {
    updateMortgagee(
      mortgagee: $mortgagee
      policyId: $policyId
      mortgageeIndex: $mortgageeIndex
      readablePolicyId: $readablePolicyId
    ) {
      name
      loanNumber
      street1
      street2
      city
      state
      zip
      index
    }
  }
`;
export type UpdateMortgageeMutationFn = Apollo.MutationFunction<
  UpdateMortgageeMutation,
  UpdateMortgageeMutationVariables
>;

/**
 * __useUpdateMortgageeMutation__
 *
 * To run a mutation, you first call `useUpdateMortgageeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMortgageeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMortgageeMutation, { data, loading, error }] = useUpdateMortgageeMutation({
 *   variables: {
 *      mortgagee: // value for 'mortgagee'
 *      policyId: // value for 'policyId'
 *      mortgageeIndex: // value for 'mortgageeIndex'
 *      readablePolicyId: // value for 'readablePolicyId'
 *   },
 * });
 */
export function useUpdateMortgageeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMortgageeMutation,
    UpdateMortgageeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMortgageeMutation,
    UpdateMortgageeMutationVariables
  >(UpdateMortgageeDocument, options);
}
export type UpdateMortgageeMutationHookResult = ReturnType<
  typeof useUpdateMortgageeMutation
>;
export type UpdateMortgageeMutationResult =
  Apollo.MutationResult<UpdateMortgageeMutation>;
export type UpdateMortgageeMutationOptions = Apollo.BaseMutationOptions<
  UpdateMortgageeMutation,
  UpdateMortgageeMutationVariables
>;
export const UpdatePaperlessDocumentsEmailDocument = gql`
  mutation updatePaperlessDocumentsEmail(
    $opInsuredEmailAddressDocuments: String!
    $policyID: String!
  ) {
    updatePaperlessDocumentsEmail(
      opInsuredEmailAddressDocuments: $opInsuredEmailAddressDocuments
      policyID: $policyID
    )
  }
`;
export type UpdatePaperlessDocumentsEmailMutationFn = Apollo.MutationFunction<
  UpdatePaperlessDocumentsEmailMutation,
  UpdatePaperlessDocumentsEmailMutationVariables
>;

/**
 * __useUpdatePaperlessDocumentsEmailMutation__
 *
 * To run a mutation, you first call `useUpdatePaperlessDocumentsEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaperlessDocumentsEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaperlessDocumentsEmailMutation, { data, loading, error }] = useUpdatePaperlessDocumentsEmailMutation({
 *   variables: {
 *      opInsuredEmailAddressDocuments: // value for 'opInsuredEmailAddressDocuments'
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUpdatePaperlessDocumentsEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaperlessDocumentsEmailMutation,
    UpdatePaperlessDocumentsEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaperlessDocumentsEmailMutation,
    UpdatePaperlessDocumentsEmailMutationVariables
  >(UpdatePaperlessDocumentsEmailDocument, options);
}
export type UpdatePaperlessDocumentsEmailMutationHookResult = ReturnType<
  typeof useUpdatePaperlessDocumentsEmailMutation
>;
export type UpdatePaperlessDocumentsEmailMutationResult =
  Apollo.MutationResult<UpdatePaperlessDocumentsEmailMutation>;
export type UpdatePaperlessDocumentsEmailMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePaperlessDocumentsEmailMutation,
    UpdatePaperlessDocumentsEmailMutationVariables
  >;
export const UpdatePaperlessEmailDocument = gql`
  mutation updatePaperlessEmail(
    $opInsuredEmailAddressBilling: String!
    $policyID: String!
  ) {
    updatePaperlessEmail(
      opInsuredEmailAddressBilling: $opInsuredEmailAddressBilling
      policyID: $policyID
    )
  }
`;
export type UpdatePaperlessEmailMutationFn = Apollo.MutationFunction<
  UpdatePaperlessEmailMutation,
  UpdatePaperlessEmailMutationVariables
>;

/**
 * __useUpdatePaperlessEmailMutation__
 *
 * To run a mutation, you first call `useUpdatePaperlessEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaperlessEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaperlessEmailMutation, { data, loading, error }] = useUpdatePaperlessEmailMutation({
 *   variables: {
 *      opInsuredEmailAddressBilling: // value for 'opInsuredEmailAddressBilling'
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUpdatePaperlessEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaperlessEmailMutation,
    UpdatePaperlessEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaperlessEmailMutation,
    UpdatePaperlessEmailMutationVariables
  >(UpdatePaperlessEmailDocument, options);
}
export type UpdatePaperlessEmailMutationHookResult = ReturnType<
  typeof useUpdatePaperlessEmailMutation
>;
export type UpdatePaperlessEmailMutationResult =
  Apollo.MutationResult<UpdatePaperlessEmailMutation>;
export type UpdatePaperlessEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaperlessEmailMutation,
  UpdatePaperlessEmailMutationVariables
>;
export const UpdatePolicyIntervalDataDocument = gql`
  mutation updatePolicyIntervalData(
    $policyID: String!
    $emailAddress: String
    $username: String
    $endorsementUpdates: EndorsementUpdates!
  ) {
    updatePolicyIntervalData(
      policyId: $policyID
      emailAddress: $emailAddress
      username: $username
      endorsementUpdates: $endorsementUpdates
    )
  }
`;
export type UpdatePolicyIntervalDataMutationFn = Apollo.MutationFunction<
  UpdatePolicyIntervalDataMutation,
  UpdatePolicyIntervalDataMutationVariables
>;

/**
 * __useUpdatePolicyIntervalDataMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyIntervalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyIntervalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyIntervalDataMutation, { data, loading, error }] = useUpdatePolicyIntervalDataMutation({
 *   variables: {
 *      policyID: // value for 'policyID'
 *      emailAddress: // value for 'emailAddress'
 *      username: // value for 'username'
 *      endorsementUpdates: // value for 'endorsementUpdates'
 *   },
 * });
 */
export function useUpdatePolicyIntervalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyIntervalDataMutation,
    UpdatePolicyIntervalDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePolicyIntervalDataMutation,
    UpdatePolicyIntervalDataMutationVariables
  >(UpdatePolicyIntervalDataDocument, options);
}
export type UpdatePolicyIntervalDataMutationHookResult = ReturnType<
  typeof useUpdatePolicyIntervalDataMutation
>;
export type UpdatePolicyIntervalDataMutationResult =
  Apollo.MutationResult<UpdatePolicyIntervalDataMutation>;
export type UpdatePolicyIntervalDataMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePolicyIntervalDataMutation,
    UpdatePolicyIntervalDataMutationVariables
  >;
export const VerifyUserOwnershipDocument = gql`
  mutation verifyUserOwnership($verificationInfo: UserVerificationInfoInput!) {
    verifyUserOwnership(verificationInfo: $verificationInfo) {
      success
      errors
      errorCode
    }
  }
`;
export type VerifyUserOwnershipMutationFn = Apollo.MutationFunction<
  VerifyUserOwnershipMutation,
  VerifyUserOwnershipMutationVariables
>;

/**
 * __useVerifyUserOwnershipMutation__
 *
 * To run a mutation, you first call `useVerifyUserOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserOwnershipMutation, { data, loading, error }] = useVerifyUserOwnershipMutation({
 *   variables: {
 *      verificationInfo: // value for 'verificationInfo'
 *   },
 * });
 */
export function useVerifyUserOwnershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyUserOwnershipMutation,
    VerifyUserOwnershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyUserOwnershipMutation,
    VerifyUserOwnershipMutationVariables
  >(VerifyUserOwnershipDocument, options);
}
export type VerifyUserOwnershipMutationHookResult = ReturnType<
  typeof useVerifyUserOwnershipMutation
>;
export type VerifyUserOwnershipMutationResult =
  Apollo.MutationResult<VerifyUserOwnershipMutation>;
export type VerifyUserOwnershipMutationOptions = Apollo.BaseMutationOptions<
  VerifyUserOwnershipMutation,
  VerifyUserOwnershipMutationVariables
>;
export const CommonPolicyDataDocument = gql`
  query commonPolicyData($policyID: ID!, $email: String!) {
    userBilling(policyID: $policyID) {
      accounting {
        dueDate
        minimumPaymentDue
        invoiceAmountCurrent
        outstandingBalanceDue
        paymentPlan {
          planType
        }
        easyPayAccount {
          lastFourDigits
          paymentMethod
        }
      }
      mortgagees {
        name
        loanNumber
        street1
        street2
        city
        state
        zip
        index
      }
      billingAddress {
        line1
        line2
        line3
      }
      installments {
        amount
        charges
        dueDateMillis
        feesAndPremiums
        invoiceDateMillis
        number
      }
      pendingChanges {
        ScheduledPayments {
          scheduledPaymentList {
            paymentDate
            paymentAmount
            totalScheduledAmount
          }
        }
      }
    }
    userDetails(policyID: $policyID) {
      policyStatus
      propertyAddress {
        firstName
        lastName
        street1
        street2
        city
        state
        zip
      }
      mailingAddress {
        firstName
        lastName
        street1
        street2
        city
        state
        zip
      }
      additionalAddresses {
        firstName
        lastName
        street1
        street2
        city
        state
        zip
      }
      quoteNumber
      currentTerm {
        policyNumber
        agencyLocationCode
        effectiveDate
        expirationDate
        effectiveDatePolicyTerm
        agentName
        totalPremium
        agencyLocationCode
        coverageA
        coverageB
        coverageC
        coverageD
        coverageE
        coverageEPerOccurrence
        coverageEAggregate
        coverageF
        coverageL
        coverageM
        hurricaneDeductible
        hurricaneDeductibleAmount
        allOtherPerilsDeductible
        dbaName
        windHailDeductible
        propertyDamageLiabilityDeductiblePerClaim
        propertyDamageLiabilityDeductiblePerOccurrence
        termLocationData {
          location
          coverageA
          coverageC
          deductibleAmount
          hurricaneDeductible
          windHailDeductible
          allOtherPerilsDeductibleDollarAmount
          allOtherPerilsDeductible
        }
      }
      isCommercial
      isBookRoll
      keystonePolicyId
      mortgageeIndex
      insuredPreferences {
        easyPayEnrollmentStatus
        paperlessBillingEnrollmentStatus
        paperlessDocumentEnrollmentStatus
        opInsuredEmailAddress
        opInsuredEmailAddressBilling
        opInsuredEmailAddressDocuments
        opInsuredContactFirstName
        opInsuredContactLastName
      }
      primaryInsured {
        name
        emailAddress
      }
      coApplicant {
        firstName
        lastName
      }
      isEnrolledInLeakBot
      policyStateAttributes {
        reasonCode {
          description
          label
          value
        }
        effectiveDate
      }
    }
    userDocuments(policyID: $policyID) {
      grouped {
        group
        href
        label
        lastUpdated
        location
        route
        templateId
      }
      latest {
        declaration {
          group
          href
          label
          lastUpdated
          location
          route
          templateId
        }
        invoice {
          group
          href
          label
          lastUpdated
          location
          route
          templateId
        }
      }
    }
    userBasicInfo(email: $email) {
      email
      firstName
      lastName
      userName
      authProvider
    }
    userInsuranceRep(policyID: $policyID) {
      agencyName
      agentStreetNumber
      agentStreetName
      agentCity
      agentState
      agentZipCode
      agentPhone
      agentEmail
    }
    userPolicyProperties(policyID: $policyID) {
      carrierGroup
      policyType
      agencyLocationCode
      policyState
    }
    userCarrierInfo(policyID: $policyID) {
      name
      nameShort
      id
      logo
      email
      website
      phone
      hours
      fax
    }
  }
`;

/**
 * __useCommonPolicyDataQuery__
 *
 * To run a query within a React component, call `useCommonPolicyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonPolicyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonPolicyDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCommonPolicyDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    CommonPolicyDataQuery,
    CommonPolicyDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommonPolicyDataQuery, CommonPolicyDataQueryVariables>(
    CommonPolicyDataDocument,
    options
  );
}
export function useCommonPolicyDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommonPolicyDataQuery,
    CommonPolicyDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CommonPolicyDataQuery,
    CommonPolicyDataQueryVariables
  >(CommonPolicyDataDocument, options);
}
export type CommonPolicyDataQueryHookResult = ReturnType<
  typeof useCommonPolicyDataQuery
>;
export type CommonPolicyDataLazyQueryHookResult = ReturnType<
  typeof useCommonPolicyDataLazyQuery
>;
export type CommonPolicyDataQueryResult = Apollo.QueryResult<
  CommonPolicyDataQuery,
  CommonPolicyDataQueryVariables
>;
export const ContactItemDataDocument = gql`
  query contactItemData($policyID: ID!) {
    userDetails(policyID: $policyID) {
      primaryInsured {
        emailAddress
      }
      mailingAddress {
        street1
        street2
        city
        state
        zip
      }
      insuredPreferences {
        paperlessDocumentEnrollmentStatus
        paperlessBillingEnrollmentStatus
        opInsuredEmailAddressDocuments
        opInsuredEmailAddress
      }
      isCommercial
    }
    userClaimsConstants(policyID: $policyID) {
      PolicyHolderDetails {
        phoneNumber
      }
    }
    userBillingAddress(policyID: $policyID)
    userBilling(policyID: $policyID) {
      accounting {
        paymentPlan {
          planType
        }
      }
    }
    userPolicyProperties(policyID: $policyID) {
      carrierGroup
    }
  }
`;

/**
 * __useContactItemDataQuery__
 *
 * To run a query within a React component, call `useContactItemDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactItemDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactItemDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useContactItemDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactItemDataQuery,
    ContactItemDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactItemDataQuery, ContactItemDataQueryVariables>(
    ContactItemDataDocument,
    options
  );
}
export function useContactItemDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactItemDataQuery,
    ContactItemDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactItemDataQuery,
    ContactItemDataQueryVariables
  >(ContactItemDataDocument, options);
}
export type ContactItemDataQueryHookResult = ReturnType<
  typeof useContactItemDataQuery
>;
export type ContactItemDataLazyQueryHookResult = ReturnType<
  typeof useContactItemDataLazyQuery
>;
export type ContactItemDataQueryResult = Apollo.QueryResult<
  ContactItemDataQuery,
  ContactItemDataQueryVariables
>;
export const DocusignStatusDocument = gql`
  query docusignStatus($insightPolicyId: String!) {
    docusignStatus(insightPolicyId: $insightPolicyId) {
      documentType
      id
      documentStatus
      createdDateTime
      signers {
        name
        recipientType
        status
      }
    }
  }
`;

/**
 * __useDocusignStatusQuery__
 *
 * To run a query within a React component, call `useDocusignStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocusignStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocusignStatusQuery({
 *   variables: {
 *      insightPolicyId: // value for 'insightPolicyId'
 *   },
 * });
 */
export function useDocusignStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    DocusignStatusQuery,
    DocusignStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocusignStatusQuery, DocusignStatusQueryVariables>(
    DocusignStatusDocument,
    options
  );
}
export function useDocusignStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocusignStatusQuery,
    DocusignStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocusignStatusQuery, DocusignStatusQueryVariables>(
    DocusignStatusDocument,
    options
  );
}
export type DocusignStatusQueryHookResult = ReturnType<
  typeof useDocusignStatusQuery
>;
export type DocusignStatusLazyQueryHookResult = ReturnType<
  typeof useDocusignStatusLazyQuery
>;
export type DocusignStatusQueryResult = Apollo.QueryResult<
  DocusignStatusQuery,
  DocusignStatusQueryVariables
>;
export const EasypayProcessingFeesDocument = gql`
  query easypayProcessingFees($policyID: ID!) {
    easypayProcessingFees(policyID: $policyID)
  }
`;

/**
 * __useEasypayProcessingFeesQuery__
 *
 * To run a query within a React component, call `useEasypayProcessingFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEasypayProcessingFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEasypayProcessingFeesQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useEasypayProcessingFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    EasypayProcessingFeesQuery,
    EasypayProcessingFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EasypayProcessingFeesQuery,
    EasypayProcessingFeesQueryVariables
  >(EasypayProcessingFeesDocument, options);
}
export function useEasypayProcessingFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EasypayProcessingFeesQuery,
    EasypayProcessingFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EasypayProcessingFeesQuery,
    EasypayProcessingFeesQueryVariables
  >(EasypayProcessingFeesDocument, options);
}
export type EasypayProcessingFeesQueryHookResult = ReturnType<
  typeof useEasypayProcessingFeesQuery
>;
export type EasypayProcessingFeesLazyQueryHookResult = ReturnType<
  typeof useEasypayProcessingFeesLazyQuery
>;
export type EasypayProcessingFeesQueryResult = Apollo.QueryResult<
  EasypayProcessingFeesQuery,
  EasypayProcessingFeesQueryVariables
>;
export const GetEligibleCoveragesDocument = gql`
  query getEligibleCoverages($policyID: String!) {
    getEligibleCoverages(policyId: $policyID) {
      coverages {
        name
        protectionValue
        eligibility
        price
        premiumNew
        proratedPrice
        startDate
      }
    }
  }
`;

/**
 * __useGetEligibleCoveragesQuery__
 *
 * To run a query within a React component, call `useGetEligibleCoveragesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibleCoveragesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibleCoveragesQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useGetEligibleCoveragesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEligibleCoveragesQuery,
    GetEligibleCoveragesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEligibleCoveragesQuery,
    GetEligibleCoveragesQueryVariables
  >(GetEligibleCoveragesDocument, options);
}
export function useGetEligibleCoveragesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEligibleCoveragesQuery,
    GetEligibleCoveragesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEligibleCoveragesQuery,
    GetEligibleCoveragesQueryVariables
  >(GetEligibleCoveragesDocument, options);
}
export type GetEligibleCoveragesQueryHookResult = ReturnType<
  typeof useGetEligibleCoveragesQuery
>;
export type GetEligibleCoveragesLazyQueryHookResult = ReturnType<
  typeof useGetEligibleCoveragesLazyQuery
>;
export type GetEligibleCoveragesQueryResult = Apollo.QueryResult<
  GetEligibleCoveragesQuery,
  GetEligibleCoveragesQueryVariables
>;
export const GetPolicyDocument = gql`
  query getPolicy($id: String!) {
    getPolicy(id: $id) {
      pendingCancellationDate
      inspection {
        status
        completed
        completedDate
        dispositionCodeEnum
        dispositionCode
        statusEnum
        orderDate
        surveyDate
        underwritingUpdatedDate
        previewUrl
        vendorName
        confirmationNumber
        newBusinessInspectionWriteOuts {
          dueDate
          vendorName
          projectId
          referralList {
            underwritingAction
            resolved
            underwritingCondition {
              referralCode
              referralText
              category
              actionStatement
              issueStatements
              reasoning
              proof
              contractor
              serviceProvider
              searchTerm
            }
            referralActionRequired
            location
            referralCategory
          }
          images {
            id
            baseUrl
            thumbnailUrl
            notes
          }
        }
        renewalWriteOuts {
          projectId
          bees360ReferralList {
            underwritingAction
            resolved
            underwritingCondition {
              referralCode
              referralText
              category
              actionStatement
              issueStatements
              reasoning
              proof
              contractor
              serviceProvider
              searchTerm
            }
            referralActionRequired
            location
            referralCategory
            factors {
              name
              factorDataList {
                text
                direction
                images
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPolicyQuery__
 *
 * To run a query within a React component, call `useGetPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<GetPolicyQuery, GetPolicyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyQuery, GetPolicyQueryVariables>(
    GetPolicyDocument,
    options
  );
}
export function useGetPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyQuery,
    GetPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyQuery, GetPolicyQueryVariables>(
    GetPolicyDocument,
    options
  );
}
export type GetPolicyQueryHookResult = ReturnType<typeof useGetPolicyQuery>;
export type GetPolicyLazyQueryHookResult = ReturnType<
  typeof useGetPolicyLazyQuery
>;
export type GetPolicyQueryResult = Apollo.QueryResult<
  GetPolicyQuery,
  GetPolicyQueryVariables
>;
export const IsLoggedInDocument = gql`
  query isLoggedIn {
    isLoggedIn {
      loggedIn
    }
  }
`;

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(
    IsLoggedInDocument,
    options
  );
}
export function useIsLoggedInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(
    IsLoggedInDocument,
    options
  );
}
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>;
export type IsLoggedInLazyQueryHookResult = ReturnType<
  typeof useIsLoggedInLazyQuery
>;
export type IsLoggedInQueryResult = Apollo.QueryResult<
  IsLoggedInQuery,
  IsLoggedInQueryVariables
>;
export const OptionalCoveragesDocument = gql`
  query optionalCoverages($policyID: String!) {
    getEligibleCoverages(policyId: $policyID) {
      coverages {
        name
        protectionValue
        eligibility
        price
        premiumNew
        proratedPrice
        startDate
      }
    }
  }
`;

/**
 * __useOptionalCoveragesQuery__
 *
 * To run a query within a React component, call `useOptionalCoveragesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionalCoveragesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionalCoveragesQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useOptionalCoveragesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OptionalCoveragesQuery,
    OptionalCoveragesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OptionalCoveragesQuery,
    OptionalCoveragesQueryVariables
  >(OptionalCoveragesDocument, options);
}
export function useOptionalCoveragesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OptionalCoveragesQuery,
    OptionalCoveragesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OptionalCoveragesQuery,
    OptionalCoveragesQueryVariables
  >(OptionalCoveragesDocument, options);
}
export type OptionalCoveragesQueryHookResult = ReturnType<
  typeof useOptionalCoveragesQuery
>;
export type OptionalCoveragesLazyQueryHookResult = ReturnType<
  typeof useOptionalCoveragesLazyQuery
>;
export type OptionalCoveragesQueryResult = Apollo.QueryResult<
  OptionalCoveragesQuery,
  OptionalCoveragesQueryVariables
>;
export const PaperlessEnrollmentStatusDocument = gql`
  query paperlessEnrollmentStatus($policyID: ID!) {
    paperlessEnrollmentStatus(policyID: $policyID)
  }
`;

/**
 * __usePaperlessEnrollmentStatusQuery__
 *
 * To run a query within a React component, call `usePaperlessEnrollmentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaperlessEnrollmentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaperlessEnrollmentStatusQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function usePaperlessEnrollmentStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaperlessEnrollmentStatusQuery,
    PaperlessEnrollmentStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaperlessEnrollmentStatusQuery,
    PaperlessEnrollmentStatusQueryVariables
  >(PaperlessEnrollmentStatusDocument, options);
}
export function usePaperlessEnrollmentStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaperlessEnrollmentStatusQuery,
    PaperlessEnrollmentStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaperlessEnrollmentStatusQuery,
    PaperlessEnrollmentStatusQueryVariables
  >(PaperlessEnrollmentStatusDocument, options);
}
export type PaperlessEnrollmentStatusQueryHookResult = ReturnType<
  typeof usePaperlessEnrollmentStatusQuery
>;
export type PaperlessEnrollmentStatusLazyQueryHookResult = ReturnType<
  typeof usePaperlessEnrollmentStatusLazyQuery
>;
export type PaperlessEnrollmentStatusQueryResult = Apollo.QueryResult<
  PaperlessEnrollmentStatusQuery,
  PaperlessEnrollmentStatusQueryVariables
>;
export const PoaSignatureStatusDocument = gql`
  query poaSignatureStatus($insightPolicyId: String!) {
    getPoaSignatureStatus(insightPolicyId: $insightPolicyId) {
      documentType
      documentStatus
      embeddedSigningUrl
      id
      emailSubject
      apiUserId
    }
  }
`;

/**
 * __usePoaSignatureStatusQuery__
 *
 * To run a query within a React component, call `usePoaSignatureStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoaSignatureStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoaSignatureStatusQuery({
 *   variables: {
 *      insightPolicyId: // value for 'insightPolicyId'
 *   },
 * });
 */
export function usePoaSignatureStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    PoaSignatureStatusQuery,
    PoaSignatureStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PoaSignatureStatusQuery,
    PoaSignatureStatusQueryVariables
  >(PoaSignatureStatusDocument, options);
}
export function usePoaSignatureStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PoaSignatureStatusQuery,
    PoaSignatureStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PoaSignatureStatusQuery,
    PoaSignatureStatusQueryVariables
  >(PoaSignatureStatusDocument, options);
}
export type PoaSignatureStatusQueryHookResult = ReturnType<
  typeof usePoaSignatureStatusQuery
>;
export type PoaSignatureStatusLazyQueryHookResult = ReturnType<
  typeof usePoaSignatureStatusLazyQuery
>;
export type PoaSignatureStatusQueryResult = Apollo.QueryResult<
  PoaSignatureStatusQuery,
  PoaSignatureStatusQueryVariables
>;
export const PolicyPaperlessDataDocument = gql`
  query policyPaperlessData($policyID: ID!) {
    userDetails(policyID: $policyID) {
      insuredPreferences {
        paperlessDocumentEnrollmentStatus
        paperlessBillingEnrollmentStatus
        opInsuredEmailAddressDocuments
        opInsuredEmailAddress
      }
      isCommercial
    }
    userBillingAddress(policyID: $policyID)
    userBilling(policyID: $policyID) {
      accounting {
        paymentPlan {
          planType
        }
      }
    }
  }
`;

/**
 * __usePolicyPaperlessDataQuery__
 *
 * To run a query within a React component, call `usePolicyPaperlessDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyPaperlessDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyPaperlessDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function usePolicyPaperlessDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    PolicyPaperlessDataQuery,
    PolicyPaperlessDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PolicyPaperlessDataQuery,
    PolicyPaperlessDataQueryVariables
  >(PolicyPaperlessDataDocument, options);
}
export function usePolicyPaperlessDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PolicyPaperlessDataQuery,
    PolicyPaperlessDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PolicyPaperlessDataQuery,
    PolicyPaperlessDataQueryVariables
  >(PolicyPaperlessDataDocument, options);
}
export type PolicyPaperlessDataQueryHookResult = ReturnType<
  typeof usePolicyPaperlessDataQuery
>;
export type PolicyPaperlessDataLazyQueryHookResult = ReturnType<
  typeof usePolicyPaperlessDataLazyQuery
>;
export type PolicyPaperlessDataQueryResult = Apollo.QueryResult<
  PolicyPaperlessDataQuery,
  PolicyPaperlessDataQueryVariables
>;
export const PolicyPaymentDataDocument = gql`
  query policyPaymentData($policyID: ID!) {
    userDetails(policyID: $policyID) {
      insuredPreferences {
        easyPayEnrollmentStatus
      }
      keystonePolicyId
      policyStatus
    }
    userBillingAddress(policyID: $policyID)
    userBilling(policyID: $policyID) {
      accounting {
        paymentPlan {
          planType
        }
      }
      mortgagees {
        name
        loanNumber
        street1
        street2
        city
        state
        zip
        index
      }
    }
  }
`;

/**
 * __usePolicyPaymentDataQuery__
 *
 * To run a query within a React component, call `usePolicyPaymentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyPaymentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyPaymentDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function usePolicyPaymentDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    PolicyPaymentDataQuery,
    PolicyPaymentDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PolicyPaymentDataQuery,
    PolicyPaymentDataQueryVariables
  >(PolicyPaymentDataDocument, options);
}
export function usePolicyPaymentDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PolicyPaymentDataQuery,
    PolicyPaymentDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PolicyPaymentDataQuery,
    PolicyPaymentDataQueryVariables
  >(PolicyPaymentDataDocument, options);
}
export type PolicyPaymentDataQueryHookResult = ReturnType<
  typeof usePolicyPaymentDataQuery
>;
export type PolicyPaymentDataLazyQueryHookResult = ReturnType<
  typeof usePolicyPaymentDataLazyQuery
>;
export type PolicyPaymentDataQueryResult = Apollo.QueryResult<
  PolicyPaymentDataQuery,
  PolicyPaymentDataQueryVariables
>;
export const PolicyPaymentPlansDocument = gql`
  query policyPaymentPlans($policyID: ID!) {
    policyPaymentPlans(policyID: $policyID) {
      planId
      downPayment
      installmentAmount
      paymentPlanSetupFee
      installmentCharge
      numberOfPayments
      billingMethod
    }
  }
`;

/**
 * __usePolicyPaymentPlansQuery__
 *
 * To run a query within a React component, call `usePolicyPaymentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyPaymentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyPaymentPlansQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function usePolicyPaymentPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    PolicyPaymentPlansQuery,
    PolicyPaymentPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PolicyPaymentPlansQuery,
    PolicyPaymentPlansQueryVariables
  >(PolicyPaymentPlansDocument, options);
}
export function usePolicyPaymentPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PolicyPaymentPlansQuery,
    PolicyPaymentPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PolicyPaymentPlansQuery,
    PolicyPaymentPlansQueryVariables
  >(PolicyPaymentPlansDocument, options);
}
export type PolicyPaymentPlansQueryHookResult = ReturnType<
  typeof usePolicyPaymentPlansQuery
>;
export type PolicyPaymentPlansLazyQueryHookResult = ReturnType<
  typeof usePolicyPaymentPlansLazyQuery
>;
export type PolicyPaymentPlansQueryResult = Apollo.QueryResult<
  PolicyPaymentPlansQuery,
  PolicyPaymentPlansQueryVariables
>;
export const ServiceFeesDocument = gql`
  query serviceFees($policyID: ID!) {
    serviceFees(policyID: $policyID)
  }
`;

/**
 * __useServiceFeesQuery__
 *
 * To run a query within a React component, call `useServiceFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceFeesQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useServiceFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceFeesQuery,
    ServiceFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceFeesQuery, ServiceFeesQueryVariables>(
    ServiceFeesDocument,
    options
  );
}
export function useServiceFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceFeesQuery,
    ServiceFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceFeesQuery, ServiceFeesQueryVariables>(
    ServiceFeesDocument,
    options
  );
}
export type ServiceFeesQueryHookResult = ReturnType<typeof useServiceFeesQuery>;
export type ServiceFeesLazyQueryHookResult = ReturnType<
  typeof useServiceFeesLazyQuery
>;
export type ServiceFeesQueryResult = Apollo.QueryResult<
  ServiceFeesQuery,
  ServiceFeesQueryVariables
>;
export const LegacyUserDataDocument = gql`
  query legacyUserData($policyID: ID!) {
    isLoggedIn {
      loggedIn
    }
    userInfo(policyID: $policyID) {
      email
      firstName
      lastName
      userName
      authProvider
    }
  }
`;

/**
 * __useLegacyUserDataQuery__
 *
 * To run a query within a React component, call `useLegacyUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyUserDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useLegacyUserDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LegacyUserDataQuery,
    LegacyUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegacyUserDataQuery, LegacyUserDataQueryVariables>(
    LegacyUserDataDocument,
    options
  );
}
export function useLegacyUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegacyUserDataQuery,
    LegacyUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegacyUserDataQuery, LegacyUserDataQueryVariables>(
    LegacyUserDataDocument,
    options
  );
}
export type LegacyUserDataQueryHookResult = ReturnType<
  typeof useLegacyUserDataQuery
>;
export type LegacyUserDataLazyQueryHookResult = ReturnType<
  typeof useLegacyUserDataLazyQuery
>;
export type LegacyUserDataQueryResult = Apollo.QueryResult<
  LegacyUserDataQuery,
  LegacyUserDataQueryVariables
>;
export const UserAdditionalCoveragesDocument = gql`
  query userAdditionalCoverages($policyID: ID!) {
    userAdditionalCoverages(policyID: $policyID) {
      identityFraudCoverage
      identityFraudCoveragePremium
      identityFraudIncreaseLimit
      identityFraudTotalLimit
      mechanicalBreakdownCoverage
      mechanicalBreakdownCoveragePremium
      mechanicalElectricalEquipmentGroundLevel
      serviceLineCoverage
      serviceLineDeductible
      serviceLinePremium
    }
  }
`;

/**
 * __useUserAdditionalCoveragesQuery__
 *
 * To run a query within a React component, call `useUserAdditionalCoveragesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAdditionalCoveragesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAdditionalCoveragesQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUserAdditionalCoveragesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAdditionalCoveragesQuery,
    UserAdditionalCoveragesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserAdditionalCoveragesQuery,
    UserAdditionalCoveragesQueryVariables
  >(UserAdditionalCoveragesDocument, options);
}
export function useUserAdditionalCoveragesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAdditionalCoveragesQuery,
    UserAdditionalCoveragesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserAdditionalCoveragesQuery,
    UserAdditionalCoveragesQueryVariables
  >(UserAdditionalCoveragesDocument, options);
}
export type UserAdditionalCoveragesQueryHookResult = ReturnType<
  typeof useUserAdditionalCoveragesQuery
>;
export type UserAdditionalCoveragesLazyQueryHookResult = ReturnType<
  typeof useUserAdditionalCoveragesLazyQuery
>;
export type UserAdditionalCoveragesQueryResult = Apollo.QueryResult<
  UserAdditionalCoveragesQuery,
  UserAdditionalCoveragesQueryVariables
>;
export const UserBillingAddressDocument = gql`
  query userBillingAddress($policyID: ID!) {
    userBillingAddress(policyID: $policyID)
  }
`;

/**
 * __useUserBillingAddressQuery__
 *
 * To run a query within a React component, call `useUserBillingAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBillingAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBillingAddressQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUserBillingAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserBillingAddressQuery,
    UserBillingAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserBillingAddressQuery,
    UserBillingAddressQueryVariables
  >(UserBillingAddressDocument, options);
}
export function useUserBillingAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserBillingAddressQuery,
    UserBillingAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserBillingAddressQuery,
    UserBillingAddressQueryVariables
  >(UserBillingAddressDocument, options);
}
export type UserBillingAddressQueryHookResult = ReturnType<
  typeof useUserBillingAddressQuery
>;
export type UserBillingAddressLazyQueryHookResult = ReturnType<
  typeof useUserBillingAddressLazyQuery
>;
export type UserBillingAddressQueryResult = Apollo.QueryResult<
  UserBillingAddressQuery,
  UserBillingAddressQueryVariables
>;
export const UserClaimsConstantsDocument = gql`
  query userClaimsConstants($policyID: ID!) {
    userClaimsConstants(policyID: $policyID) {
      LossTypes
      RelationshipToPolicyHolderTypes
      RelationshipToCommercialPolicyHolderTypes
      PrimaryPhoneTypes
      SecondaryPhoneTypes
      CarrierDetails {
        claimsActivityInitialText
        carrierDetailsText
        carrierLogo
        claimsFormReviewText
        claimsReviewText
        claimsContactCarrierText
        claimsReceiveCarrierCallText
      }
      PolicyHolderDetails {
        name
        emailAddress
        phoneNumber
      }
    }
  }
`;

/**
 * __useUserClaimsConstantsQuery__
 *
 * To run a query within a React component, call `useUserClaimsConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserClaimsConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserClaimsConstantsQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUserClaimsConstantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserClaimsConstantsQuery,
    UserClaimsConstantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserClaimsConstantsQuery,
    UserClaimsConstantsQueryVariables
  >(UserClaimsConstantsDocument, options);
}
export function useUserClaimsConstantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserClaimsConstantsQuery,
    UserClaimsConstantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserClaimsConstantsQuery,
    UserClaimsConstantsQueryVariables
  >(UserClaimsConstantsDocument, options);
}
export type UserClaimsConstantsQueryHookResult = ReturnType<
  typeof useUserClaimsConstantsQuery
>;
export type UserClaimsConstantsLazyQueryHookResult = ReturnType<
  typeof useUserClaimsConstantsLazyQuery
>;
export type UserClaimsConstantsQueryResult = Apollo.QueryResult<
  UserClaimsConstantsQuery,
  UserClaimsConstantsQueryVariables
>;
export const UserClaimsListDocument = gql`
  query userClaimsList($policyID: ID!) {
    userClaimsList(policyID: $policyID) {
      PolicyNumber
      InsuredName
      ClaimsTrackerInfo {
        Status
        CarrierClaim {
          DateSubmitted
          Number
          DateOfLoss
          TypeOfLoss
          CustomerContactDate
          ClosedDate
        }
        FNOLSubmission {
          SubmissionNumber
          DateOfLoss
          DateReceived
          LossAtInsuredProperty
          LocationOfLoss {
            Address
            AddressLine2
            City
            State
            Zip
          }
          TypeOfLoss
          LossDescription
          ClaimSubmittedByPolicyholder
          ClaimSubmittedBy {
            Name
            Relationship
            PrimaryPhoneNumber
            PrimaryPhoneNumberType
            AlternatePhoneNumber
            AlternatePhoneNumberType
          }
          ContactInfo {
            Name
            Relationship
            Mobile
            Email
            PrimaryPhoneNumber
            PrimaryPhoneNumberType
            AlternatePhoneNumber
            AlternatePhoneNumberType
            WantsToReceiveSMSUpdates
          }
        }
        Inspection {
          DateInspectionCompleted
          DateReportCompleted
        }
        Adjuster {
          DateAssigned
          AdjusterName
          PhoneNumber
          Email
        }
        Payments {
          DateOfPayment
          PaymentAmount
          Coverage
          CheckNumber
          PayableTo
        }
        Milestones {
          ClaimReport {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          AdjusterAssignment {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          CustomerContact {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          PropertyInspection {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          FieldInspectionReport {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          ClaimSettlement {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
        }
        FootNotes {
          Disclaimer {
            Message
            Show
          }
          Weather {
            Message
            Show
          }
        }
      }
      ClaimsInfo {
        ClaimNumber
        DateOfLoss
        DateReceived
        Status
        LossCauseDescription
        ClaimDescription
        IndependentAdjusterName
        IndependentAdjusterPhoneNumber
        SubmissionNumber
        payments {
          paymentAmount
          paymentDate
        }
      }
      SubmissionsInfo {
        SubmissionNumber
        DateOfLoss
        DateReceived
        LossAtInsuredProperty
        LocationOfLoss {
          Address
          AddressLine2
          City
          State
          Zip
        }
        TypeOfLoss
        LossDescription
        ClaimSubmittedByPolicyholder
        ClaimSubmittedBy {
          Name
          Relationship
          PrimaryPhoneNumber
          PrimaryPhoneNumberType
          AlternatePhoneNumber
          AlternatePhoneNumberType
        }
        ContactInfo {
          Name
          Relationship
          Mobile
          Email
          PrimaryPhoneNumber
          PrimaryPhoneNumberType
          AlternatePhoneNumber
          AlternatePhoneNumberType
          WantsToReceiveSMSUpdates
        }
      }
    }
  }
`;

/**
 * __useUserClaimsListQuery__
 *
 * To run a query within a React component, call `useUserClaimsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserClaimsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserClaimsListQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUserClaimsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserClaimsListQuery,
    UserClaimsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserClaimsListQuery, UserClaimsListQueryVariables>(
    UserClaimsListDocument,
    options
  );
}
export function useUserClaimsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserClaimsListQuery,
    UserClaimsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserClaimsListQuery, UserClaimsListQueryVariables>(
    UserClaimsListDocument,
    options
  );
}
export type UserClaimsListQueryHookResult = ReturnType<
  typeof useUserClaimsListQuery
>;
export type UserClaimsListLazyQueryHookResult = ReturnType<
  typeof useUserClaimsListLazyQuery
>;
export type UserClaimsListQueryResult = Apollo.QueryResult<
  UserClaimsListQuery,
  UserClaimsListQueryVariables
>;
export const UserPolicyIDsDocument = gql`
  query userPolicyIDs($email: String!) {
    userPolicies(email: $email) {
      policyId
      propertyAddress {
        street1
        street2
        city
        state
        zip
      }
      isCommercial
      policyState
      insightPolicyId
    }
  }
`;

/**
 * __useUserPolicyIDsQuery__
 *
 * To run a query within a React component, call `useUserPolicyIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPolicyIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPolicyIDsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserPolicyIDsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserPolicyIDsQuery,
    UserPolicyIDsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserPolicyIDsQuery, UserPolicyIDsQueryVariables>(
    UserPolicyIDsDocument,
    options
  );
}
export function useUserPolicyIDsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPolicyIDsQuery,
    UserPolicyIDsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserPolicyIDsQuery, UserPolicyIDsQueryVariables>(
    UserPolicyIDsDocument,
    options
  );
}
export type UserPolicyIDsQueryHookResult = ReturnType<
  typeof useUserPolicyIDsQuery
>;
export type UserPolicyIDsLazyQueryHookResult = ReturnType<
  typeof useUserPolicyIDsLazyQuery
>;
export type UserPolicyIDsQueryResult = Apollo.QueryResult<
  UserPolicyIDsQuery,
  UserPolicyIDsQueryVariables
>;
export const UserPropertyProfileDataDocument = gql`
  query userPropertyProfileData($policyID: ID!) {
    userPropertyProfileData(policyID: $policyID) {
      constructionYearRoof
      poolType
      poolFence
      burglarAlarm
      fireAlarm
      trampoline
      roofCoveringType
      poolFenceAboveGround
      divingBoardOrSlide
      poolCovering
      immovablePoolLadder
      unlockedPoolGate
      propertyUpdateData {
        detailName
        taskStatus
        items {
          termName
          enumerationValue
        }
      }
    }
  }
`;

/**
 * __useUserPropertyProfileDataQuery__
 *
 * To run a query within a React component, call `useUserPropertyProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPropertyProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPropertyProfileDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUserPropertyProfileDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserPropertyProfileDataQuery,
    UserPropertyProfileDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserPropertyProfileDataQuery,
    UserPropertyProfileDataQueryVariables
  >(UserPropertyProfileDataDocument, options);
}
export function useUserPropertyProfileDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPropertyProfileDataQuery,
    UserPropertyProfileDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserPropertyProfileDataQuery,
    UserPropertyProfileDataQueryVariables
  >(UserPropertyProfileDataDocument, options);
}
export type UserPropertyProfileDataQueryHookResult = ReturnType<
  typeof useUserPropertyProfileDataQuery
>;
export type UserPropertyProfileDataLazyQueryHookResult = ReturnType<
  typeof useUserPropertyProfileDataLazyQuery
>;
export type UserPropertyProfileDataQueryResult = Apollo.QueryResult<
  UserPropertyProfileDataQuery,
  UserPropertyProfileDataQueryVariables
>;
export const UserTransactionsDocument = gql`
  query userTransactions($policyID: ID!) {
    userTransactions(policyID: $policyID) {
      transactions {
        type
        description
        amount
        date
        accountNumber
        creditCardType
        method
      }
      latestPayment
      lastPaymentDate
    }
  }
`;

/**
 * __useUserTransactionsQuery__
 *
 * To run a query within a React component, call `useUserTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTransactionsQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useUserTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserTransactionsQuery,
    UserTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserTransactionsQuery, UserTransactionsQueryVariables>(
    UserTransactionsDocument,
    options
  );
}
export function useUserTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserTransactionsQuery,
    UserTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserTransactionsQuery,
    UserTransactionsQueryVariables
  >(UserTransactionsDocument, options);
}
export type UserTransactionsQueryHookResult = ReturnType<
  typeof useUserTransactionsQuery
>;
export type UserTransactionsLazyQueryHookResult = ReturnType<
  typeof useUserTransactionsLazyQuery
>;
export type UserTransactionsQueryResult = Apollo.QueryResult<
  UserTransactionsQuery,
  UserTransactionsQueryVariables
>;
export const VerifyRoofShingleResealIntakeEligibilityDocument = gql`
  query verifyRoofShingleResealIntakeEligibility(
    $policyId: String!
    $postal: String!
  ) {
    verifyRoofShingleResealIntakeEligibility(
      policyId: $policyId
      postal: $postal
    ) {
      insightPolicyId
      eligible
      address {
        street1
        street2
        city
        state
        zip
      }
    }
  }
`;

/**
 * __useVerifyRoofShingleResealIntakeEligibilityQuery__
 *
 * To run a query within a React component, call `useVerifyRoofShingleResealIntakeEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyRoofShingleResealIntakeEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyRoofShingleResealIntakeEligibilityQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *      postal: // value for 'postal'
 *   },
 * });
 */
export function useVerifyRoofShingleResealIntakeEligibilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    VerifyRoofShingleResealIntakeEligibilityQuery,
    VerifyRoofShingleResealIntakeEligibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VerifyRoofShingleResealIntakeEligibilityQuery,
    VerifyRoofShingleResealIntakeEligibilityQueryVariables
  >(VerifyRoofShingleResealIntakeEligibilityDocument, options);
}
export function useVerifyRoofShingleResealIntakeEligibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VerifyRoofShingleResealIntakeEligibilityQuery,
    VerifyRoofShingleResealIntakeEligibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VerifyRoofShingleResealIntakeEligibilityQuery,
    VerifyRoofShingleResealIntakeEligibilityQueryVariables
  >(VerifyRoofShingleResealIntakeEligibilityDocument, options);
}
export type VerifyRoofShingleResealIntakeEligibilityQueryHookResult =
  ReturnType<typeof useVerifyRoofShingleResealIntakeEligibilityQuery>;
export type VerifyRoofShingleResealIntakeEligibilityLazyQueryHookResult =
  ReturnType<typeof useVerifyRoofShingleResealIntakeEligibilityLazyQuery>;
export type VerifyRoofShingleResealIntakeEligibilityQueryResult =
  Apollo.QueryResult<
    VerifyRoofShingleResealIntakeEligibilityQuery,
    VerifyRoofShingleResealIntakeEligibilityQueryVariables
  >;
export const BillingPageDataDocument = gql`
  query billingPageData($policyID: ID!) {
    userTransactions(policyID: $policyID) {
      transactions {
        type
        description
        amount
        date
        accountNumber
        creditCardType
        method
      }
    }
    policyPaymentPlans(policyID: $policyID) {
      planId
      downPayment
      installmentAmount
      paymentPlanSetupFee
      installmentCharge
      numberOfPayments
      billingMethod
    }
    userBillingAddress(policyID: $policyID)
  }
`;

/**
 * __useBillingPageDataQuery__
 *
 * To run a query within a React component, call `useBillingPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPageDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useBillingPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillingPageDataQuery,
    BillingPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingPageDataQuery, BillingPageDataQueryVariables>(
    BillingPageDataDocument,
    options
  );
}
export function useBillingPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingPageDataQuery,
    BillingPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BillingPageDataQuery,
    BillingPageDataQueryVariables
  >(BillingPageDataDocument, options);
}
export type BillingPageDataQueryHookResult = ReturnType<
  typeof useBillingPageDataQuery
>;
export type BillingPageDataLazyQueryHookResult = ReturnType<
  typeof useBillingPageDataLazyQuery
>;
export type BillingPageDataQueryResult = Apollo.QueryResult<
  BillingPageDataQuery,
  BillingPageDataQueryVariables
>;
export const ClaimsPageDataDocument = gql`
  query ClaimsPageData($policyID: ID!) {
    userCarrierInfo(policyID: $policyID) {
      name
      nameShort
      id
      logo
      email
      website
      phone
      hours
      fax
    }
    userClaimsConstants(policyID: $policyID) {
      LossTypes
      RelationshipToPolicyHolderTypes
      RelationshipToCommercialPolicyHolderTypes
      PrimaryPhoneTypes
      SecondaryPhoneTypes
      CarrierDetails {
        claimsActivityInitialText
        carrierDetailsText
        carrierLogo
        claimsFormReviewText
        claimsReviewText
        claimsContactCarrierText
        claimsReceiveCarrierCallText
      }
      PolicyHolderDetails {
        name
        emailAddress
        phoneNumber
      }
    }
    userClaimsList(policyID: $policyID) {
      PolicyNumber
      InsuredName
      ClaimsTrackerInfo {
        Status
        CarrierClaim {
          DateSubmitted
          Number
          DateOfLoss
          TypeOfLoss
          CustomerContactDate
          ClosedDate
        }
        FNOLSubmission {
          SubmissionNumber
          DateOfLoss
          DateReceived
          LossAtInsuredProperty
          LocationOfLoss {
            Address
            AddressLine2
            City
            State
            Zip
          }
          TypeOfLoss
          LossDescription
          ClaimSubmittedByPolicyholder
          ClaimSubmittedBy {
            Name
            Relationship
            PrimaryPhoneNumber
            PrimaryPhoneNumberType
            AlternatePhoneNumber
            AlternatePhoneNumberType
          }
          ContactInfo {
            Name
            Relationship
            Mobile
            Email
            PrimaryPhoneNumber
            PrimaryPhoneNumberType
            AlternatePhoneNumber
            AlternatePhoneNumberType
            WantsToReceiveSMSUpdates
          }
        }
        Inspection {
          DateInspectionCompleted
          DateReportCompleted
        }
        Adjuster {
          DateAssigned
          AdjusterName
          PhoneNumber
          Email
        }
        Payments {
          DateOfPayment
          PaymentAmount
          Coverage
          CheckNumber
          PayableTo
        }
        Milestones {
          ClaimReport {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          AdjusterAssignment {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          CustomerContact {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          PropertyInspection {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          FieldInspectionReport {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
          ClaimSettlement {
            Name
            CompletedName
            Message
            Sla
            ShowSla
            ShowMilestone
          }
        }
        FootNotes {
          Disclaimer {
            Message
            Show
          }
          Weather {
            Message
            Show
          }
        }
      }
      ClaimsInfo {
        ClaimNumber
        DateOfLoss
        DateReceived
        Status
        LossCauseDescription
        ClaimDescription
        IndependentAdjusterName
        IndependentAdjusterPhoneNumber
        SubmissionNumber
        payments {
          paymentAmount
          paymentDate
        }
      }
      SubmissionsInfo {
        SubmissionNumber
        DateOfLoss
        DateReceived
        LossAtInsuredProperty
        LocationOfLoss {
          Address
          AddressLine2
          City
          State
          Zip
        }
        TypeOfLoss
        LossDescription
        ClaimSubmittedByPolicyholder
        ClaimSubmittedBy {
          Name
          Relationship
          PrimaryPhoneNumber
          PrimaryPhoneNumberType
          AlternatePhoneNumber
          AlternatePhoneNumberType
        }
        ContactInfo {
          Name
          Relationship
          Mobile
          Email
          PrimaryPhoneNumber
          PrimaryPhoneNumberType
          AlternatePhoneNumber
          AlternatePhoneNumberType
          WantsToReceiveSMSUpdates
        }
      }
    }
  }
`;

/**
 * __useClaimsPageDataQuery__
 *
 * To run a query within a React component, call `useClaimsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimsPageDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useClaimsPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClaimsPageDataQuery,
    ClaimsPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClaimsPageDataQuery, ClaimsPageDataQueryVariables>(
    ClaimsPageDataDocument,
    options
  );
}
export function useClaimsPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClaimsPageDataQuery,
    ClaimsPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClaimsPageDataQuery, ClaimsPageDataQueryVariables>(
    ClaimsPageDataDocument,
    options
  );
}
export type ClaimsPageDataQueryHookResult = ReturnType<
  typeof useClaimsPageDataQuery
>;
export type ClaimsPageDataLazyQueryHookResult = ReturnType<
  typeof useClaimsPageDataLazyQuery
>;
export type ClaimsPageDataQueryResult = Apollo.QueryResult<
  ClaimsPageDataQuery,
  ClaimsPageDataQueryVariables
>;
export const ExternalClaimSubmitDocument = gql`
  query externalClaimSubmit($policyID: ID!) {
    userDetails(policyID: $policyID) {
      propertyAddress {
        street1
        street2
        city
        state
        zip
      }
      currentTerm {
        expirationDate
        effectiveDate
      }
    }
    userPolicyProperties(policyID: $policyID) {
      carrierGroup
      policyType
    }
    userCarrierInfo(policyID: $policyID) {
      id
      name
    }
    userClaimsConstants(policyID: $policyID) {
      PolicyHolderDetails {
        name
        emailAddress
        phoneNumber
      }
    }
  }
`;

/**
 * __useExternalClaimSubmitQuery__
 *
 * To run a query within a React component, call `useExternalClaimSubmitQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalClaimSubmitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalClaimSubmitQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useExternalClaimSubmitQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalClaimSubmitQuery,
    ExternalClaimSubmitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExternalClaimSubmitQuery,
    ExternalClaimSubmitQueryVariables
  >(ExternalClaimSubmitDocument, options);
}
export function useExternalClaimSubmitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalClaimSubmitQuery,
    ExternalClaimSubmitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalClaimSubmitQuery,
    ExternalClaimSubmitQueryVariables
  >(ExternalClaimSubmitDocument, options);
}
export type ExternalClaimSubmitQueryHookResult = ReturnType<
  typeof useExternalClaimSubmitQuery
>;
export type ExternalClaimSubmitLazyQueryHookResult = ReturnType<
  typeof useExternalClaimSubmitLazyQuery
>;
export type ExternalClaimSubmitQueryResult = Apollo.QueryResult<
  ExternalClaimSubmitQuery,
  ExternalClaimSubmitQueryVariables
>;
export const LeakbotPageDataDocument = gql`
  query LeakbotPageData($policyID: ID!) {
    userClaimsConstants(policyID: $policyID) {
      PolicyHolderDetails {
        phoneNumber
      }
    }
  }
`;

/**
 * __useLeakbotPageDataQuery__
 *
 * To run a query within a React component, call `useLeakbotPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeakbotPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeakbotPageDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useLeakbotPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LeakbotPageDataQuery,
    LeakbotPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeakbotPageDataQuery, LeakbotPageDataQueryVariables>(
    LeakbotPageDataDocument,
    options
  );
}
export function useLeakbotPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeakbotPageDataQuery,
    LeakbotPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LeakbotPageDataQuery,
    LeakbotPageDataQueryVariables
  >(LeakbotPageDataDocument, options);
}
export type LeakbotPageDataQueryHookResult = ReturnType<
  typeof useLeakbotPageDataQuery
>;
export type LeakbotPageDataLazyQueryHookResult = ReturnType<
  typeof useLeakbotPageDataLazyQuery
>;
export type LeakbotPageDataQueryResult = Apollo.QueryResult<
  LeakbotPageDataQuery,
  LeakbotPageDataQueryVariables
>;
export const CoveragePriceInfoDocument = gql`
  query coveragePriceInfo(
    $policyID: String!
    $coverageName: CoverageName!
    $protectionValue: Int!
  ) {
    previewCoverage(
      policyId: $policyID
      coverageName: $coverageName
      protectionValue: $protectionValue
    ) {
      coverages {
        name
        protectionValue
        price
        premiumNew
        proratedPrice
        startDate
      }
      policyRenewal {
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useCoveragePriceInfoQuery__
 *
 * To run a query within a React component, call `useCoveragePriceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoveragePriceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoveragePriceInfoQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *      coverageName: // value for 'coverageName'
 *      protectionValue: // value for 'protectionValue'
 *   },
 * });
 */
export function useCoveragePriceInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoveragePriceInfoQuery,
    CoveragePriceInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoveragePriceInfoQuery,
    CoveragePriceInfoQueryVariables
  >(CoveragePriceInfoDocument, options);
}
export function useCoveragePriceInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoveragePriceInfoQuery,
    CoveragePriceInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoveragePriceInfoQuery,
    CoveragePriceInfoQueryVariables
  >(CoveragePriceInfoDocument, options);
}
export type CoveragePriceInfoQueryHookResult = ReturnType<
  typeof useCoveragePriceInfoQuery
>;
export type CoveragePriceInfoLazyQueryHookResult = ReturnType<
  typeof useCoveragePriceInfoLazyQuery
>;
export type CoveragePriceInfoQueryResult = Apollo.QueryResult<
  CoveragePriceInfoQuery,
  CoveragePriceInfoQueryVariables
>;
export const ReviewDocument = gql`
  query review($policyID: ID!) {
    policyPaymentPlans(policyID: $policyID) {
      downPayment
      installmentAmount
      paymentPlanSetupFee
      installmentCharge
      numberOfPayments
      billingMethod
    }
    easypayProcessingFees(policyID: $policyID)
  }
`;

/**
 * __useReviewQuery__
 *
 * To run a query within a React component, call `useReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useReviewQuery(
  baseOptions: Apollo.QueryHookOptions<ReviewQuery, ReviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewQuery, ReviewQueryVariables>(
    ReviewDocument,
    options
  );
}
export function useReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewQuery, ReviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewQuery, ReviewQueryVariables>(
    ReviewDocument,
    options
  );
}
export type ReviewQueryHookResult = ReturnType<typeof useReviewQuery>;
export type ReviewLazyQueryHookResult = ReturnType<typeof useReviewLazyQuery>;
export type ReviewQueryResult = Apollo.QueryResult<
  ReviewQuery,
  ReviewQueryVariables
>;
export const ContactSettingsDataDocument = gql`
  query contactSettingsData($email: String!) {
    userPolicies(email: $email) {
      policyId
      propertyAddress {
        street1
        street2
        city
        state
        zip
      }
      isCommercial
      policyState
    }
  }
`;

/**
 * __useContactSettingsDataQuery__
 *
 * To run a query within a React component, call `useContactSettingsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactSettingsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactSettingsDataQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useContactSettingsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactSettingsDataQuery,
    ContactSettingsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContactSettingsDataQuery,
    ContactSettingsDataQueryVariables
  >(ContactSettingsDataDocument, options);
}
export function useContactSettingsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactSettingsDataQuery,
    ContactSettingsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactSettingsDataQuery,
    ContactSettingsDataQueryVariables
  >(ContactSettingsDataDocument, options);
}
export type ContactSettingsDataQueryHookResult = ReturnType<
  typeof useContactSettingsDataQuery
>;
export type ContactSettingsDataLazyQueryHookResult = ReturnType<
  typeof useContactSettingsDataLazyQuery
>;
export type ContactSettingsDataQueryResult = Apollo.QueryResult<
  ContactSettingsDataQuery,
  ContactSettingsDataQueryVariables
>;
export const EnrollmentStatusDataDocument = gql`
  query enrollmentStatusData($policyID: ID!) {
    userDetails(policyID: $policyID) {
      isEnrolledInLeakBot
    }
  }
`;

/**
 * __useEnrollmentStatusDataQuery__
 *
 * To run a query within a React component, call `useEnrollmentStatusDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentStatusDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentStatusDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function useEnrollmentStatusDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    EnrollmentStatusDataQuery,
    EnrollmentStatusDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EnrollmentStatusDataQuery,
    EnrollmentStatusDataQueryVariables
  >(EnrollmentStatusDataDocument, options);
}
export function useEnrollmentStatusDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnrollmentStatusDataQuery,
    EnrollmentStatusDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EnrollmentStatusDataQuery,
    EnrollmentStatusDataQueryVariables
  >(EnrollmentStatusDataDocument, options);
}
export type EnrollmentStatusDataQueryHookResult = ReturnType<
  typeof useEnrollmentStatusDataQuery
>;
export type EnrollmentStatusDataLazyQueryHookResult = ReturnType<
  typeof useEnrollmentStatusDataLazyQuery
>;
export type EnrollmentStatusDataQueryResult = Apollo.QueryResult<
  EnrollmentStatusDataQuery,
  EnrollmentStatusDataQueryVariables
>;
export const HomeServicesDataDocument = gql`
  query homeServicesData($email: String!) {
    userPolicies(email: $email) {
      policyId
      propertyAddress {
        street1
        street2
        city
        state
        zip
      }
      isCommercial
      policyState
    }
  }
`;

/**
 * __useHomeServicesDataQuery__
 *
 * To run a query within a React component, call `useHomeServicesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeServicesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeServicesDataQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useHomeServicesDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    HomeServicesDataQuery,
    HomeServicesDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeServicesDataQuery, HomeServicesDataQueryVariables>(
    HomeServicesDataDocument,
    options
  );
}
export function useHomeServicesDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeServicesDataQuery,
    HomeServicesDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeServicesDataQuery,
    HomeServicesDataQueryVariables
  >(HomeServicesDataDocument, options);
}
export type HomeServicesDataQueryHookResult = ReturnType<
  typeof useHomeServicesDataQuery
>;
export type HomeServicesDataLazyQueryHookResult = ReturnType<
  typeof useHomeServicesDataLazyQuery
>;
export type HomeServicesDataQueryResult = Apollo.QueryResult<
  HomeServicesDataQuery,
  HomeServicesDataQueryVariables
>;
export const PaperlessSettingsDataDocument = gql`
  query paperlessSettingsData($email: String!) {
    userPolicies(email: $email) {
      policyId
      propertyAddress {
        street1
        street2
        city
        state
        zip
      }
      isCommercial
      policyState
    }
  }
`;

/**
 * __usePaperlessSettingsDataQuery__
 *
 * To run a query within a React component, call `usePaperlessSettingsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaperlessSettingsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaperlessSettingsDataQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePaperlessSettingsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaperlessSettingsDataQuery,
    PaperlessSettingsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaperlessSettingsDataQuery,
    PaperlessSettingsDataQueryVariables
  >(PaperlessSettingsDataDocument, options);
}
export function usePaperlessSettingsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaperlessSettingsDataQuery,
    PaperlessSettingsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaperlessSettingsDataQuery,
    PaperlessSettingsDataQueryVariables
  >(PaperlessSettingsDataDocument, options);
}
export type PaperlessSettingsDataQueryHookResult = ReturnType<
  typeof usePaperlessSettingsDataQuery
>;
export type PaperlessSettingsDataLazyQueryHookResult = ReturnType<
  typeof usePaperlessSettingsDataLazyQuery
>;
export type PaperlessSettingsDataQueryResult = Apollo.QueryResult<
  PaperlessSettingsDataQuery,
  PaperlessSettingsDataQueryVariables
>;
export const PaymentSettingsDataDocument = gql`
  query paymentSettingsData($email: String!) {
    userPolicies(email: $email) {
      policyId
      propertyAddress {
        street1
        street2
        city
        state
        zip
      }
      isCommercial
      policyState
    }
  }
`;

/**
 * __usePaymentSettingsDataQuery__
 *
 * To run a query within a React component, call `usePaymentSettingsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentSettingsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentSettingsDataQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePaymentSettingsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentSettingsDataQuery,
    PaymentSettingsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaymentSettingsDataQuery,
    PaymentSettingsDataQueryVariables
  >(PaymentSettingsDataDocument, options);
}
export function usePaymentSettingsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentSettingsDataQuery,
    PaymentSettingsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaymentSettingsDataQuery,
    PaymentSettingsDataQueryVariables
  >(PaymentSettingsDataDocument, options);
}
export type PaymentSettingsDataQueryHookResult = ReturnType<
  typeof usePaymentSettingsDataQuery
>;
export type PaymentSettingsDataLazyQueryHookResult = ReturnType<
  typeof usePaymentSettingsDataLazyQuery
>;
export type PaymentSettingsDataQueryResult = Apollo.QueryResult<
  PaymentSettingsDataQuery,
  PaymentSettingsDataQueryVariables
>;
export const PolicyItemDataDocument = gql`
  query policyItemData($policyID: ID!) {
    userDetails(policyID: $policyID) {
      currentTerm {
        expirationDate
        effectiveDatePolicyTerm
      }
    }
  }
`;

/**
 * __usePolicyItemDataQuery__
 *
 * To run a query within a React component, call `usePolicyItemDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyItemDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyItemDataQuery({
 *   variables: {
 *      policyID: // value for 'policyID'
 *   },
 * });
 */
export function usePolicyItemDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    PolicyItemDataQuery,
    PolicyItemDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PolicyItemDataQuery, PolicyItemDataQueryVariables>(
    PolicyItemDataDocument,
    options
  );
}
export function usePolicyItemDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PolicyItemDataQuery,
    PolicyItemDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PolicyItemDataQuery, PolicyItemDataQueryVariables>(
    PolicyItemDataDocument,
    options
  );
}
export type PolicyItemDataQueryHookResult = ReturnType<
  typeof usePolicyItemDataQuery
>;
export type PolicyItemDataLazyQueryHookResult = ReturnType<
  typeof usePolicyItemDataLazyQuery
>;
export type PolicyItemDataQueryResult = Apollo.QueryResult<
  PolicyItemDataQuery,
  PolicyItemDataQueryVariables
>;
export const SecurityDataDocument = gql`
  query securityData($email: String!) {
    userBasicInfo(email: $email) {
      email
      userName
      authProvider
    }
  }
`;

/**
 * __useSecurityDataQuery__
 *
 * To run a query within a React component, call `useSecurityDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityDataQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSecurityDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    SecurityDataQuery,
    SecurityDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SecurityDataQuery, SecurityDataQueryVariables>(
    SecurityDataDocument,
    options
  );
}
export function useSecurityDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecurityDataQuery,
    SecurityDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SecurityDataQuery, SecurityDataQueryVariables>(
    SecurityDataDocument,
    options
  );
}
export type SecurityDataQueryHookResult = ReturnType<
  typeof useSecurityDataQuery
>;
export type SecurityDataLazyQueryHookResult = ReturnType<
  typeof useSecurityDataLazyQuery
>;
export type SecurityDataQueryResult = Apollo.QueryResult<
  SecurityDataQuery,
  SecurityDataQueryVariables
>;
