import { useContext, useEffect, useState } from "react";

import {
  Alert,
  Button,
  CheckboxGroup,
  Link as DSLink,
  Heading,
  Span,
} from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { HTTP_LOGIN_TIMEOUT } from "consts";
import {
  formatLeakBotPayload,
  formatPhoneNumber,
} from "pages/leakbot/leakbot-utils";
import { authHeaders, logError, trackEvent } from "utils";

import styles from "./leakbot.module.scss";

const LeakbotReview = ({
  onBack,
  name,
  policyId,
  address,
  email,
  phone,
  agreeSetup,
  setAgreeSetup,
  onSubmitSuccess,
}) => {
  const { selectedPolicyId, userDetails, userInfo } =
    useContext(AuthAppContext);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (errorMessage) {
      trackEvent("LeakBot validation error");
    }
  }, [errorMessage]);

  const onSubmit = async () => {
    setErrorMessage(
      agreeSetup ? "" : "Please agree to the LeakBot set up commitment."
    );

    if (agreeSetup) {
      const payload = formatLeakBotPayload({
        policyId: selectedPolicyId,
        userDetails,
        userInfo,
      });
      payload.email = email;
      payload.phone = phone.slice(0, 10);

      trackEvent("LeakBot - Submit order button clicked");

      try {
        const leakbotSubmitResponse = await fetch(
          `${CONFIG.KEYSTONE_PROXY_HREF}/api/leakbot/enrollment`,
          {
            body: JSON.stringify(payload),
            headers: authHeaders({
              Accept: "application/json",
              "content-type": "application/json",
            }),
            method: "POST",
            credentials: "include",
          }
        ).then((resp) => {
          return resp.json();
        });

        if (leakbotSubmitResponse.status >= HTTP_LOGIN_TIMEOUT) {
          setErrorMessage(
            "There was an error. Please try again or contact support if this persists."
          );
          trackEvent("LeakBot submission error");
        } else {
          trackEvent("LeakBot - Ordered");
          onSubmitSuccess();
        }
      } catch (error) {
        setErrorMessage(
          "There was an error. Please try again or contact support if this persists."
        );
        trackEvent("LeakBot submission error");
        logError(`LeakBot submission: ${error.message}`);
      }
    }
  };

  return (
    <div className={styles.lbNonCardLayout}>
      <div className={styles.lbNonCardMainContent}>
        <Heading size="lg">
          Please review and confirm your order details.
        </Heading>
        <div>
          <Span bold>Name</Span>
          <p>{name}</p>
        </div>
        <div>
          <Span bold>Policy number</Span>
          <p>{policyId}</p>
        </div>
        <div>
          <Span bold>Shipping address</Span>
          <p>{address}</p>
        </div>
        <div>
          <Span bold>Email</Span>
          <p>{email}</p>
        </div>
        <div>
          <Span bold>Phone number</Span>
          <p>{formatPhoneNumber(phone)}</p>
        </div>
        {errorMessage && <Alert title={errorMessage} appearance="danger" />}
        <div>
          <Span bold>Terms and Conditions</Span>
          <div>
            <CheckboxGroup aria-label="Terms and Conditions">
              <CheckboxGroup.Checkbox
                isSelected={agreeSetup}
                onChange={() => {
                  setErrorMessage("");
                  setAgreeSetup(!agreeSetup);
                }}
              >
                <Span bold>
                  I agree to complete set up of my LeakBot within 28 days of
                  delivery.
                </Span>
              </CheckboxGroup.Checkbox>
            </CheckboxGroup>
          </div>
          <div className={styles.lbCheckboxLabel}>
            <p>
              LeakBot only takes a few minutes to set up. You don&apos;t need
              any tools or plumbing knowledge, just download the free app and
              follow the on-screen instruction, then clip LeakBot onto the pipe.
            </p>
            <br />
            <p>
              <DSLink
                href="https://myleakbot.com/terms-and-conditions-us-nj/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </DSLink>{" "}
              apply and will be included in your confirmation email.
            </p>
          </div>
        </div>
        <div className={styles.lbNav}>
          <Button appearance="tertiary" onPress={onBack}>
            Go back
          </Button>
          <Button onPress={onSubmit} data-testid="LeakbotStartBtn">
            Submit
          </Button>
        </div>
      </div>
      <div>
        <img
          src="/assets/images/leakbot-price-card.png"
          alt="a cost breakdown showing that leakbot is free"
        />
      </div>
    </div>
  );
};

export default LeakbotReview;
