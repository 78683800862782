import { useContext } from "react";
import { useLoaderData } from "react-router-dom";

import { AuthAppContext } from "components/root/auth-app-provider";
import { type PropertyProfileLoaderData } from "components/root/router";
import { ALARM_ENUM_VALUE_MAP } from "consts";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { useFlags } from "utils";

export const usePropertyUpdateSecurity = () => {
  const { retireProxy } = useFlags();
  const { selectedPolicyId } = useContext(AuthAppContext);
  const loaderData = useLoaderData() as PropertyProfileLoaderData;
  const { data: propertyProfileData, loading } =
    useUserPropertyProfileDataQuery({
      variables: {
        policyID: selectedPolicyId,
      },
      skip: !selectedPolicyId,
    });
  const { burglarAlarm, fireAlarm } = retireProxy
    ? loaderData?.propertyProfile ?? {}
    : propertyProfileData?.userPropertyProfileData ?? {};

  const prevBurglar = ALARM_ENUM_VALUE_MAP[burglarAlarm ?? ""];
  const prevFire = ALARM_ENUM_VALUE_MAP[fireAlarm ?? ""];

  return {
    loading,
    prevBurglar,
    prevFire,
  };
};
