import { Heading, List, Span } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import {
  PropertyUpdateRoofContext,
  type PropertyUpdateRoofContextState,
} from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import { PropertyUpload } from "../property-upload";
import styles from "./roof-proof-upload.module.scss";

export const RoofProofUpload = () => {
  const { setFields, state } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );

  return (
    <PropertyUpdateLayout
      step={3}
      numSteps={4}
      heading="Update roof details"
      buttonProps={{
        primaryDisabled: !state?.files?.length,
        primaryPath: "../review",
      }}
    >
      <div className={styles.roofProof}>
        <Heading size="md">Help us review the details</Heading>
        <div>
          Please upload:
          <List type="ol">
            <li>
              The <Span bold>contract</Span> from your roofer, making sure it
              includes their <Span bold>license number</Span> and{" "}
              <Span bold>a detailed list of the work</Span> they did
            </li>
            <li>
              <Span bold>Photos of your roof</Span> from all sides of your home
            </li>
          </List>
        </div>
        <PropertyUpload
          filesField="files"
          propertyType="roof"
          state={state}
          setFields={setFields}
        />
      </div>
    </PropertyUpdateLayout>
  );
};
