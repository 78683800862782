import { useCallback, useEffect, useState } from "react";

import { addToast } from "@icg360/design-system";

import { isError } from "data/ss-error";
import { useKeystone } from "data/ss-store";
import { useContactItemDataQuery } from "gql/__generated__/hooks";
import { useFlags } from "utils";

const TOAST_MESSAGES = {
  success: {
    address: "Mailing address updated successfully.",
    phone: "Phone number updated successfully.",
    email: "Email address updated successfully.",
  },
  error:
    "There was an error. Please try again or contact support if this persists.",
};

export const useUpdateForm = (infoItem) => {
  const [showForm, setShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const handleShowForm = () => setShowForm(true);
  const handleCancelForm = () => setShowForm(false);
  const handleContactInfoUpdateSuccess = () => {
    addToast(TOAST_MESSAGES.success[infoItem], {
      icon: true,
    });
    setShowForm(false);
  };
  const handleContactInfoUpdateError = () => {
    setErrorMessage(TOAST_MESSAGES.error);
  };
  const handleErrorDismiss = () => setErrorMessage(null);

  return {
    showForm,
    errorMessage,
    handleShowForm,
    handleCancelForm,
    handleContactInfoUpdateError,
    handleContactInfoUpdateSuccess,
    handleErrorDismiss,
  };
};

export function usePolicyHolderPhone(policyId: string) {
  const [policyHolderPhone, setPolicyHolderPhone] = useState<string>();
  const [restLoading, setRestLoading] = useState(false);
  const { retireProxy } = useFlags();
  const keystone = useKeystone();

  const { data: claimsConstantsData, loading } = useContactItemDataQuery({
    variables: {
      policyID: policyId ?? "",
    },
  });

  const doClaimConstantsQuery = useCallback(async () => {
    setRestLoading(true);
    const response = await keystone.getClaimConstants(policyId);
    setRestLoading(false);
    if (!isError(response)) {
      setPolicyHolderPhone(response?.PolicyHolderDetails?.phoneNumber);
    }
  }, [policyId, keystone]);

  useEffect(() => {
    if (retireProxy) {
      doClaimConstantsQuery();
    } else {
      setPolicyHolderPhone(
        claimsConstantsData?.userClaimsConstants?.PolicyHolderDetails
          ?.phoneNumber
      );
    }
  }, [
    doClaimConstantsQuery,
    claimsConstantsData?.userClaimsConstants?.PolicyHolderDetails,
    retireProxy,
  ]);

  const refreshPhone = useCallback(async () => {
    if (retireProxy) {
      await doClaimConstantsQuery();
    }
  }, [doClaimConstantsQuery, retireProxy]);

  return {
    policyHolderPhone,
    phoneLoading: retireProxy ? restLoading : loading,
    refreshPhone,
  };
}
