import cx from "classnames";
import { Field, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Link as DSLink,
  Heading,
  Span,
} from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { type PropertyProfileLoaderData } from "components/root/router";
import { InputField } from "components/shared/form-fields";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import {
  type PROPERTY_ROOF_TYPE,
  PROPERTY_ROOF_TYPES,
  PROPERTY_ROOF_UPDATE_TYPE,
} from "consts";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { type RoofFormValues } from "pages/property-profile/update-roof/index";
import RoofTypesModal from "pages/property-profile/update-roof/roof-types-modal";
import { trackEvent, useFlags } from "utils";

import styles from "./update-roof.module.scss";

type RoofMaterialCardOldProps = {
  roofType: PROPERTY_ROOF_TYPE;
  onClick: () => void;
  selected?: boolean;
};

export const RoofMaterialCardOld = ({
  roofType,
  onClick,
  selected,
}: RoofMaterialCardOldProps) => {
  const { imgSrc, label } = PROPERTY_ROOF_UPDATE_TYPE[roofType];

  return (
    <button
      className={cx(
        styles.roofCard,
        selected && styles.highlighted,
        !imgSrc && styles.noImg
      )}
      onClick={() => onClick()}
      key={roofType}
    >
      {imgSrc ? (
        <img src={imgSrc} alt={label} className={styles.roofImage} />
      ) : null}

      <Span
        bold
        color={selected ? "default" : "quiet"}
        className={styles.roofCardLabel}
      >
        {label}
      </Span>
    </button>
  );
};

const RoofDetails = () => {
  const navigate = useNavigate();
  const [showRoofTypesModal, setShowRoofTypesModal] = useState(false);
  const [showMoreRoofTypes, setShowMoreRoofTypes] = useState(false);
  const [roofTypes, setRoofTypes] = useState<PROPERTY_ROOF_TYPE[]>();
  const { retireProxy } = useFlags();
  const loaderData = useLoaderData() as PropertyProfileLoaderData;

  const { selectedPolicyId } = useContext(AuthAppContext);
  const { data: propertyProfileData } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });
  const roofYear = retireProxy
    ? loaderData?.propertyProfile?.constructionYearRoof
    : propertyProfileData?.userPropertyProfileData?.constructionYearRoof;

  useEffect(() => {
    if (showMoreRoofTypes) {
      setRoofTypes(PROPERTY_ROOF_TYPES);
    } else {
      setRoofTypes(
        PROPERTY_ROOF_TYPES.slice(0, 6).concat(PROPERTY_ROOF_TYPES.slice(-1))
      );
    }
  }, [showMoreRoofTypes]);

  const {
    setFieldValue,
    dirty,
    isValid,
    values: { constructionYearRoof, roofCoveringType },
  } = useFormikContext<RoofFormValues>();

  const showTooltipModal = () => {
    trackEvent("Property roof - Roof material tooltip opened");
    setShowRoofTypesModal(true);
  };

  return (
    <>
      <ProgressBar progress={50} />
      <MainLayout>
        <Card className={styles.cardContainer}>
          <Heading size="md">Please update your roof details.</Heading>
          <Heading size="sm">
            When was your roof installed or last replaced?
          </Heading>

          <Field
            name="constructionYearRoof"
            component={InputField}
            title={`Year (last updated ${roofYear})`}
            value={constructionYearRoof}
            className={styles.constructionYear}
          />

          <span className={styles.tooltipLabel}>
            <Heading size="sm">What is your roof made of?</Heading>
            <DSLink onPress={showTooltipModal}>
              Learn more about each type
            </DSLink>
          </span>

          <div className={styles.roofGrid}>
            {roofTypes?.map((roofType) => (
              <Field
                name="roofCoveringType"
                roofType={roofType}
                key={roofType}
                component={RoofMaterialCardOld}
                selected={roofType === roofCoveringType}
                onClick={() => {
                  setFieldValue("roofCoveringType", roofType);
                }}
              />
            ))}
          </div>

          <div>
            <Button
              appearance="tertiary"
              onPress={() => setShowMoreRoofTypes(!showMoreRoofTypes)}
              trailingIcon={showMoreRoofTypes ? "ArrowUp" : "ArrowDown"}
            >
              {showMoreRoofTypes
                ? "Hide more roof materials"
                : "Show more roof materials"}
            </Button>
          </div>

          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onPress={() => navigate("/my/property")}
            >
              Go back
            </Button>
            <Button
              appearance="primary"
              size="default"
              onPress={() => {
                trackEvent("Property updates review page load");
                navigate("/my/property/roof/review");
              }}
              disabled={!isValid || !dirty}
              data-testid="roof-continue-btn"
            >
              Continue
            </Button>
          </div>
        </Card>
        <RoofTypesModal
          open={showRoofTypesModal}
          onClose={() => setShowRoofTypesModal(false)}
        />
      </MainLayout>
    </>
  );
};

export default RoofDetails;
