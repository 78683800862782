import { Heading, RadioGroup, Span } from "@icg360/design-system";

import { type AlarmType } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import {
  PropertyUpdateSecurityContext,
  type PropertyUpdateSecurityContextState,
} from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import { PropertyUpload } from "../property-upload";
import styles from "./property-update-security.module.scss";
import { alarmOptions } from "./security-burglar-alarm";

export const SecurityFireAlarm = () => {
  const STEP = 2;
  const { setFields, state } =
    useMultiStepFormState<PropertyUpdateSecurityContextState>(
      PropertyUpdateSecurityContext
    );

  const nextDisabled =
    state?.fireAlarm === "central" && !state?.fireAlarmFiles?.length;

  return (
    <PropertyUpdateLayout
      step={STEP}
      numSteps={3}
      heading="Update security details"
      buttonProps={{ primaryPath: "../review", primaryDisabled: nextDisabled }}
    >
      <div>
        <Heading size="md" className={styles.questionHeadingDS}>
          Do you have a fire alarm system?
        </Heading>
        <RadioGroup
          aria-label="Fire alarm options"
          defaultValue={state?.fireAlarm ?? "none"}
          onChange={(value: AlarmType) => setFields({ fireAlarm: value })}
          className={styles.radioGroupDS}
          grid
        >
          {alarmOptions.map((option) => (
            <RadioGroup.Card key={option.value} {...option} />
          ))}
        </RadioGroup>
      </div>
      {state?.fireAlarm === "central" ? (
        <div className={styles.uploadSection}>
          <Span className={styles.uploadPromptDS}>
            Please upload the <Span bold>alarm certificate</Span>:
          </Span>
          <PropertyUpload
            filesField="fireAlarmFiles"
            propertyType="security"
            state={state}
            setFields={setFields}
          />
        </div>
      ) : null}
    </PropertyUpdateLayout>
  );
};
