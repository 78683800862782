import { Heading, RadioGroup, Span } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import {
  PropertyUpdateTrampolineContext,
  type PropertyUpdateTrampolineContextState,
} from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import styles from "./property-update-trampoline.module.scss";

export const TrampolinePadding = () => {
  const { state, setFields } =
    useMultiStepFormState<PropertyUpdateTrampolineContextState>(
      PropertyUpdateTrampolineContext
    );

  return (
    <PropertyUpdateLayout
      step={2}
      numSteps={3}
      heading="Update trampoline details"
      buttonProps={{
        primaryDisabled: !state?.trampolinePadding,
        primaryPath: "../review",
      }}
    >
      <div className={styles.radioContentWrapper}>
        <Heading size="md">
          Does your trampoline have shock-absorbing padding?
        </Heading>
        <RadioGroup
          aria-label="Trampoline options"
          defaultValue={state?.trampolinePadding}
          onChange={(value: "yes" | "no") =>
            setFields({ trampolinePadding: value })
          }
          className={styles.radioGroupDS}
          grid
        >
          <RadioGroup.Card title="Yes" value="yes" />
          <RadioGroup.Card title="No" value="no" />
        </RadioGroup>
        <Span color="quiet">
          Shock absorbing{" "}
          <Span bold color="quiet">
            padding over the springs, hooks and frame
          </Span>{" "}
          is highly recommended for trampoline safety.
        </Span>
      </div>
    </PropertyUpdateLayout>
  );
};
