import { useContext } from "react";
import { useLoaderData } from "react-router-dom";

import { AuthAppContext } from "components/root/auth-app-provider";
import { type PropertyProfileLoaderData } from "components/root/router";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { useFlags } from "utils";

export const usePropertyUpdateTrampoline = () => {
  const { retireProxy } = useFlags();
  const { selectedPolicyId } = useContext(AuthAppContext);
  const loaderData = useLoaderData() as PropertyProfileLoaderData;
  const { data: propertyProfileData, loading } =
    useUserPropertyProfileDataQuery({
      variables: {
        policyID: selectedPolicyId,
      },
      skip: !selectedPolicyId || retireProxy,
    });
  const propertyData = retireProxy
    ? loaderData?.propertyProfile
    : propertyProfileData?.userPropertyProfileData;

  const prevTrampoline = propertyData?.trampoline;

  return {
    loading,
    prevTrampoline,
  };
};
