/**
 * Type guard for sagesure errors. After the check comes back true, TS compiler
 * will see the value as a SSError.
 */
export function isError(result: unknown): result is SSError {
  return result instanceof SSError;
}

/**
 * This is the base class for all the possible client errors. API consumers
 * should use the `instanceof` operator to determine an error type, like
 * `if (err instanceof BadRequestError) { ... }`
 */
export class SSError extends Error {
  constructor(message: string) {
    super(message);
  }
}

/**
 * Request never sent, missing required arguments.
 */
export class MissingArgumentsError extends SSError {
  constructor(message: string) {
    super(message);
    this.name = "MissingArgumentsError";
    this.cause = "Missing or malformed data provided, request never sent";
  }
}

/**
 * Error was caused by an issue with the data sent to the server.
 */
export class BadRequestError extends SSError {
  constructor(message: string) {
    super(message);
    this.name = "BadRequestError";
    this.cause = "Missing or malformed data sent to server";
  }
}

/**
 * Not found error, wrong location or no results for this query.
 */
export class NotFoundError extends SSError {
  constructor(message: string) {
    super(message);
    this.name = "NotFoundError";
    this.cause = "Wrong location or no results found";
  }
}

/**
 * User isn't authorized to make the request.
 */
export class UnauthorizedRequestError extends SSError {
  constructor(message: string) {
    super(message);
    this.name = "UnauthorizedRequestError";
    this.cause = "Missing, malformed, or expired authorization token";
  }
}

/**
 * Problem connecting to service.
 */
export class NetworkError extends SSError {
  constructor(message: string) {
    super(message);
    this.name = "NetworkError";
    this.cause = "Network error contacting API server";
  }
}
