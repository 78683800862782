import { useContext, useEffect, useState } from "react";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";

import {
  Alert,
  Badge,
  Button,
  Card,
  Link as DSLink,
  Heading,
  Hr,
  Icon,
  Span,
} from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { DataDefinition, DataRow, DataTerm } from "components/common/data-row";
import { FetchingError } from "components/common/fetching-error";
import { Stack } from "components/common/stack";
import { CustomerCareContactModal } from "components/customer-care-contact-modal";
import PropertyMessages from "components/home-services/property-messages";
import { AuthAppContext } from "components/root/auth-app-provider";
import { type PropertyProfileLoaderData } from "components/root/router";
import { FaqSidebar } from "components/shared/faq-sidebar";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { ALARM_ENUM_DISPLAY_MAP, PROPERTY_ROOF_CODE_MAP } from "consts";
import {
  type PropertyUpdateData,
  useUserPropertyProfileDataQuery,
} from "gql/__generated__/hooks";
import { formatDate, trackEvent, useFlags } from "utils";

import { usePropertyProfilePage } from "./hooks";
import { PoolData } from "./pool-data";
import {
  checkRoofUpdates,
  checkSecurityUpdates,
  checkTrampolineUpdates,
} from "./property-check-updates";
import styles from "./property-profile.module.scss";

const PropertyProfile = () => {
  const {
    propertyProfileDwelling,
    propertyProfilePool,
    propertyProfileSecurityDetails,
    propertyProfileTrampoline,
    propertyPagePoolValues,
    roofUpdateFlow,
    showPropertyTabDetailsSection,
    retireProxy,
  } = useFlags();
  const navigate = useNavigate();
  const { selectedPolicyId, userDetails } = useContext(AuthAppContext);
  const loaderData = useLoaderData() as PropertyProfileLoaderData;

  const [hasFutureEffectiveDate, setHasFutureEffectiveDate] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const {
    data: propertyProfileData,
    loading,
    error,
  } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  const { userPropertyProfileData } = propertyProfileData ?? {};

  const { poolDisplayData, propertyUpdated } = usePropertyProfilePage(
    retireProxy ? loaderData?.propertyProfile : userPropertyProfileData
  );

  useEffect(() => {
    trackEvent("Property Care - Property Care Module Displayed", {
      homeServices: "propertyCare",
    });
  }, []);

  useEffect(() => {
    if (userDetails) {
      setHasFutureEffectiveDate(
        new Date(userDetails?.currentTerm?.effectiveDatePolicyTerm) > new Date()
      );
    }
  }, [userDetails]);

  if (userDetails?.isCommercial) {
    return <Navigate to={"/my/overview"} replace />;
  }

  if (error) {
    return (
      <BaseLayout>
        <MainLayout
          sidebar={
            showPropertyTabDetailsSection ? (
              <FaqSidebar page="property" />
            ) : null
          }
        >
          <FetchingError page="Property" />
        </MainLayout>
      </BaseLayout>
    );
  }

  if (loading) {
    return <BaseLayout loading />;
  }

  const {
    burglarAlarm,
    fireAlarm,
    constructionYearRoof,
    roofCoveringType,
    trampoline,
    propertyUpdateData,
  } = userPropertyProfileData ?? {};
  const { poolType, poolFence } = poolDisplayData ?? {};

  const { constructionYearDisplay, roofCoveringDisplay, roofPending } =
    checkRoofUpdates(propertyUpdateData as PropertyUpdateData[], {
      constructionYearRoof,
      roofCoveringType,
    });

  const { burglarAlarmDisplay, fireAlarmDisplay, securityPending } =
    checkSecurityUpdates(propertyUpdateData as PropertyUpdateData[], {
      burglarAlarm,
      fireAlarm,
    });

  const { trampolineDisplay, trampolinePaddingDisplay, trampolinePending } =
    checkTrampolineUpdates(propertyUpdateData as PropertyUpdateData[], {
      trampoline,
    });

  const sidebar = (
    <Stack>
      <InsuranceRepresentative />
      {showPropertyTabDetailsSection ? <FaqSidebar page="property" /> : null}
    </Stack>
  );

  const mainContent = (
    <Stack>
      <PropertyMessages />
      {showPropertyTabDetailsSection && (
        <Card title="Property details">
          <section>
            <p>
              We have the following details on file. Make updates below if
              anything has changed. You may be eligible for a lower insurance
              premium. For any other changes, contact your insurance
              representative or{" "}
              <DSLink onPress={() => setShowContactModal(true)}>
                SageSure customer care
              </DSLink>
              .
            </p>
            {!propertyUpdated && (
              <div className={styles.dontSeeNotification}>
                {hasFutureEffectiveDate && (
                  <Alert
                    appearance="warning"
                    title={`Any changes made will be reflected after policy renewal date on ${formatDate(
                      userDetails?.currentTerm?.effectiveDatePolicyTerm,
                      "MM/DD/yyyy"
                    )}`}
                    description="Your new policy term will begin soon. Any changes made to your property will be reflected after the start of your next policy term."
                  />
                )}
                {!roofUpdateFlow &&
                  !propertyUpdated &&
                  !hasFutureEffectiveDate && (
                    <Alert
                      title="Don't see your updated changes?"
                      description="If you recently submitted an update, you will see changes to your property profile once your update is verified. Please check for an email from SageSure covering any next steps or call your insurance representative if you have any questions."
                    />
                  )}
              </div>
            )}
          </section>
          <Hr spacing="2xl" />
          <section>
            <Heading size="sm">
              <div className={styles.propertySectionHeader}>
                <Span color="quiet">
                  <Icon name="Quote" size="lg" />
                </Span>
                Security
                {securityPending && (
                  <div className={styles.statusBadge}>
                    <Badge appearance="neutral">Pending review</Badge>
                  </div>
                )}
              </div>
            </Heading>

            {securityPending ? (
              <Alert
                appearance="success"
                title="Your update is under review."
                className={styles.pendingAlertDS}
                description={
                  <Span>
                    We&apos;ll get back to you in the next few days to let you
                    know if there&apos;s anything else for you to do. If you
                    have any questions or concerns, contact{" "}
                    <DSLink onPress={() => setShowContactModal(true)}>
                      SageSure customer care
                    </DSLink>
                    .
                  </Span>
                }
              />
            ) : (
              <p className={styles.sectionDescription}>
                Alarm systems can protect your home and property. If you&apos;ve
                updated your security system let us know. It could lower your
                premium.
              </p>
            )}

            <DataRow
              flex="row"
              className={
                securityPending ? styles.updatedDataTerm : styles.dataTerm
              }
            >
              <DataTerm>Burglar alarm:</DataTerm>
              <DataDefinition>{burglarAlarmDisplay}</DataDefinition>
            </DataRow>

            <DataRow
              flex="row"
              className={
                securityPending ? styles.updatedDataTerm : styles.dataTerm
              }
            >
              <DataTerm>Fire alarm:</DataTerm>
              <DataDefinition>{fireAlarmDisplay}</DataDefinition>
            </DataRow>

            {propertyProfileSecurityDetails && !securityPending && (
              <Button
                size="sm"
                className={styles.propertySectionButtonDS}
                onPress={() => {
                  trackEvent("Property security - Update button clicked", {
                    burglarAlarm: ALARM_ENUM_DISPLAY_MAP[burglarAlarm ?? 0],
                    fireAlarm: ALARM_ENUM_DISPLAY_MAP[fireAlarm ?? 0],
                  });
                  navigate("/my/property/security");
                }}
                data-testid="update-security-btn"
              >
                Update
              </Button>
            )}
          </section>
          <Hr spacing="2xl" />
          <section>
            <Heading size="sm">
              <div className={styles.propertySectionHeader}>
                <Span color="quiet">
                  <Icon name="HouseShield" size="lg" />
                </Span>
                Roof
                {roofPending && (
                  <div className={styles.statusBadge}>
                    <Badge appearance="neutral">Pending review</Badge>
                  </div>
                )}
              </div>
            </Heading>

            {roofPending ? (
              <Alert
                appearance="success"
                title="Your update is under review."
                className={styles.pendingAlertDS}
                description={
                  <Span>
                    We&apos;ll get back to you in the next few days to let you
                    know if there&apos;s anything else for you to do. If you
                    have any questions or concerns, contact{" "}
                    <DSLink onPress={() => setShowContactModal(true)}>
                      SageSure customer care
                    </DSLink>
                    .
                  </Span>
                }
              />
            ) : (
              <p className={styles.sectionDescription}>
                A roof that&apos;s in good repair helps keep your home safe and
                secure. If you&apos;ve replaced your roof let us know. It could
                lower your premium.
              </p>
            )}

            <DataRow
              flex="row"
              className={roofPending ? styles.updatedDataTerm : styles.dataTerm}
            >
              <DataTerm>Year roof installed / replaced:</DataTerm>
              <DataDefinition>{constructionYearDisplay}</DataDefinition>
            </DataRow>

            <DataRow
              flex="row"
              className={roofPending ? styles.updatedDataTerm : styles.dataTerm}
            >
              <DataTerm>Roof material:</DataTerm>
              <DataDefinition>{roofCoveringDisplay}</DataDefinition>
            </DataRow>

            {propertyProfileDwelling && !roofPending && (
              <Button
                size="sm"
                className={styles.propertySectionButtonDS}
                onPress={() => {
                  trackEvent("Property roof - Update button clicked", {
                    roofMaterial: PROPERTY_ROOF_CODE_MAP[roofCoveringType ?? 0],
                    roofAge: constructionYearRoof,
                  });
                  navigate("/my/property/roof");
                }}
                data-testid="update-roof-btn"
              >
                Update
              </Button>
            )}
          </section>
          <Hr spacing="2xl" />
          <section>
            <Heading size="sm">
              <div className={styles.propertySectionHeader}>
                <Span color="quiet">
                  <Icon name="Pool" size="lg" />
                </Span>
                Swimming pool
              </div>
            </Heading>

            <p className={styles.sectionDescription}>
              Accurate and up-to-date property information helps us ensure you
              have the best coverage.
            </p>

            {propertyPagePoolValues ? (
              <PoolData poolDisplayData={poolDisplayData} />
            ) : (
              <>
                <DataRow flex="row" className={styles.dataTerm}>
                  <DataTerm>Type:</DataTerm>
                  <DataDefinition>{poolType}</DataDefinition>
                </DataRow>

                {poolType !== "None" && (
                  <DataRow flex="row" className={styles.dataTerm}>
                    <DataTerm>Pool fence:</DataTerm>
                    <DataDefinition>
                      {poolFence ? "Yes" : "None"}
                    </DataDefinition>
                  </DataRow>
                )}
              </>
            )}

            {propertyProfilePool && (
              <Button
                size="sm"
                className={styles.propertySectionButtonDS}
                data-testid="update-pool-btn"
                onPress={() => {
                  trackEvent("Property pool - Update button clicked", {
                    pool: poolType,
                  });
                  navigate("/my/property/pool");
                }}
              >
                Update
              </Button>
            )}
          </section>
          <Hr spacing="2xl" />
          <section>
            <Heading size="sm">
              <div className={styles.propertySectionHeader}>
                <Span color="quiet">
                  <Icon name="Trampoline" size="lg" />
                </Span>
                Trampoline
                {trampolinePending && (
                  <div className={styles.statusBadge}>
                    <Badge appearance="neutral">Pending review</Badge>
                  </div>
                )}
              </div>
            </Heading>

            {trampolinePending ? (
              <Alert
                appearance="success"
                title="Your update is under review."
                className={styles.pendingAlertDS}
                description={
                  <Span>
                    We&apos;ll get back to you in the next few days to let you
                    know if there&apos;s anything else for you to do. If you
                    have any questions or concerns, contact{" "}
                    <DSLink onPress={() => setShowContactModal(true)}>
                      SageSure customer care
                    </DSLink>
                    .
                  </Span>
                }
              />
            ) : (
              <p className={styles.sectionDescription}>
                Updating details about your property helps ensure your coverage
                meets your needs.
              </p>
            )}

            <DataRow
              flex="row"
              className={
                trampolinePending ? styles.updatedDataTerm : styles.dataTerm
              }
            >
              <DataTerm>Trampoline:</DataTerm>
              <DataDefinition>{trampolineDisplay}</DataDefinition>
            </DataRow>

            {trampolinePaddingDisplay ? (
              <DataRow
                flex="row"
                className={
                  trampolinePending ? styles.updatedDataTerm : styles.dataTerm
                }
              >
                <DataTerm>Shock-absorbing padding:</DataTerm>
                <DataDefinition>{trampolinePaddingDisplay}</DataDefinition>
              </DataRow>
            ) : null}

            {propertyProfileTrampoline && !trampolinePending && (
              <Button
                size="sm"
                className={styles.propertySectionButtonDS}
                onPress={() => {
                  trackEvent("Property trampoline - Update button clicked", {
                    trampoline,
                  });
                  navigate("/my/property/trampoline");
                }}
                data-testid="update-trampoline-btn"
              >
                Update
              </Button>
            )}
          </section>
        </Card>
      )}
    </Stack>
  );

  return (
    <BaseLayout
      availableNotifications={[
        "Leakbot",
        "HomeServices",
        "CancellationPayment",
      ]}
    >
      <MainLayout sidebar={sidebar}> {mainContent}</MainLayout>
      <CustomerCareContactModal
        description="We can assist with any questions about billing or your SageSure account."
        handleClose={() => setShowContactModal(false)}
        show={showContactModal}
      />
    </BaseLayout>
  );
};

export default PropertyProfile;
