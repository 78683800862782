import { Heading, Span } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import {
  PropertyUpdateTrampolineContext,
  type PropertyUpdateTrampolineContextState,
} from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import { PropertyUpload } from "../property-upload";
import styles from "./trampoline-proof-upload.module.scss";

export const TrampolineProof = () => {
  const { setFields, state } =
    useMultiStepFormState<PropertyUpdateTrampolineContextState>(
      PropertyUpdateTrampolineContext
    );

  return (
    <PropertyUpdateLayout
      step={2}
      numSteps={4}
      heading="Update trampoline details"
      buttonProps={{
        primaryDisabled: !state?.files?.length,
        primaryPath: "../confirm",
      }}
    >
      <div className={styles.uploadContentWrapper}>
        <Heading size="md">Help us review the details</Heading>
        <Span>
          Please upload a photo showing that the trampoline is no longer on your
          property.
        </Span>
        <PropertyUpload
          filesField="files"
          propertyType="trampoline"
          state={state}
          setFields={setFields}
        />
      </div>
    </PropertyUpdateLayout>
  );
};
