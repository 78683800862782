import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet } from "react-router";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { usePropertyUpdateTrampoline } from "./hooks";
import styles from "./property-update-trampoline.module.scss";

export type PropertyUpdateTrampolineContextState = {
  trampoline?: "yes" | "no";
  trampolinePadding?: "yes" | "no";
  confirmNoTrampoline?: boolean;
  files?: FileList;
} | null;

export const PropertyUpdateTrampolineContext = createContext<{
  state: PropertyUpdateTrampolineContextState;
  setState: Dispatch<SetStateAction<PropertyUpdateTrampolineContextState>>;
}>({ state: null, setState: () => null });

export const PropertyUpdateTrampoline = () => {
  const { selectedPolicyId } = useContext(AuthAppContext);
  const { loading } = usePropertyUpdateTrampoline();

  const { formNavigate, state } =
    useMultiStepFormState<PropertyUpdateTrampolineContextState>(
      PropertyUpdateTrampolineContext
    );

  useEffect(() => {
    if (!state?.trampoline) {
      formNavigate("./");
    }
  }, [formNavigate, state]);

  if (loading || !selectedPolicyId) {
    return (
      <div className={styles.loaderWrapper}>
        <SpinnerLoader />
      </div>
    );
  }

  return <Outlet />;
};

export const PropertyUpdateTrampolineFlow = () => {
  const { prevTrampoline } = usePropertyUpdateTrampoline();
  const trampoline = prevTrampoline ? "yes" : "no";
  const [state, setState] = useState<PropertyUpdateTrampolineContextState>({
    trampoline,
    confirmNoTrampoline: false,
  });
  return (
    <PropertyUpdateTrampolineContext.Provider value={{ state, setState }}>
      <PropertyUpdateTrampoline />
    </PropertyUpdateTrampolineContext.Provider>
  );
};
