import styles from "./thumbtack-modal.module.scss";

export const Stars = ({ rating, count }: { rating: number; count: number }) => {
  const positive = Math.ceil(rating);
  const negative = 5 - positive;
  return (
    <div className={styles.rating}>
      <span className={styles.stars}>
        <span className={styles.starFill}>{"\u2605".repeat(positive)}</span>
        <span className={styles.starEmpty}>{"\u2605".repeat(negative)}</span>
      </span>{" "}
      ({count})
    </div>
  );
};
