import { useRouteError } from "react-router-dom";

import { Button } from "@icg360/design-system";

import { ErrorMessage } from "components/error-message";
import { useIsLoggedInQuery } from "gql/__generated__/hooks";
import { useLogout } from "hooks/use-logout-user";

import styles from "./full-page-error.module.scss";

const FullPageError = () => {
  const { logout } = useLogout();
  const error = useRouteError();
  console.error("Route Error:", error);
  const { data: isLoggedInResponse } = useIsLoggedInQuery();
  const loggedIn = isLoggedInResponse?.isLoggedIn?.loggedIn;

  return (
    <ErrorMessage>
      {loggedIn ? (
        <Button
          appearance="secondary"
          size="sm"
          className={styles.logoutButton}
          onPress={logout}
          data-testid="logout-btn"
        >
          Log out
        </Button>
      ) : null}
    </ErrorMessage>
  );
};

export default FullPageError;
