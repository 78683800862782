import { DataDefinition, DataRow, DataTerm } from "components/common/data-row";
import { type PoolDisplayValues } from "consts";

import styles from "./pool-data.module.scss";

type PoolDataProps = {
  poolDisplayData?: PoolDisplayValues;
};

export const PoolData = ({ poolDisplayData }: PoolDataProps) => {
  const {
    poolType,
    poolFence,
    poolFenceAboveGround,
    unlockedPoolGate,
    divingBoardOrSlide,
    immovablePoolLadder,
    poolCovering,
  } = poolDisplayData ?? {};
  const poolIsAboveGround = poolType === "Above-ground";
  const fence = poolIsAboveGround ? poolFenceAboveGround : poolFence;

  return (
    <>
      <DataRow flex="row" className={styles.dataTerm}>
        <DataTerm>Type:</DataTerm>
        <DataDefinition>{poolType}</DataDefinition>
      </DataRow>

      {poolType !== "None" && (
        <>
          <DataRow flex="row" className={styles.dataTerm}>
            <DataTerm>Permanent fenced or locked structure:</DataTerm>
            <DataDefinition>{fence}</DataDefinition>
          </DataRow>
          {fence === "Yes" && (
            <ul>
              <li>
                {poolIsAboveGround
                  ? "Your pool is at least 4 feet tall or has a fence that’s 4 feet or taller."
                  : "Your pool is surrounded by a fence 4 feet or taller."}
              </li>
              <li>
                Your pool fence has a padlocked or self-locking or self-latching
                gate.
              </li>
            </ul>
          )}

          {unlockedPoolGate && (
            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>Attached to deck without a locking gate:</DataTerm>
              <DataDefinition>{unlockedPoolGate}</DataDefinition>
            </DataRow>
          )}

          {divingBoardOrSlide && (
            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>Diving board or slide:</DataTerm>
              <DataDefinition>{divingBoardOrSlide}</DataDefinition>
            </DataRow>
          )}

          {poolIsAboveGround && (
            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>Immovable ladder:</DataTerm>
              <DataDefinition>{immovablePoolLadder}</DataDefinition>
            </DataRow>
          )}

          {poolCovering && (
            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>Unfilled and not completely covered:</DataTerm>
              <DataDefinition>{poolCovering}</DataDefinition>
            </DataRow>
          )}
        </>
      )}
    </>
  );
};
