import { createContext, useCallback, useContext, useState } from "react";

import { ThumbtackModal } from "./thumbtack-modal";

const ThumbtackModalContext = createContext({
  openThumbtackModal: (
    _zipCode: string,
    _cat: string,
    _actionDescription?: string
  ) => {},
});

export function useThumbtackModalContext() {
  const ctx = useContext(ThumbtackModalContext);
  if (!ctx) {
    throw new Error(
      "useThumbtackModalContext must be used within ThumbtackModalProvider"
    );
  }
  return ctx;
}

export const ThumbtackModalProvider = ({ children }) => {
  const [zip, setZip] = useState<string>();
  const [category, setCategory] = useState<string>();
  const [action, setAction] = useState<string>();
  const [show, setShow] = useState(false);
  const openThumbtackModal = useCallback((zipCode, cat, actionDescription) => {
    setZip(zipCode);
    setCategory(cat);
    setAction(actionDescription);
    setShow(true);
  }, []);
  return (
    <ThumbtackModalContext.Provider value={{ openThumbtackModal }}>
      {children}
      <ThumbtackModal
        zipCode={zip ?? ""}
        category={category ?? ""}
        action={action}
        onClose={() => {
          setZip(undefined);
          setCategory(undefined);
          setShow(false);
        }}
        show={show}
      />
    </ThumbtackModalContext.Provider>
  );
};
